export const actions = {
    async getPolicy({commit}) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`halabk/policy`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setPolicy", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getTerms({commit}) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`halabk/terms-&-conditions`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setTerms", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },


    async getFaqs({commit}) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`halabk/faqs`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setFaqs", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getAbout({commit}) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`halabk/about`)
            .then((respo) => {
                if (respo.status === 200) {

                commit("setAbout", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getFooter({commit}) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`halabk/footer`)
            .then((respo) => {
                if (respo.status === 200) {

                commit("setFooter", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },


    async sendContact({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("halabk/contact", data)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("app/setInputLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


}