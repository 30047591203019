<template>
  <div>

    <div class="container  mobile-container">
      <PageHeading :title='$t("sign-up.title")' :show-icons="false"/>

      <div class="steps-timeline mt-12">
        <div :class="`steps-timeline--step ${checkStep('info')}`">
          <div class="step-no">
            1
          </div>
          <div class="step-name">
            {{ $t("sign-up.step.info") }}
          </div>
        </div>
        <div :class="`steps-timeline--line ${checkStep('info')}`"></div>
        <div :class="`steps-timeline--step ${checkStep('verify')}`">
          <div class="step-no">
            2
          </div>
          <div class="step-name">
            {{ $t("sign-up.step.verify") }}
          </div>
        </div>
        <div :class="`steps-timeline--line ${checkStep('verify')}`"></div>
        <div :class="`steps-timeline--step ${checkStep('interests')}`">
          <div class="step-no">
            3
          </div>
          <div class="step-name">
            {{ $t("sign-up.step.interests") }}
          </div>
        </div>
      </div>

      <div class="sign-up">
        <div class="form">

          <div v-if="currentStep==='info'" class=" details-container">

            <div class="p-4 pt-2">

              <div class="step-one flex flex-col gap-3">

                <upload-avatar class="mx-auto mb-2" @changeAvatar="(value)=>this.infoForm.image=value"/>

                <TextInput :required="true"
                           :label="$t('input.full-name.title')"
                           :placeholder="$t('input.full-name.placeholder')"
                           :rules="['required']"
                           :error="error.name"
                           @inputValue="(value)=>this.infoForm.name=value"
                           @isReady="(value) => this.valid=value">
                  <template v-slot:prefix-icon>
                    <person-icon/>
                  </template>
                </TextInput>

                <TextInput :required="true"
                           :label='$t("input.email.title")'
                           :placeholder='$t("input.email.placeholder")'
                           :rules="['required','email']"
                           :error="error.email"
                           @inputValue="(value)=>this.infoForm.email=value"
                           @isReady="(value) => this.valid=value">
                  <template v-slot:prefix-icon>
                    <envelope-fontawesome/>
                  </template>
                </TextInput>

                <TextInput
                    :required="true"
                    :label='$t("input.password.title")'
                    :placeholder='$t("input.password.placeholder")'
                    :type="showPassword ? 'text' : 'password'"
                    :rules="['required','password']"
                    :error="error.password"
                    @inputValue="(value)=>this.infoForm.password=value"
                    @isReady="(value) => this.valid=value">

                  <template v-slot:prefix-icon>
                    <profile-lock-icon/>
                  </template>
                  <template v-slot:suffix-icon>
                    <eye-fontawesome @click="showPassword=!showPassword"/>
                  </template>
                </TextInput>


              </div>

              <LoadingButton
                  :btn-class="'text-center'"
                  :name="$t('btn.next')"
                  :loading="loading" class="w-full text-center mt-4"
                  :on-click="()=>nextStep('info','verify')"
              />


            </div>
          </div>

          <div v-if="currentStep==='verify'" class="sign-up-container container mobile-container">
            <div class="forget-password">
              <div class="forget-password-form  ">


                <h2 class="title">{{ $t("confirm.title") }}</h2>
                <small class="desc">{{ $t("confirm.desc") }}</small>

                <div class="form mt-8 flex flex-col gap-4">

                  <div class="input-wrapper">
                    <div class="text-reg-14 text-gray-1 mb-2">{{ $t("confirm.input") }}</div>
                    <PincodeInput dir="ltr"
                                  v-model="verifyForm.code"
                                  placeholder=" "
                                  :length="5"
                                  class="confirm-code "
                                  :class="error.code.length>0?'error':''"
                    />

                    <span class="input--error" v-if="error.code.length>0">{{ error.code }}</span>
                  </div>


                  <LoadingButton
                      :btn-class="'text-center'"
                      :name="$t('btn.send')"
                      :loading="loading" class="w-full text-center mt-4"
                      :on-click="()=>nextStep('verify','interests')"
                  />


                </div>

                <div class="text-reg-14 text-gray-2 text-center mt-4">
                  <div>
                    {{ $t("confirm.not_receive") }}

                    <countdown :time="countDownTime" @end="enableResendCode" v-if="!enableResendVerifyCode">
                      <template slot-scope="props">
                        {{ props.minutes }}:{{ props.seconds }}
                      </template>
                    </countdown>
                  </div>

                  <button :class="`mt-2 btn-text ${enableResendVerifyCode?'':'disabled'}`"
                          :disabled="!enableResendVerifyCode" @click="resendVerifyCode">
                    {{ $t("btn.resend") }}
                  </button>

                </div>

              </div>
            </div>
          </div>


          <div v-if="currentStep==='interests'"
               class="sign-up-container details-container mb-5 contact-form mobile-container">

            <div class="details--heading h-show">
              <h3>
                {{ $t("sign-up.interests.title") }}
              </h3>
            </div>

            <div class="details-divider h-show"></div>

            <div class="sign-up-interests">
              <div class="interests-grid">

                <div :class="`interests-container ${selectedInterests.includes(interest.id)?'active':''}`"
                     v-for="interest in interests"
                     :key="interest.id" @click="toggleInterests(interest.id)">

                  <img :src="interest.image" :alt="interest.title" class="icon">
                  <h3 class="text-center">{{ interest.title }}</h3>
                </div>

              </div>
              <span class="input--error" v-if="error.selectedInterests.length>0">{{ error.selectedInterests }}</span>


              <LoadingButton
                  :btn-class="'text-center'"
                  :name="$t('btn.send')"
                  :loading="loading" class="w-full text-center mt-4"
                  :on-click="()=>nextStep('interests','finish')"
              />


            </div>

          </div>

        </div>
      </div>


    </div>

  </div>
</template>


<script>
import PageHeading from "../../../components/PageHeading";
import TextInput from "../../../components/inputs/text";
import UploadAvatar from "../../../components/uploadAvatar";
import PincodeInput from "vue-pincode-input";
import {mapGetters} from "vuex";
import LoadingButton from "../../../components/buttons/loadingButton";
import router from "../../../routes";


export default {
  name: "SignUp",

  components: {
    LoadingButton,
    PincodeInput,
    UploadAvatar,
    TextInput,
    PageHeading,
  },

  data() {
    return {
      valid: true,


      selectedInterests: [],

      showPassword: false,

      enableSubmit: true,
      infoForm: {image: "", name: "", email: "", password: ""},
      verifyForm: {code: ""},
      error: {name: "", email: "", password: "", code: "", selectedInterests: ""},
      rules: ["required"],

      countDownTime: 3 * 60 * 1000,
      enableResendVerifyCode: false,
    }
  },

  computed: {
    ...mapGetters({
      countries: "app/getCountries",
      interests: "app/getInterests",
      loading: "app/getInputLoading",
      currentStep: "auth/getAccountStatus",
      completeSteps: "auth/getCompleteSteps",
      isMobile: "app/getIsMobile",
    }),
    validate: function () {
      return this.error.email.length === 0 && this.error.password.length === 0
          && this.error.name.length === 0
          && this.valid;
    },
  },

  methods: {
    toggleInterests(interestId) {
      //toggle selected interests array
      if (this.selectedInterests.includes(interestId)) {
        this.selectedInterests.splice(this.selectedInterests.indexOf(interestId), 1);
      } else {
        this.selectedInterests.push(interestId);
      }
    },

    checkStep(name) {
      if (this.completeSteps.includes(name))
        return "active";

      if (this.currentStep === name)
        return "current"
      return "";
    },

    nextStep(current, next) {

      if (current === "info") {

        Object.keys(this.infoForm).map(function (key) {

          if (this.infoForm[key].length === 0) {
            this.error[key] = this.$t("rules.required");
          } else {
            this.error[key] = "";
          }

        }.bind(this))

        if (!this.validate)
          return;


        this.$store.dispatch("auth/signUp", this.infoForm)
            .then(() => {
                  this.$store.commit('auth/setAccountStatus', next);
                  let steps = [...this.completeSteps];
                  steps.push(current)
                  this.$store.commit('auth/setCompleteSteps', steps);

                },
            )
            .catch(error => {
                  let inputErrors = error.response.data.data;
                  Object.keys(inputErrors).map(function (key) {
                    this.error[key] = inputErrors[key][0];
                  }.bind(this))
                },
            )
      } else if (current === "verify") {

        if (this.verifyForm.code.length === 0) {
          this.error.code = this.$t("rules.required");
          return;
        } else {
          this.error.code = "";
        }


        this.$store.dispatch("auth/verify", {code: this.verifyForm.code})
            .then(() => {
                  this.$store.commit('auth/setAccountStatus', next);
                  let steps = [...this.completeSteps];
                  steps.push(current)
                  this.$store.commit('auth/setCompleteSteps', steps);
                },
            )
            .catch(error => {
                  if (error.response.status === 400) {
                    this.error.code = this.$t("rules.code.invalid");
                    return;
                  }
                  let inputErrors = error.response.data.data;
                  Object.keys(inputErrors).map(function (key) {
                    this.error[key] = inputErrors[key][0];
                  }.bind(this))
                },
            )

      } else if (current === "interests") {

        if (this.selectedInterests.length === 0) {
          this.error.selectedInterests = this.$t("rules.interests.required");
          return;
        } else {
          this.error.selectedInterests = "";
        }


        this.$store.dispatch("auth/userInterests", {interests: this.selectedInterests})
            .then(async () => {
                  await router.push("/")
                },
            )
      }
    },

    enableResendCode() {
      this.enableResendVerifyCode = true;
    },

    resendVerifyCode() {
      this.$store.dispatch("auth/resendVerifyCode")
          .then(() => {
                this.countDownTime = 3 * 60 * 1000;
                this.enableResendVerifyCode = false;
              },
          )
          .catch(error => {
                this.enableResendVerifyCode = false;
                this.$toast.error(error.response.data.message);
              },
          )
    },


  },

  created() {
    // if (this.currentStep === "info") {
    //   this.$store.dispatch("app/getCountries")
    // } else if (this.currentStep === "interests") {
    // }
    this.$store.dispatch("app/getInterests")

  },
  mounted() {
    this.toggleMobileBottomBar(true);
    this.toggleMobileCopyright(true);
    setTimeout(() => {

      this.toggleMobileBottomBar(true);
      this.toggleMobileCopyright(true);
    }, 10);
  },

  beforeDestroy() {
    this.toggleMobileBottomBar(false)
    this.toggleMobileCopyright(false)
  }


}
</script>

