<template>

  <div class="faq details-container" v-if="faqs.length>0">
    <div class="details--heading">
      <h3>{{ $t("common.faqs") }}</h3>
    </div>
    <div class="details-divider"></div>
    <div class="faq-content ">

      <faq-accordion
          v-for="faq in faqs"
          :key="faq.id"
          :title="faq.question"
      >
        <p  v-html="faq.answer"/>
      </faq-accordion>

    </div>
  </div>

</template>

<script>

import FaqAccordion from "../../../../components/faqAccordion";

export default {
  name: "faqs-section",
  components: {FaqAccordion},
  props: {
    faqs: [],
  },
}
</script>