export const actions = {
    async getPartnersContent({commit}) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`halabk/partners`)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("getPartnersContent", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch(() => {
                commit("app/setLoading", false, {root: true})

            })
    },

}