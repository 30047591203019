<template>

  <div class="counter">
    <div class="counter-header">
      <h3 class="title">{{ title }}</h3>
      <div class="flex items-center gap-1">
        <div class="price" v-if="packagePrice!=0">(+{{ (packagePrice * currentCount).toFixed(2) }})</div>
        <div class="price">{{ PriceCalc(priceWithDiscount * currentCount) }}</div>
        <div class="price price-old" v-if="priceWithDiscount!=price">{{ PriceCalc(price * currentCount) }}</div>
      </div>
    </div>
    <div class="counter-container">
      <div class=" flex items-center gap-2">
        <person-icon/>
        <div>{{ placeholder }}</div>
      </div>
      <div class="counter-btns">
        <button class="counter--btn bg-white" @click="decrease">
          <minus-icon/>
        </button>
        <div class="counter--value">
          {{ count }}
        </div>
        <button class="counter--btn bg-secondary" @click="increase">
          <plus-icon/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "counter",
  props: {
    title:  [Number,String],
    placeholder: [Number,String],
    price: [Number, String],
    priceWithDiscount: [Number, String],
    packagePrice: [Number, String],
    value: [Number, String],
    counterId: [Number, String],
  },
  data() {
    return {
      count: this.value || 0,
    }
  },

  computed: {
    currentCount() {
      return this.count !== 0 ? this.count : 1
    },
  },
  watch: {
    count(val) {
      this.$emit("onCount", {id: this.counterId, count: val, price: this.price, title: this.title})
    },
  },
  methods: {
    increase() {
      this.count++;
    },
    decrease() {
      if (this.count > 0)
        this.count--;
    },

  },
}
</script>