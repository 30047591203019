<template>

  <div class="container mobile-container">
    <PageHeading :title="$t('user.bookings.info')" :paths="[{title:$t('user.profile'),link:'../'}]"/>


    <!--    booking-progress canceled-->
    <div :class="`booking-progress ${detail.status===3?'canceled':''} `">
      <div class="bar">
        <div :class="`progress ${getStatus('pending')} `">
          <span>{{ $t("user.bookings.detail.status.booked") }}</span>
        </div>
        <div :class="`progress ${getStatus('active')} `">
          <span>{{ $t("user.bookings.detail.status.active") }}</span>
        </div>
        <div :class="`progress ${getStatus('complete')} `">
          <span>{{ $t("user.bookings.detail.status.completed") }}</span>
        </div>
      </div>
    </div>

    <div class="booking-content">

      <div class="booking-content-details">
        <BookCard :book="detail.service" :status-code="detail.status" :service-link="true"/>

        <!--         reject or cancellation reason    -->
        <div class=" details-container" v-if="detail.status === 3">
          <div class="details--heading">
            <h3 class="text-error">{{ $t("user.bookings.detail.cancel_reason") }}</h3>

            <time class="text-reg-12 text-gray-3">{{ detail.canceled_at | moment("calendar") }}</time>
          </div>
          <div class="details-divider">

          </div>
          <div class="px-3 ">
            <p class="text-reg-14 text-gray-3">
              {{ detail.cancel_reason }}
            </p>
          </div>
        </div>

        <!--         booking detail table-->
        <div class=" details-container pb-0">
          <div class="details--heading">
            <h3>{{ $t("user.bookings.detail.info") }}</h3>
          </div>
          <div class="details-divider mb-0"></div>
          <div class="details-table">
            <div class="row">
              <div class="key">{{ $t("user.bookings.no") }}</div>
              <div class="value">#{{ detail.id }}</div>
            </div>
            <div class="row">
              <div class="key">{{ $t("user.fields.created_at") }}</div>
              <div class="value">{{ detail.created_at | moment("calendar") }}</div>
            </div>


            <div class="row" v-for="(counter,index) in detail.service_counters" :key="index">
              <div class="key">{{ counter.title }}</div>
              <div class="value"><span class="text-primary">{{ counter.value }}</span></div>
            </div>


            <div class="row">
              <div class="key">{{ $t("user.fields.package") }}</div>
              <div class="value">{{ detail.service_packages.title }}</div>
            </div>
            <div class="row">
              <div class="key">{{ $t("user.fields.name") }}</div>
              <div class="value">{{ detail.name }}</div>
            </div>
            <div class="row">
              <div class="key">{{ $t("user.fields.phone") }}</div>
              <div class="value">{{ detail.phone }}</div>
            </div>
            <div class="row">
              <div class="key">{{ $t("user.fields.secondary_phone") }}</div>
              <div class="value">{{ detail.secondary_phone }}</div>
            </div>
            <div class="row">
              <div class="key">{{ $t("user.fields.email") }}</div>
              <div class="value">{{ detail.email }}</div>
            </div>
            <div class="row">
              <div class="key">{{ $t("user.fields.country") }}</div>
              <div class="value">{{ detail.city }}</div>
            </div>
          </div>
        </div>

        <BookCommentSection v-if="detail.status===2"
                            :service-id="detail.id"
                            :comment-object="detail.commnet"/>

      </div>

      <div class="booking-content-book-info">


        <div class=" details-container">
          <div class="details--heading">
            <h3>{{ $t("user.bookings.detail.info") }}</h3>
            <div class="text-primary text-reg-14 cursor-pointer" @click="downloadInvoice">{{
                $t("user.bookings.detail.invoice")
              }}
            </div>
          </div>
          <div class="details-divider"></div>

          <div class="booking-content-book-info--book">

            <div class="book-flex">
              <div>{{ $t("user.bookings.booked_at") }}</div>
              <div class="text-med-16">{{ detail.booked_at | moment("LL") }}</div>
            </div>


            <div class="book-divider"></div>
            <div class="book-flex">
              <div>{{ $t("user.bookings.people_count") }}</div>
              <div class="text-med-16">{{ detail.people_count }}</div>
            </div>
            <ul class="terms-list">
              <li v-for="(counter,index) in detail.service_counters" :key="index">
                <div>{{ counter.title }} , X{{ counter.value }}</div>
                <div>{{ PriceCalc(counter.price * counter.value) }}</div>
              </li>
            </ul>

            <div class="book-divider"></div>
            <div class="book-flex">
              <div>{{ $t("user.bookings.packages") }}</div>
            </div>

            <ul class="terms-list">
              <li>
                <div>{{ detail.service_packages.title }}</div>
                <div>+{{ PriceCalc(detail.service_packages.additional_price) }}</div>
              </li>
            </ul>

            <div class="book-divider"></div>
            <div class="book-flex">
              <div>{{ $t("common.price.total") }}:</div>
              <div>{{ PriceCalc(getTotalPrice) }}</div>
            </div>

            <div class="book-flex">
              <div>{{ $t("common.price.tax") }}:</div>
              <div>{{ PriceCalc(getTax) }}</div>
            </div>


            <div class="book-flex " v-if="calOfferDiscount>0">
              <div class="name">{{ $t("common.price.discount") }}:</div>
              <div class="value text-error">- {{ PriceCalc(calOfferDiscount) }}</div>
            </div>

            <div class="divider my-1"></div>


            <div class="book-flex" v-if="calOfferDiscount>0">
              <div class="name">{{ $t("common.price.total-after-discount") }}:</div>
              <div class="value text-primary">{{ PriceCalc(calcTotalAfterOfferDiscount) }}</div>
            </div>


            <div class="book-flex " v-if="calCouponDiscount>0">
              <div class="name">{{ $t("common.price.coupon-discount") }}:</div>
              <div class="value text-error">- {{ PriceCalc(calCouponDiscount) }}</div>
            </div>


            <div class="divider my-1"></div>

            <div class="book-flex">
              <div>{{ $t("common.price.total-price") }}:</div>
              <div class="text-primary">{{ PriceCalc(calTotalAfterTax) }}</div>
            </div>

          </div>
        </div>


        <ProviderCard :provider="detail.provider"/>

      </div>
    </div>
  </div>
</template>

<script>


import PageHeading from "../../../components/PageHeading";
import BookCard from "./components/bookCard";

import ProviderCard from "../../../components/ProviderCard";
import {mapGetters} from "vuex";
import BookCommentSection from "./sections/commentSection";


export default {
  name: "BookingDetail",
  components: {BookCommentSection, ProviderCard, BookCard, PageHeading},


  computed: {
    ...mapGetters({
      detail: "user/bookings/getBookDetails",
    }),

    getTotalPrice() {
      let total = 0;
      this.detail.service_counters.map(function (counter) {
        total += ((counter.price + this.detail.service_packages.additional_price) * counter.value);
      }.bind(this))
      return total;
    },

    calOfferDiscount() {
      return (this.detail.discount_percent * this.getTotalPrice) / 100
    },

    calcTotalAfterOfferDiscount() {
      return this.getTotalPrice - this.calOfferDiscount;
    },

    calCouponDiscount() {
      return (this.calcTotalAfterOfferDiscount * this.detail.coupon_discount_percent) / 100
    },


    calcTotalAfterCouponDiscount() {
      return this.calcTotalAfterOfferDiscount - this.calCouponDiscount;
    },

    getTax() {
      return (this.calcTotalAfterCouponDiscount * this.detail.tax) / 100;

    },

    calTotalAfterTax() {
      return this.calcTotalAfterCouponDiscount + this.getTax;
    },
  },

  watch: {
    $route(to, from) {
      if (from !== to) {
        this.getDetail();
      }
    },
  },

  async created() {

    this.$store.commit('app/setNotification404', false)

    await this.getDetail();
  },

  beforeDestroy() {

    this.$store.commit('app/setNotification404', true)

  },

  methods: {

    async getDetail() {
      await this.$store.dispatch("user/bookings/getBookDetails", this.$route.params.id).catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({name:'404'})
        }

      });
    },
    async downloadInvoice() {
      await this.$store.dispatch("user/bookings/downloadInvoice", this.$route.params.id);
    },
    getStatus(status) {
      if (this.detail.status !== 3) {
        if (this.detail.status === 0) {
          if (status === "pending")
            return "current";
          return "";
        }
        if (this.detail.status === 1) {
          if (status === "pending")
            return "active";
          if (status === "active")
            return "current";
          return "";
        }
        return "active";
      }
      return "";
    },
  },
}
</script>
