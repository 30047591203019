<template>
  <div class="container mobile-container">


    <div class="affiliate ">
      <div class="affiliate-header">
        <h1>{{ $t("affiliate.statics") }}</h1>

        <div>

          <div class="affiliate-date-picker-input" @click="showCalender=!showCalender">

            <div class="text">{{ $t("affiliate.filter") }}</div>

            <v-date-picker v-if="showCalender" class="affiliate-date-picker" :key="datePickerKey" ref="calendar" v-model="dateInput"
                           @input="onDayClick"
                           :class="'app-calender'"
                           :locale="$i18n.locale"/>
            <calendar-outline-icon/>
          </div>

        </div>
      </div>

      <div class="affiliate-grid">

        <div class="affiliate-card">
          <div class="info">
            <h2 class="title">{{ $t("affiliate.usage") }}</h2>
            <h1 class="value">{{ getAffiliateData.coupon_usage_count }}</h1>
          </div>

          <div class="icon">
            <affiliate-usage-icon/>
          </div>
        </div>
        <div class="affiliate-card">
          <div class="info">
            <h2 class="title">{{ $t("affiliate.sales") }}</h2>
            <h1 class="value"> {{ PriceCalc(getAffiliateData.total_sales) }}</h1>
          </div>

          <div class="icon">
            <affiliate-sales-icon/>
          </div>
        </div>
        <div class="affiliate-card">
          <div class="info">
            <h2 class="title">{{ $t("affiliate.profit") }}</h2>
            <h1 class="value"> {{ PriceCalc(getAffiliateData.total_profit) }}</h1>
          </div>

          <div class="icon">
            <affiliate-profit-icon/>
          </div>
        </div>

      </div>
      <div class="affiliate-detail mt-6 mb-28">

        <div class="info">
          <h2 class="title">{{ $t("affiliate.data") }}</h2>
          <h1 class="value">{{ getAffiliateData.advertiser.name }}</h1>
        </div>

        <div class="info">
          <h2 class="title">{{ $t("affiliate.status") }}</h2>
          <h1 class="value">
            <div class="badge" :class="getAffiliateData.status">
              {{ $t('common.'+getAffiliateData.status)}}
            </div>
          </h1>
        </div>

        <div class="info">
          <h2 class="title">{{ $t("affiliate.code") }}</h2>
          <h1 class="value">
            <span id="couponCode">{{ getAffiliateData.coupon }}</span>
            <span class="copy-btn" @click="copyCode">
              {{ $t("affiliate.copy") }}
            </span>
          </h1>
        </div>

      </div>

    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "affiliate",
  components: {},
  data() {
    const date = new Date();
    const day = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    return {
      showCalender : false,
      datePickerKey: 0,

      dateInput: new Date(year, month, day),
    }
  },
  computed:{
    ...mapGetters({
      getAffiliateData: "app/getAffiliateData",
    }),
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },
    async onDayClick() {
      console.log(this.dateInput)

    },

    copyCode() {

      navigator.clipboard.writeText(document.getElementById('couponCode').textContent).then(() => {
        this.$store.dispatch('app/copiedCode')
      });
    }

  },
  created() {
    this.$store.dispatch('app/getAffiliateData',this.$router.currentRoute.params.coupon)
  }


}
</script>

<style>
[dir="ltr"] .vc-arrow {
  transform: rotate(0deg) !important;
}

[dir="ltr"] .calendar-placeholder {
  flex-direction: row-reverse;
}

.vc-container {

  --blue-100: rgba(254, 208, 41, .1);
  --blue-200: rgba(254, 208, 41, .2);
  --blue-300: rgba(254, 208, 41, .3);
  --blue-400: rgba(254, 208, 41, .4);
  --blue-500: rgba(254, 208, 41, .5);
  --blue-600: rgba(254, 208, 41, .6);
  --blue-700: rgba(254, 208, 41, .7);
  --blue-800: rgba(254, 208, 41, .8);
  --blue-900: #4C535F;

  border: none !important;
}

.vc-title {
  color: #4C535F !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.vc-weekday {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.vc-day {
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 20px !important;
}

.vc-arrow {
  transform: rotate(180deg);
}


.vc-highlight {
  border-radius: 6px !important;
}

.vc-day-content {
  border-radius: 6px !important;
}

.vc-day-content:hover, .vc-day-content:focus {
  background-color: var(--blue-100) !important;

}
</style>