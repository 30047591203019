<template>
  <div class="mobile-nav-bar login-nav  show">
    <div class="nav-bg ">
      <img class="w-full nav-pattern" src="@/assets/patterns/pattern_1.png" alt="halabk pattern">
      <div class="relative w-full mt-2">


        <div class="">
          <div class="margin-x flex items-center justify-between">
            <router-link :to="{name:'home'}" v-if="showLogo">
              <logo-icon class="nav-logo"/>
            </router-link>

            <div class=" nav-box show-in-filter-page h-hide" @click="goBack" v-if="showLogo">
              <close-icon/>
            </div>
            <div class=" nav-box show-in-filter-page h-hide" @click="goBack" v-else>
              <mobile-back-icon/>
            </div>

          </div>

          <div class="others login_text">
            <h3 class="heading">
              {{ title }}
            </h3>
            <p class="desc">
              {{ desc }}
            </p>
          </div>
        </div>

      </div>
    </div>
  </div>


</template>

<script>

export default {
  name: "RegistrationMobileNav",

  props: {
    title: String,
    desc: String,
    showLogo: {
      default: false,
      type: Boolean
    },
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },


  },

}
</script>