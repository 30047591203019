export const state = {

    service: {
        counters: [],
        package: {id: 0, price: 0, title: ''},
        slug: '',
        startDate: ''
    },

    completeFirstStep: false,

    bookInfo: {
        id: 0,
        city: "",
        country: "",
        image: "",
    },

    paymentInfo: {
        id: 0,
        payment_url: '',
        success_url: '',
        failed_url: 'http://hlabk.test/api/ar/payment/failed'
    },

    coupon: {
        "active": false,
        "message": "",
        "id": 0,
        "discount": 0
    },

    bookModal: {
        id: 0,
        show: false
    }
}


