export const actions = {

    async getProviderDetail({commit}, data) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`providers/${data.slug}` + (data.service ? `/${data.service}` : ''))
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setProviderDetail", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getProviderBasic({commit}, id) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`providers/basic/${id}/info`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setProviderBasic", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getProviderComments({commit}, data) {
        // commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`providers/${data.id}/reviews?page=${data.page}`)
            .then((respo) => {
                if (respo.status === 200) {
                    commit("setProviderComments", respo.data.data.content)
                    // commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                // commit("app/setLoading", false, {root: true})
                throw error
            })
    },

}

