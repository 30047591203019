<template>
  <div>

    <div class="container mobile-container ">
      <div class="mobile-nav-bar other-nav show">
        <div class="nav-bg " :class="isLoggedIn?'':'small'">
          <img class="w-full nav-pattern" src="@/assets/patterns/pattern_1.png" alt="halabk pattern">
          <div class="relative w-full mt-8">


            <!--      <PageHeading :title="$t('others.about')"/>-->

            <div class="others user" v-if="isLoggedIn">
              <img :src="userInfo.image!=null?userInfo.image:''" @error="replaceByDefault" :alt="userInfo.name">
              <div class="info">
                <div class="name  max-1-lines">{{ userInfo.name }}</div>
                <div class="email max-1-lines">{{ userInfo.email }}</div>
              </div>

              <router-link :to="{name:'user-profile'}" class="edit">
                {{ $t('user.nav.edit') }}
              </router-link>
            </div>

            <div class="others bookings" v-if="isLoggedIn">
              <div class="bookings-box">
                <mobile-coming-bookings-icon class="icon"/>
                <div class="info">
                  <span class="count">{{ bookingCount.coming }}</span>
                  <div class="span title">{{ $t('others.coming-bookings') }}</div>
                </div>

              </div>
              <div class="divider"></div>
              <div class="bookings-box">
                <mobile-complete-bookings-icon class="icon"/>
                <div class="info">
                  <span class="count">{{ bookingCount.complete }}</span>
                  <div class="span title">{{ $t('others.complete-bookings') }}</div>
                </div>
              </div>
            </div>

            <div class="others login_text mt-6" v-if="!isLoggedIn">
              <h3 class="heading">

               <span  @click="toLogin"> {{ $t("nav.login") }} </span> /
                <span  @click="toRegister"> {{ $t("sign-up.title")}} </span>
              </h3>
              <p class="desc">

                {{ $t('others.login_text') }}
              </p>
            </div>

            <div class="others topbar">
              <router-link :to="{name:'user-favorites'}" class="topbar-box" :class="!isLoggedIn?' gray-color':''">
                <mobile-favorites-icon class="icon"/>
                <span class="title">{{ $t('user.nav.favorites') }}</span>
              </router-link>
              <router-link :to="{name:'user-interests'}" class="topbar-box" :class="!isLoggedIn?' gray-color':''">
                <mobile-interests-icon class="icon"/>
                <span class="title">{{ $t('user.nav.interests') }}</span>
              </router-link>
              <router-link :to="{name:'others-settings'}" class="topbar-box">
                <mobile-settings-icon class="icon"/>
                <span class="title">{{ $t('others.settings') }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>


      <div class="other-nav hav-bg-height" :class="isLoggedIn?'':'small'"></div>
      <div class="others links ">
        <h3 class="text-gray-1 text-med-14 mb-2">{{ $t('common.general_info') }}</h3>
        <ul class="links-list">

          <router-link :to="{name:'about-us'}" class="links-list--link">
            <mobile-about-icon class="icon"/>
            <div class="title">{{ $t('others.about') }}</div>
            <mobile-arrow-icon class="arrow"/>
          </router-link>
          <!--          <li class="links-list&#45;&#45;link">
                      <mobile-law-icon class="icon"/>
                      <div class="title">{{ $t('others.law') }}</div>
                      <mobile-arrow-icon class="arrow"/>
                    </li>-->
          <router-link :to="{name:'faqs'}" class="links-list--link">
            <mobile-faqs-icon class="icon"/>
            <div class="title">{{ $t('others.faqs') }}</div>
            <mobile-arrow-icon class="arrow"/>
          </router-link>
          <router-link :to="{name:'contact-us'}" class="links-list--link">
            <mobile-contact-icon class="icon"/>
            <div class="title">{{ $t('others.contact') }}</div>
            <mobile-arrow-icon class="arrow"/>
          </router-link>
          <li class="links-list--link cursor-pointer" v-if="isLoggedIn" @click="logoutModal=true">
            <mobile-logout-icon class="icon color"/>
            <div class="title text-error">{{ $t('user.nav.logout') }}</div>
            <mobile-arrow-icon class="arrow"/>
          </li>

        </ul>
      </div>
      <div style="height: 100px"></div>
    </div>

    <div class="modal-overlay" v-if="logoutModal">
      <div class="modal-content">
        <div class="icon logout">
          <modal-logout-icon/>
        </div>
        <div class="title">
          {{ $t("user.logout.modal.title") }}
        </div>
        <div class="desc">
          {{ $t("user.logout.modal.desc") }}
        </div>
        <div class="btns">
          <div class="btn btn-danger w-full" @click="logout">{{ $t("btn.logout") }}</div>
          <div class="btn btn-gray-3 w-full" @click="logoutModal=false">{{ $t("btn.cancel") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters} from "vuex";

export default {
  name: "Others",

  data() {
    return {

      logoutModal: false,
    }
  },

  computed: {
    ...mapGetters({
      isLoggedIn: "auth/getIsLoggedIn",
      userInfo: "user/getUserInfo",
      bookingCount: 'user/bookings/getBooingCount'
    }),
  },
  methods: {
    toRegister() {
      this.$router.push({name: 'signUp'});
    },
    toLogin() {
      this.$router.push({name: 'login'});
    },
    async logout() {
      await this.$store.dispatch("auth/logout")
      this.logoutModal = false;
    }
  },

  async created() {
    await this.$store.dispatch("auth/checkIsLoggedIn");
    if (this.isLoggedIn) {
      await this.$store.dispatch('user/bookings/getBookingCount')
      await this.$store.dispatch('user/getUserInfo');
    }
  },
}
</script>