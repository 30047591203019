<template>

  <div class="container section-about my-28">
    <div class="dots">
      <dots-icon/>
    </div>
    <div class="about-images">
      <img class="img1" src="@/assets/images/about/1.webp" alt="">
      <img class="img2" src="@/assets/images/about/2.webp" alt="">
      <img class="img3" src="@/assets/images/about/3.webp" alt="">
    </div>

    <div class="about-info">
      <div class="tag">{{ $t("home.about.subtitle") }}</div>
      <div class="title">{{ $t('home.about.title') }}</div>
      <div class="desc">{{ $t('home.about.desc') }}</div>


      <div class="newsletter">
        <h4 class="text">{{ $t("home.about.newsletter.title") }}</h4>
        <div class="newsletter-form">
          <div class="input">
            <email-outline-icon/>
            <input
                v-model="inputValue" type="text"
                :placeholder="$t('home.about.newsletter.input')">
          </div>
          <LoadingButton :loading="btnLoading" :on-click="subscribe"
                         :name='$t("home.about.newsletter.btn")'/>

        </div>
        <span class="text-reg-10 text-error" v-if="errorMessage.length>0">{{ errorMessage }}</span>

      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import LoadingButton from "../../../components/buttons/loadingButton";

export default {
  name: "about-section",
  components: {LoadingButton},
  data() {
    return {
      inputValue: '',
      errorMessage: this.error ?? "",
      emailPattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    }
  },

  computed: {
    ...mapGetters({
      btnLoading: "app/getInputLoading",
    }),
  },
  watch: {

    inputValue: function (value) {
      this.inputValue = value;

      if (!(this.emailPattern.test(value)) && value.length > 0) {
        this.errorMessage = this.$t("rules.email.valid");
        return;
      }

      this.errorMessage = "";
      this.inputValue = value;

    },

  },
  methods: {
    subscribe() {
      if (this.errorMessage.length === 0) {
        if (this.inputValue.length === 0) {
          this.errorMessage = this.$t("rules.required");
          return;
        }

        this.errorMessage = "";

        this.$store.dispatch('app/subscribeToNewsLetter', {email: this.inputValue}).then(() => {
          this.inputValue = "";
        });

      }
    },
  },
}

</script>
