<template>
  <div class="section-title">
    <h3 class="section-title--heading">{{ title }}</h3>
    <router-link class="section-title--link" :to="{name:link}" v-if="link!=null">{{$t('common.show-all')}}</router-link>
  </div>
</template>

<script>

export default {
  name: "SectionTile",
  props: {
    title: String,
    link: String,
  },

}
</script>
