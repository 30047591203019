export const mutations = {
    setAccountStatus(state, payload) {
        state.accountStatus = payload;
    },
    setCompleteSteps(state, payload) {
        state.completeSteps = payload;
    },

    setNextRoute(state, payload) {
        state.nextRoute = payload;
    },

    setForgetEmail: (state, data) => {
        state.forgetEmail = data
    },

    setIsLoggedIn: (state, data) => {
        state.isLoggedIn = data
    },

    setUserCacheData: (state, data) => {
        state.userCacheData = data
    },

    setCurrentStep: (state,data) => {
        state.currentStep = data
    },
}