// import i18n from "../../i18n";

// import router from "../../routes";


export const actions = {

    async init({commit}) {
        commit("setLoading", true)
        await this._vm.$http.get("halabk/currencies")
            .then((response) => {
                if (response.status === 200) {


                    commit("setCurrencies", response.data.data);


                    let data = localStorage.getItem('currency')
                    if (data == null) {
                        localStorage.setItem('currency', response.data.data[0]["code"])
                        localStorage.setItem('currency_symbol', response.data.data[0]["symbol"])
                        localStorage.setItem('currency_title', response.data.data[0]["title"])
                    }

                    commit("setLoading", false)
                }
            })
            .catch((error) => {
                commit("setLoading", false)
                return Promise.reject(error)
            })
    },

    async setCurrency({commit}, data) {

        if (data.reload === true) {
            commit("app/setLoading", true, {root: true})
        }
        localStorage.setItem('currency', data.currency.code);
        localStorage.setItem('currency_symbol', data.currency.symbol);
        localStorage.setItem('currency_title', data.currency.title);

        commit("setCurrentCurrency", data.currency.code)
        if (data.reload === true) {
            location.reload();
        }

    },


    async getCountries({commit}, data) {
        commit("setLoading", true)
        await this._vm.$http.get("halabk/countries", data)
            .then((response) => {
                if (response.status === 200) {


                    commit("setCountries", response.data.data);
                    commit("setLoading", false)

                }
            })
            .catch((error) => {
                commit("setLoading", false)
                throw error
            })
    },

    async getInterests({commit}, data) {
        commit("setLoading", true)
        await this._vm.$http.get("halabk/interests", data)
            .then((response) => {
                if (response.status === 200) {


                    commit("setInterests", response.data.data);
                    commit("setLoading", false)

                }
            })
            .catch((error) => {
                commit("setLoading", false)
                throw error
            })
    },

    async getAffiliateData({commit}, data) {
        commit("setLoading", true)
        await this._vm.$http.get(`affiliate/${data}`)
            .then((response) => {
                if (response.status === 200) {


                    commit("setAffiliateData", response.data.data);
                    commit("setLoading", false)

                }
            })
            .catch((error) => {
                commit("setLoading", false)
                throw error
            })
    },


    async subscribeToNewsLetter({commit}, data) {
        commit("setInputLoading", true)
        await this._vm.$http.post("halabk/subscribe-newsletter", data)
            .then((response) => {
                if (response.status === 200) {


                    commit("successMessage", 'newsletter')
                    commit("setInputLoading", false)

                }
            })
            .catch((error) => {
                commit("failMessage", 'newsletter')

                commit("setInputLoading", false)
                throw error
            })
    },

    async copiedCode({commit}) {
        commit("successMessage", 'coupon_copied')

    },


    async upload({commit}, data) {
        commit("setLoading", true)
        await this._vm.$http.post("/upload-files", data)
            .then((response) => {
                if (response.status === 200) {


                    commit("setLoading", false)
                    commit("setUploadedFileLink", response.data.data);
                }
            })
            .catch((error) => {
                commit("setLoading", false)
                return Promise.reject(error);
            })
    },


    async setLanguage({commit}, language) {


        commit("app/setLoading", true, {root: true})
        commit("setLanguage", language.toLowerCase());
        if (language.toLowerCase() === 'en') {
            window.location = window.location.href.replace('/ar/', '/en/')
        } else {
            window.location = window.location.href.replace('/en/', '/ar/')
        }
        // location.reload();


    },

    async setLanguageWithoutLoading({commit}, language) {
        commit("setLanguage", language);
    },

    async isMobile({commit}, value) {
        commit("setIsMobile", value);
    },

    async changeMobileLinkStatus({commit}, value) {
        commit("setMobileLinkStatus", value);
    },
    async updateActiveRoute({commit}, value) {
        let currentActiveRoute = 'home';

        if (value.endsWith('/ar/') || value.endsWith('/en/')) {
            currentActiveRoute = 'home';
        } else if (value.includes("/offers")) {
            currentActiveRoute = "offers";
        } else if (value.includes("/services")) {
            currentActiveRoute = "services";
        }else if (value.includes("/destinations")) {
            currentActiveRoute = "destinations";
        } else if (value.includes("/articles")) {
            currentActiveRoute = "articles";
        } else if (value.includes("/bookings")) {
            currentActiveRoute = "user-bookings";
        } else if (value.includes("/others")) {
            currentActiveRoute = "others";
        } else {
            currentActiveRoute = "";
        }
        commit("setCurrentActiveRoute", currentActiveRoute);
    },
}