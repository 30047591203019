export const actions = {

    async getBookings({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`user/bookings?page=${data.page}&filter=${data.filter}`, data)
            .then((respo) => {
                if (respo.status === 200) {

                    if (data.filter === "complete") {
                        commit("setCompleteBookings", respo.data.data)
                    } else {
                        commit("setCurrentBookings", respo.data.data)
                    }


                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

    async getBookingCount({commit}) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`user/bookings/count`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setBookingCount", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

    async getBookDetails({commit}, id) {
        commit("app/setLoading", true, {root: true})
        this._vm.$http.get(`user/bookings/${id}`)
            .then((respo) => {
                if (respo.status === 200) {
                    commit("setBookDetails", respo.data.data)
                    commit("app/setLoading", false, {root: true})
                }
                return respo;
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


    async downloadInvoice({commit}, id) {
        commit("app/setLoading", true, {root: true})
        this._vm.$http.get(`user/bookings/${id}/invoice`)
            .then((respo) => {
                if (respo.status === 200) {
                    window.open(respo.data.data).focus();
                    commit("app/setLoading", false, {root: true})
                }
                return respo;
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


    async sendReview({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post(`user/bookings/${data.id}/review`, data)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("app/setInputLoading", false, {root: true})
                    commit("setComment", respo.data.data)

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

}