<template>
  <div class="container mobile-container">
    <PageHeading :title="destination.title"
                 :paths="[
          {title:$t('destinations.title'),link:'.'},
          ]"
    />
    <div class="offers-search">
      <div class="search-input">
        <input type="text" @input="getSearchServices" :value="search" :placeholder="$t('input.search.title')">
        <search-icon/>
      </div>
      <div class="mobile-fixed-height"></div>
      <div class="search-filter mobile-fixed">
        <div class="count">
          {{ total }} {{ $t('service.available-package') }}
        </div>

        <div class="filters">


          <div class="flex items-center gap-2">
            <div class="text-med-14">{{ $t("common.order-by") }}</div>

            <label class="select">
              <select required="required" @change="changeOrderItems">
                <option value="">{{ $t('common.order.all') }}</option>
                <option value="place">{{ $t('common.order.place') }}</option>
                <option value="date">{{ $t('common.order.date') }}</option>
              </select>
              <arrow-down-icon/>
            </label>
          </div>

          <div class="flex items-center gap-2">
            <div class="text-med-14">{{ $t("common.show") }}</div>

            <label class="select">
              <select required="required" @change="changeItemsPerPage">
                <option value="12" selected="selected">12</option>
                <option value="24">24</option>
                <option value="48">48</option>
              </select>
              <arrow-down-icon/>
            </label>
          </div>


        </div>
      </div>
    </div>

    <div class="offers-container">
      <div class="offers-container--filters-search-mobile" ref="filter_search_container" id="filter_search_container">
        <div class=" search-bg">
          <img class="w-full" src="@/assets/patterns/pattern_1.png" alt="halabk pattern">
          <div class="flex items-center gap-4 relative pt-6 px-4 z">

            <input type="text" class="border-radius p-2 w-4/5 " @input="getSearchServices" :value="search"
                   :placeholder="$t('input.search.title')">

            <div class="text-white text-med-14 p-2  ml-auto" @click="cancelSearch">
              {{ $t('common.cancel') }}
            </div>
          </div>
        </div>

        <div class="search-bg-height"></div>
        <div class="search-keywords">
          <h3 class="text-gray-1 text-med-14 mb-3">{{ $t('service.keywords') }}</h3>
          <ul>
            <li class="keyword"
                v-for="(keyword, index) in servicesSuggestions"
                :key="index"
                @click="()=>setSearchValue(keyword)">{{ keyword }}
            </li>

          </ul>
        </div>
      </div>


      <div class="offers-container--filters " ref="filter_container" id="filter_container">

        <div class="filter-heading h-hide">
          <h3 class="text-med-17 text-gray-1">{{ $t('service.filter') }}</h3>
          <div class="gray-box" @click="closeFilter">
            <close-icon/>
          </div>
        </div>
        <div class="filter-btns h-hide">
          <div class=" bg-primary border-radius text-white" @click="closeFilter">
            {{ $t('service.show_result', {no: total}) }}
          </div>

          <div class=" text-gray-2" @click="clearFilter">
            {{ $t('service.clear_result') }}
          </div>


        </div>

        <Accordion v-for="filter in filters"
                   :key="filter.key"
                   :title="filter.title">
          <div slot="content" class="flex gap-2 flex-col">

            <template v-if="filter.type==='ListCheckbox'">
              <CheckBox v-for="(item ,index) in filter.data"
                        :key="index"
                        :value="item.id"
                        :label="item.title"
                        :count="item.count"
                        :on-change="(e)=>onCheckChange(e,filter.key)"
              />
            </template>

            <template v-if="filter.type==='Date'">
              <div class="mb-1">
                <div
                    class="calendar-placeholder border-radius bg-background py-2 px-3 flex items-center gap-2 text-med-14 text-input mb-4">
                  <calendar-outline-icon class="small-icon icon-gray-4"/>
                  <!--                  <div>{{ filter.title }}</div>-->
                  <div class="max-1-lines" dir="rtl">
                    {{ dateRange.start | moment('yyyy-MM-D') }} - {{ dateRange.end | moment('yyyy-MM-D') }}
                  </div>

                  <div class="bg-gray-4 bg-opacity-4 p-1 border-radius-small ml-auto cursor-pointer"
                       @click="removeDateFilter">
                    <close-icon class="p-0.5"/>
                  </div>
                </div>

                <v-date-picker :key="datePickerKey" ref="calendar" v-model="dateRange" is-range @input="onDayClick"
                               :class="'app-calender'"
                               :locale="$i18n.locale"/>

              </div>
            </template>

            <template v-if="filter.type==='RangeSlider'">
              <div class="pt-2">

                <vue-slider v-model="price" v-bind="language==='en'?rangeOptionsLTR:rangeOptions"
                            @change="(value)=>onRangeSlideChange(value,  filter.key)"
                            @drag-end="onRangeSlideChangeEnd"
                            :max="500" :interval="0.50"/>

              </div>
              <div class="flex items-center justify-between">
                <div class="text-reg-14 flex gap-2">
                  <div class="text-primary">{{
                      appliedFilters[filter.key] ? ("min" in appliedFilters[filter.key])
                          ? PriceCalc(appliedFilters[filter.key]["min"]) : PriceCalc(0) : PriceCalc(0)
                    }}
                  </div>
                </div>
                <div class="text-reg-14 flex gap-2">
                  <div class="text-primary">{{
                      appliedFilters[filter.key] ? ("max" in appliedFilters[filter.key])
                          ? PriceCalc(appliedFilters[filter.key]["max"]) : PriceCalc(0) : PriceCalc(0)
                    }}
                  </div>
                </div>
              </div>
            </template>

            <template v-if="filter.type==='Slider'">
              <div class="pt-2">

                <vue-slider v-model="appliedFilters[filter.key]"
                            @change="(value)=>onSliderChange(value,  filter.key)"
                            @drag-end="onSliderChangeEnd"
                            v-bind="language==='en'?rangeOptionsLTR: rangeOptions"/>

              </div>
              <div class="text-reg-14 flex gap-2">
                <p class="text-primary">{{ appliedFilters[filter.key] }}</p>
                {{ filter.unit }}
              </div>
            </template>

          </div>

        </Accordion>


      </div>
      <div class="div" :class="services.length===0?' w-full':''">


        <div class=" details-container mb-5">

          <div class="details--heading">
            <h3>
              {{ $t("destinations.heading") }}
            </h3>
          </div>

          <div class="details-divider"></div>
          <div class="px-4 text-reg-14 text-gray-2">
            {{ destination.content }}
          </div>
        </div>


        <Nothing
            v-if="services.length===0"
            :title="$t('nothing.services.title')"
            :desc="$t('nothing.services.desc')"
        />

        <div class="offers-container--grid">
          <ServiceCard v-for="service in services"
                       :key="service.id"
                       :service="service"/>
        </div>

        <paginate
            v-if="pageCount>1"
            v-model="page"
            class="mt-12"
            :page-count="pageCount"
            :page-range="4"
            :margin-pages="1"

            :prev-text="'<'"
            :next-text="'>'"

            :click-handler="paginationCallback"

            :container-class="'pagination'"
            :break-view-text="'...'"
            :page-class="'page-item'">
        </paginate>
      </div>
    </div>


  </div>
</template>

<style>
[dir="ltr"] .vc-arrow {
  transform: rotate(0deg) !important;
}

[dir="ltr"] .calendar-placeholder {
  flex-direction: row-reverse;
}

.vc-container {

  --blue-100: rgba(254, 208, 41, .1);
  --blue-200: rgba(254, 208, 41, .2);
  --blue-300: rgba(254, 208, 41, .3);
  --blue-400: rgba(254, 208, 41, .4);
  --blue-500: rgba(254, 208, 41, .5);
  --blue-600: rgba(254, 208, 41, .6);
  --blue-700: rgba(254, 208, 41, .7);
  --blue-800: rgba(254, 208, 41, .8);
  --blue-900: #4C535F;

  border: none !important;
}

.vc-title {
  color: #4C535F !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.vc-weekday {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 20px !important;
}

.vc-day {
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 20px !important;
}

.vc-arrow {
  transform: rotate(180deg);
}


.vc-highlight {
  border-radius: 6px !important;
}

.vc-day-content {
  border-radius: 6px !important;
}

.vc-day-content:hover, .vc-day-content:focus {
  background-color: var(--blue-100) !important;

}
</style>

<script>
import paginate from "vuejs-paginate";
import PageHeading from "../../components/PageHeading";
import ServiceCard from "../../components/ServiceCard";
import Accordion from "../../components/Accordion";
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/antd.css"
import CheckBox from "../../components/inputs/checkbox";
import {mapGetters} from "vuex";
import Nothing from "../../components/nothing";
// https://nightcatsama.github.io/vue-slider-component/#/basics/simple
export default {
  name: "DestinationDetail",
  components: {Nothing, CheckBox, Accordion, ServiceCard, PageHeading, paginate, VueSlider},
  data() {
    const date = new Date();
    const day = date.getDay();
    const month = date.getMonth();
    const year = date.getFullYear();
    return {
      searchTime: 2,
      page: this.$route.query.page ?? 1,
      per_page: this.$route.query.per_page ?? 12,
      datePickerKey: 0,
      seats: 0,
      search: "",
      price: [0, 0],
      dateRange: {
        start: new Date(year, month, day),
        end: new Date(year, month, day),
      },
      rangeOptions: {
        contained: false,
        direction: "rtl",
        clickable: false,
        min: 0,
        max: 18,
        tooltip: "none",
        processStyle: {
          background: "#5D3EBD",
        },
        dotStyle: {
          border: "1px solid #5D3EBD",
        },
      },
      rangeOptionsLTR: {
        contained: false,
        direction: "ltr",
        clickable: false,
        min: 0,
        max: 18,
        tooltip: "none",
        processStyle: {
          background: "#5D3EBD",
        },
        dotStyle: {
          border: "1px solid #5D3EBD",
        },
      },

      appliedFilters: {
        search: "",
        hide: ["destinations"],
      },
    }
  },


  methods: {

    closeFilter() {
      this.$refs.filter_container.classList.remove('show-mobile')
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    },
    async clearFilter() {
      [].forEach.call(document.querySelectorAll('input[type="checkbox"]'), function (el) {
            el.checked = false;
          }
      )
      const date = new Date();

      this.dateRange.start = date;
      this.dateRange.end = date;
      this.datePickerKey += 1;
      this.seats = 0;
      this.search = "";
      this.price = [0, 0];

      this.appliedFilters = {};
      await this.getServices();
    },
    async onDayClick() {
      let startDate = (new Date(this.dateRange.start).toISOString())
      let endDate = (new Date(this.dateRange.end).toISOString())
      this.appliedFilters['date'] = {
        from: startDate,
        to: endDate
      };
      await this.getServices();

    },
    paginationCallback(page) {
      this.page = page;

      this.getServices();
    },

    changeOrderItems(e) {
      this.appliedFilters.orderBy = e.target.value;
      this.getServices();
    },

    changeItemsPerPage(e) {
      this.per_page = e.target.value;
      this.getServices();
    },
    removeDateFilter() {
      const date = new Date();

      this.dateRange.start = date;
      this.dateRange.end = date;
      this.datePickerKey += 1;


      delete this.appliedFilters['date']
      this.getServices()
    },


    getServices() {
      this.$store.dispatch("services/getFilteredServices", {
        page: this.page,
        per_page: this.per_page, ...this.appliedFilters,
        destinations: [this.destination.id],
      })
    },
    setSearchValue(value) {
      this.search = '';
      this.appliedFilters.search = value;//this.search;
      this.$refs.filter_search_container.classList.remove('show-mobile')
      this.getServices();
    },
    cancelSearch() {
      this.search = '';
      this.appliedFilters.search = '';
      this.$refs.filter_search_container.classList.remove('show-mobile')
      this.getServices();
    },
    async getSearchServices(e) {
      this.searchTime = 2;
      this.search = e.target.value;
      this.appliedFilters.search = e.target.value;
      if (this.searchTime >= 2) {
        clearInterval(this.searchInterval);
      }

      this.searchInterval = setInterval(() => {
        this.searchTime--;
        if (this.searchTime === 0) {
          this.$refs.filter_search_container.classList.remove('show-mobile')
          clearInterval(this.searchInterval);
          this.getServices();
        }
      }, 1000);


    },

    async onCheckChange(e, filter) {

      if (!(filter in this.appliedFilters)) {
        this.appliedFilters[filter] = [];
      }

      if (!this.appliedFilters[filter].includes(e.target.value)) {
        this.appliedFilters[filter].push(e.target.value);
      } else {
        if (!e.target.checked) {
          this.appliedFilters[filter] = this.appliedFilters[filter].filter(item => item !== e.target.value);
        }
      }

      this.page = 1;
      await this.getServices();

    },

    async onSliderChange(value, filter) {
      this.appliedFilters[filter] = value;
    },

    async onSliderChangeEnd() {
      this.page = 1;
      await this.getServices();
    },

    async onRangeSlideChange(value, filter) {
      this.appliedFilters[filter] = {"min": value[0], "max": value[1]};
    },


    async onRangeSlideChangeEnd() {
      this.page = 1;
      await this.getServices();
    },


  },

  computed: {
    ...mapGetters({
      destination: "destinations/getDestinationDetail",
      services: "services/getFilteredServices",
      total: "services/getTotalFilteredServices",
      filters: "services/getServiceFilters",
      pageCount: "services/getFilteredPageCount",
      language: "app/getLanguage",
      isMobile: "app/getIsMobile",
      servicesSuggestions: "services/getServiceSuggestions",
    }),
  },
  async created() {
    this.$store.commit('app/setNotification404', false)

    await this.$store.dispatch("destinations/getDestinationDetail", {slug: this.$route.params.slug}).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({name: '404'})
      }

    });
    this.$store.dispatch('services/getServicesSuggestions')
    this.getServices();
    this.$moment.locale("en");

  },


  mounted() {
    setTimeout(() => {
      console.log('this is out side of the mounted function')
      if (this.isMobile) {
        console.log('---------------------the mounted function')
        let showBoxes = document.getElementsByClassName('show-in-filter-page');
        for (let i = 0; i < showBoxes.length; i++) {
          showBoxes[i].style.cssText = "";
        }

        let boxes = document.getElementsByClassName('hide-in-filter-page');
        for (let i = 0; i < boxes.length; i++) {
          boxes[i].style.cssText = "display: none !important";
        }
      }

    }, 500)

  },
  beforeDestroy() {

    this.$store.commit('app/setNotification404', true)

    if (this.isMobile) {
      let showBoxes = document.getElementsByClassName('show-in-filter-page');
      for (let i = 0; i < showBoxes.length; i++) {


        showBoxes[i].style.cssText = "display: none !important";
      }
      let boxes = document.getElementsByClassName('hide-in-filter-page');
      for (let i = 0; i < boxes.length; i++) {
        boxes[i].style.cssText = "";
      }
    }
  }

}
</script>