import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "../i18n"
import Home from "../views/home/Home";
import Login from "../views/registration/login/login"
import SignUp from "../views/registration/signUp/signUp"
import ForgetPassword from "../views/registration/forgetPassword/forgetpassword"
import ResetPassword from "../views/registration/resetPassword/resetpassword"
import ConfirmCode from "../views/registration/resetPassword/confirmCode"
import MainLayout from "../views/_layouts/main";
import Layout2 from "../views/_layouts/layout_2";
import Destinations from "../views/destinations/Destinations";
import ServiceFilter from "../views/services/ServicesFilter";
import ServiceDetail from "../views/services/detail/ServiceDetail";
import FAQs from "../views/others/FAQs";
import Others from "../views/others/Others";
import TermsAndConditions from "../views/others/TermsAndConditions";
import PrivacyPolicy from "../views/others/PrivacyPolicy";
import Articles from "../views/articles/Articles";
import ServiceBook from "../views/services/ServiceBook";
import Profile from "../views/user/Profile";
import ChangePassword from "../views/user/ChangePassword";
import UserInterests from "../views/user/interests/Interests";
import Notifications from "../views/user/Notifications";
import Chat from "../views/chat/Chat";
import profile_layout from "../views/user/_layout/profile_layout";
import ArticleDetail from "../views/articles/ArticleDetail";
import Bookings from "../views/user/bookings/Bookings";
import BookingDetail from "../views/user/bookings/BookingDetail";
import Favorites from "../views/user/favorites/Favorites";
import ProviderDetail from "../views/provider/ProviderDetail";
import ContactUs from "../views/others/ContactUs";
import Page404 from "../views/others/404";
import ChatList from "../views/user/ChatList";
import Services from "../views/services/index/Services";

import isUser, {isGuest, isUserFromMobile, provideBookInfo} from "./middleware";
import DestinationDetail from "../views/destinations/DestinationDetail";
import AboutUs from "../views/others/About";
import OthersSettings from "@/views/others/OthersSettings";
import Partners from "../views/partner/index";
import AffiliateLayout from "../views/affiliate/layout/affiliate_layout";
import Affiliate from "../views/affiliate/affiliate";
// import Invoice from "../views/others/invoice";
import {store} from "../store/index"

Vue.use(VueRouter);

const routes = [
        {
            path: "/",
            redirect: `/${i18n.locale}/`,
        },
        {

            path: "/:lang",
            component: {
                render(c) {
                    return c("router-view")
                },
            },

            children: [

                {

                    path: "login",
                    component: Layout2,

                    children: [
                        {
                            path: "",
                            name: "login",
                            component: Login,
                            meta: {
                                middleware: isGuest,
                            },
                        },
                    ],
                },
                {

                    path: "affiliate/:coupon",
                    component: AffiliateLayout,

                    children: [
                        {
                            path: "",
                            name: "affiliate",
                            component: Affiliate,
                        },
                    ],
                },
                {

                    path: "signup",
                    component: Layout2,
                    props: {topClass: "top-300"},

                    children: [
                        {
                            path: "",
                            name: "signUp",
                            component: SignUp,
                            meta: {
                                middleware: isGuest,
                            },
                        },
                    ],
                },

                {

                    path: "forget-password",
                    component: Layout2,
                    props: {topClass: "top-300"},

                    children: [
                        {
                            path: "",
                            name: "forgetPassword",
                            component: ForgetPassword,
                            meta: {
                                middleware: isGuest,
                            },
                        },
                    ],
                },

                {
                    path: "confirm-token",
                    component: Layout2,
                    props: {topClass: "top-300"},

                    children: [
                        {
                            path: "",
                            component: ConfirmCode,
                            meta: {
                                middleware: isGuest,
                            },
                        },
                    ],
                },

                {
                    path: "reset-password",
                    component: Layout2,
                    props: {topClass: "top-300"},

                    children: [
                        {
                            path: "",
                            component: ResetPassword,
                            meta: {
                                middleware: isGuest,
                            },
                        },
                    ],
                },

                {
                    path: "/",
                    component: MainLayout,

                    children: [
                        {
                            name: "home",
                            path: "",
                            component: Home,

                        },
                    ],
                },
                {
                    path: "/",
                    component: MainLayout,
                    props: {topClass: "top-200"},

                    children: [


                        {
                            path: "destinations",
                            name: "destinations",
                            component: Destinations,
                        },
                        {
                            path: "others",
                            name: "others",
                            component: Others,
                        },
                        {
                            path: "others/settings",
                            name: "others-settings",
                            component: OthersSettings,
                        },
                        {
                            path: "destinations/:slug",
                            name: "destinationDetail",
                            component: DestinationDetail,
                        },

                        {
                            path: "offers",
                            name: "offers",
                            component: ServiceFilter,
                        },
                        {
                            path: "partners",
                            name: "partners",
                            component: Partners,
                        },
                        {
                            path: "services",
                            name: "services",
                            component: Services,
                        },
                        {
                            path: "services/:slug",
                            component: ServiceDetail,
                        },
                        {
                            path: "services/:serviceSlug/provider/:providerSlug",
                            component: ProviderDetail,
                        },
                        {
                            path: "providers/:providerSlug",
                            component: ProviderDetail,
                        },
                        {
                            path: "services/:slug/book",
                            component: ServiceBook,
                            meta: {
                                middleware: [isUser, provideBookInfo],
                            },
                        },
                        {
                            path: "faqs",
                            name: "faqs",
                            component: FAQs,
                        },
                        {
                            path: "terms",
                            name: "terms",
                            component: TermsAndConditions,
                        },
                        {
                            path: "contact-us",
                            name: "contact-us",
                            component: ContactUs,
                        },
                        {
                            path: "about-us",
                            name: "about-us",
                            component: AboutUs,
                        },
                        {
                            path: 'page-not-found',
                            name: "404",
                            component: Page404
                        },
                        {
                            path: "privacy-policy",
                            name: "privacy-policy",
                            component: PrivacyPolicy,
                        },
                        {
                            path: "articles",
                            name: "articles",
                            component: Articles,
                        },
                        {
                            path: "articles/:slug",
                            component: ArticleDetail,
                        },

                        {
                            path: "notifications",
                            name: "notifications",
                            component: Notifications,

                            meta: {
                                middleware: isUser,
                            },
                        },

                        {
                            path: "chat",
                            name: "chat",
                            component: ChatList,
                            meta: {
                                middleware: isUser,
                            },
                        },
                        {
                            path: "chat/:id",
                            component: Chat,
                            meta: {
                                middleware: isUser,
                            },
                        },
                        {
                            path: "profile/bookings/:id",
                            component: BookingDetail,
                            meta: {
                                middleware: isUser,
                            },
                        },

                    ],
                },

                {
                    path: "profile",
                    component: profile_layout,
                    props: {topClass: "top-200"},

                    children: [
                        {
                            path: "",
                            component: Profile,
                            name: 'user-profile',
                            meta: {
                                title: 'user.nav.edit',
                                middleware: isUser,
                            },
                        },
                        {
                            path: "change-password",
                            name: "user-change-password",
                            component: ChangePassword,
                            meta: {
                                title: 'user.nav.password',
                                middleware: isUser,
                            },
                        },
                        {
                            path: "interests",
                            name: "user-interests",
                            component: UserInterests,
                            meta: {
                                title: 'user.nav.interests',
                                middleware: isUser,
                            },
                        },

                        {
                            path: "favorites",
                            name: "user-favorites",
                            component: Favorites,
                            meta: {
                                title: 'user.nav.favorites',
                                middleware: isUser,
                            },
                        },
                        {
                            path: "bookings",
                            name: "user-bookings",
                            component: Bookings,

                            meta: {
                                title: 'user.nav.bookings',
                                showBack: false,
                                middleware: isUserFromMobile,
                            },
                        },


                    ],
                },

            ],

        },


        // {
        //     path:'*',
        //
        //     component: MainLayout,
        //     children: [
        //         {
        //             component: Page404
        //         },
        //     ]
        // },


    ]
;
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes, scrollBehavior() {
        return {x: 0, y: 0};
    },
});
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    var lang = to.params.lang;
    if (lang != 'ar' && lang != 'en') {
        to.params.lang = 'ar';
        window.location.href = '/';
    }
    store.dispatch('app/updateActiveRoute', to.path);
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});


export default router;