<template>
  <div>

    <div class="container mobile-container">

      <PageHeading :title="detail.title" :paths="[{title: $t('nav.our-offers'),link:'../offers'}]" :show-icons="false" :class="scrollClass">

        <div class="flex gap-3">
          <div class=" nav-box show-in-filter-page h-hide" @click="favoriteClick">
            <heart-icon/>
          </div>
          <div class=" nav-box show-in-filter-page h-hide" @click="share">
            <mobile-share-icon/>
          </div>

        </div>

      </PageHeading>


      <symbol id="icon-star" viewBox="0 0 12 12">
        <path id="Path_147238" data-name="Path 147238"
              d="M13.649,7.759a1.271,1.271,0,0,0,.294-1.3A1.255,1.255,0,0,0,12.928,5.6l-2.576-.4a.084.084,0,0,1-.054-.048l-1.129-2.4a1.268,1.268,0,0,0-2.312-.018l-1.129,2.4a.1.1,0,0,1-.06.048L3.1,5.6a1.273,1.273,0,0,0-1.021.858,1.289,1.289,0,0,0,.3,1.3l1.9,1.95a.1.1,0,0,1,0,.078L3.83,12.51a1.273,1.273,0,0,0,1.88,1.326l2.24-1.242a.078.078,0,0,1,.072,0l2.24,1.242a1.279,1.279,0,0,0,1.88-1.326L11.727,9.8a.072.072,0,0,1,0-.072Z"
              transform="translate(-2.012 -2)"/>
      </symbol>

      <div class="service-detail service-detail-container padding-top-mobile" id="service-detail-container">
        <div class="content">

          <div class="mobile-swiper-top  h-hide">
            <div class=" nav-box show-in-filter-page h-hide" @click="goBack"
            >
              <mobile-back-icon/>
            </div>
            <div class=" nav-box show-in-filter-page h-hide" @click="share"
            >
              <mobile-share-icon/>
            </div>
          </div>
          <!-- Slider main container -->
          <div class="swiper overflow-hidden pb-8 service-swiper " id="main-swiper">
            <div class=" service-swiper-tags">
              <div class="service-card-offer-tag bg-secondary" v-if="closePercent>=50">
                {{ $t("common.close-soon") }}
                {{ closePercent }}%
              </div>
              <div class="service-card-offer-tag bg-primary" v-if="detail.offer!=null">
                {{ $t("common.discount") }}
                {{ detail.offer.discount_percent }}%
              </div>

            </div>
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <div class="swiper-slide" v-for="slide in detail.slider" :key="slide.id">
                <img :src="slide.media" :alt="slide.name"/>
              </div>

            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>

            <!-- If we need navigation buttons -->
            <div class="swiper-button-prev" v-if="detail.slider.length>1"></div>
            <div class="swiper-button-next" v-if="detail.slider.length>1"></div>

          </div>


          <div class="pref">
            <pref-tile :name="$t('service.detail.days')"
                       :value="`${detail.service_period===0?1:detail.service_period} ${$t('common.days')}`">
              <service-time-icon/>
            </pref-tile>
            <div class="pref-divider"></div>
            <pref-tile :name="$t('service.detail.people')" :value="detail.max_people">
              <service-person-icon/>
            </pref-tile>
            <div class="pref-divider"></div>
            <pref-tile :name="$t('service.detail.type')" :value="detail.service_type">
              <service-baggage-icon/>
            </pref-tile>
            <div class="pref-divider"></div>
            <pref-tile :name="$t('service.detail.supervisors')"
                       :value="`${detail.service_supervisors} ${$t('common.supervisor')}`">
              <service-supervisor-icon/>
            </pref-tile>


          </div>


          <div class="info-mobile" id="main-info">
            <div class="info">
              <div class="name">

                <h1>{{ detail.title }}</h1>
                <div class="info-location-rate">
                  <div class="flex items-center  flex-wrap">
                    <div class="flex items-center gap-1 pr-4 ">
                      <location-fill-icon :width="15" :height="15"/>
                      {{ detail.city }}, {{ detail.country }}
                    </div>
                    <div class="flex items-center gap-1">
                      <calendar-service-icon :width="15" :height="15"/>
                      {{ detail.start_date | moment('yyyy/MM/D') }} - {{ detail.end_date | moment('yyyy/MM/D') }}
                    </div>
                  </div>

                  <!--                <div class="rates">

                                    <rate class="RateCustom" :length="5" :value="detail.review.rates.rate_avg" :readonly="true" iconref="icon-star"/>
                                    <div class="value">
                                      ({{ detail.review.rates.rate_count }}) {{ detail.review.rates.rate_avg }}
                                    </div>
                                  </div>-->

                </div>
              </div>
              <div class="btns">

                <button :class="`fav-btn btn  ${favorite?'is-favorite':''}`" @click="favoriteClick">
                  <heart-icon class="icon"/>
                  <div v-if="!favorite" class=" h-show">{{ $t("common.to-favorite") }}</div>
                </button>

                <button class="btn h-show" @click="share">
                  <share-icon class="icon stroke-none"/>
                  <div>{{ $t("common.share") }}</div>
                </button>

              </div>
            </div>

          </div>
          <div class="details">
            <div class="desc">
              <div class="header">
                <a class="header-link" @click="scrollToSection('service-detail')">{{
                    $t("service.detail.tabs.detail")
                  }}</a>
                <a class="header-link"
                   @click="scrollToSection('service-timeline')">{{ $t("service.detail.tabs.timeline") }}</a>
                <a class="header-link" v-if="detail.geolocation!=null"
                   @click="scrollToSection('service-location')">{{ $t("service.detail.tabs.location") }}</a>
                <!--                <div class="header-link">{{ $t("service.detail.tabs.comments") }}</div>-->
              </div>
              <div class="desc-info" id="service-detail">
                <p>
                  {{ detail.content }}
                </p>
                <div class="details-divider"></div>

                <div class="features">
                  <h3 class="desc-info-heading">{{ $t("service.detail.included") }}</h3>
                  <div class="features-list">

                    <ul class="has" v-if="detail.included_service && detail.included_service.length>0">
                      <li v-for="(included,index) in detail.included_service" :key="index">{{ included }}</li>
                    </ul>

                    <ul class="has-not" v-if="detail.excluded_service && detail.excluded_service.length>0">
                      <li v-for="(excluded,index) in detail.excluded_service" :key="index">{{ excluded }}</li>
                    </ul>

                  </div>
                </div>

                <div class="details-divider"></div>
                <div class="terms">

                  <h3 class="desc-info-heading">{{ $t("service.detail.terms") }}</h3>
                  <ul class="terms-list"
                      v-for="(terms,index) in detail.terms_and_conditions"
                      :key="index"
                  >
                    <li>{{ terms }}</li>
                  </ul>
                </div>
              </div>
            </div>

            <itinerary-section id="service-timeline" :timelines="detail.timeline"/>

            <location-section id="service-location" v-if="detail.geolocation!=null"
                              :location-lat="detail.geolocation.split(',')[0]"
                              :location-lng="detail.geolocation.split(',')[1]"
            />
            <!--

                        <comments-section
                            :review="detail.review"
                            :service-id="detail.id"
                        />
            -->

            <faqs-section :faqs="detail.faqs"/>
          </div>


        </div>


        <div class="options-side">

          <div class="options">
            <div class="price-info">
              <div class="flex items-end justify-between text-reg-12 text-gray-2">
                <div class="service-price-info">
                  <div class="price" :class="detail.offer==null?' mb-2':''">
                    {{ PriceCalc(calcPriceWithDiscount(detail.price)) }}
                    <div class="price--old" v-if="detail.offer!=null">
                      {{ PriceCalc(detail.price) }}
                    </div>
                  </div>

                </div>
                {{ $t("service.person-price") }}
              </div>
              <div class="note">{{ $t("common.price.without-tax") }}</div>
            </div>

            <div class="mx-4">

              <div>
                <div class="options-inputs mt-2">

                  <counter
                      v-for="counter in detail.service_counters"
                      :key="counter.id"
                      :counter-id="counter.id"
                      :title="counter.title"
                      :price="counter.price"
                      :price-with-discount="calcPriceWithDiscount(counter.price)"
                      :package-price="calcPriceWithDiscount(bookForm.package.price)"
                      @onCount="serviceCounter"
                      :placeholder="counter.title"/>

                </div>
                <div class="input--error mt-2" v-if="error.counter.length>0">{{ error.counter }}</div>
              </div>

              <div class="packages my-4">
                <h3 class="text-reg-14 text-gray-1 mb-1">{{ $t("service.detail.packages") }}</h3>
                <div class="packages-options">

                  <div class="packages-options--package"
                       v-for="(servicePackage,index) in detail.service_packages"
                       :key="servicePackage.id"
                  >
                    <label class="flex items-center gap-2">
                      <div>
                        <div class="mt-2"></div>
                        <input :value="servicePackage.id"
                               type="radio"
                               name="radio-group"
                               @change="chooseServicePackage"
                               :data-price="servicePackage.additional_price"
                               :data-title="servicePackage.title"
                               v-model="bookForm.package"
                               :checked="servicePackage.additional_price===0">
                      </div>
                      <span v-if="index===0" >{{ $t(servicePackage.title) }}</span>
                      <span v-else >{{ servicePackage.title }}</span>
                    </label>

                    <div class="flex-none flex items-center gap-2">
                      <div v-if="servicePackage.additional_price>0" class="price">
                        {{ PriceCalc(calcPriceWithDiscount(servicePackage.additional_price)) }}
                      </div>
                      <div
                          v-if="servicePackage.additional_price!=calcPriceWithDiscount(servicePackage.additional_price)"
                          class="price old-price">
                        {{ PriceCalc(servicePackage.additional_price) }}
                      </div>
                    </div>
                  </div>


                </div>

                <div class="input--error mt-2" v-if="error.package.length>0">{{ error.package }}</div>
              </div>
              <div class="amount">
                <div class="item">
                  <div class="name">{{ $t("common.price.total") }}:</div>
                  <div class="value">{{ PriceCalc(calTotal) }}</div>
                </div>

                <div class="item" v-if="calDiscount>0">
                  <div class="name">{{ $t("common.price.discount") }}:</div>
                  <div class="value text-error">- {{ PriceCalc(calDiscount) }}</div>
                </div>

                <div class="divider my-1"></div>


                <div class="item mt-1" v-if="calDiscount>0">
                  <div class="name">{{ $t("common.price.total-after-discount") }}:</div>
                  <div class="value text-primary">{{ PriceCalc(calAfterDiscount) }}</div>
                </div>

                <div class="item">
                  <div class="name">{{ $t("common.price.tax") }}:</div>
                  <div class="value">{{ PriceCalc(calTax) }}</div>
                </div>

                <div class="divider my-1"></div>

                <div class="item mt-2">
                  <div class="name">{{ $t("common.price.total-price") }}:</div>
                  <div class="value text-primary">{{ PriceCalc(calTotalAfterTax) }}</div>
                </div>

                <router-link :to="bookLink" class="btn btn-primary btn-flex btn-white mt-2" v-if="!isLoggedIn">
                  <div> {{ $t("nav.login") }}</div>
                  <circle-arrow-icon class="icon-reverse-ltr"/>
                </router-link>
                <div @click="goToBookPage" class="btn btn-primary  btn-white text-center mt-2"
                     v-if="isLoggedIn">
                  <div class="text-center"> {{ $t("service.book") }}</div>
                </div>

              </div>
            </div>
          </div>

          <ProviderCard :provider="detail.provider" :service-slug="detail.slug"/>

        </div>

      </div>


    </div>

    <div class="similar mt-1" v-if="detail.similar.length>0">
      <div class="container text-semi-20 text-gray-1 my-4">{{ $t('service.similar') }}</div>
      <!-- Slider main container -->
      <div class="swiper-similar pb-8 service-swiper ">


        <!-- Additional required wrapper -->
        <div class="swiper-wrapper" id="similar-swiper-wrapper">
          <!-- Slides -->

          <div class="swiper-slide" v-for="service in detail.similar" :key="service.id">

            <ServiceCard cssClass="small max-w" :service="service"/>

          </div>


        </div>

      </div>


    </div>
  </div>
</template>

<style>

.swiper-similar .swiper-slide {
  width: 296px;
  display: inline-block;
  box-sizing: border-box;

}

.swiper-similar {
  width: 80%;
  max-width: 1152px !important;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
}
</style>
<script>
import PageHeading from "../../../components/PageHeading";

import "swiper/swiper.scss"
import "swiper/modules/pagination/pagination.scss"
import "swiper/modules/navigation/navigation.scss"

import Swiper, {Navigation, Pagination} from "swiper";


import PrefTile from "./components/prefTile";
// import rate from "vue-rate/src/Rate"
// import "vue-rate/dist/vue-rate.css"
import ItinerarySection from "./detailSections/itinerary";
import LocationSection from "./detailSections/location";
import FaqsSection from "./detailSections/faqs";
// import CommentsSection from "./detailSections/comments";
import Counter from "./components/counter";
import ServiceCard from "../../../components/ServiceCard";
import ProviderCard from "../../../components/ProviderCard";
import {mapGetters} from "vuex";

import moment from 'moment';

export default {
  name: "ServiceDetail",

  components: {
    ProviderCard,
    ServiceCard,
    Counter,
    // CommentsSection,
    FaqsSection,
    LocationSection,
    ItinerarySection,


    PrefTile,
    PageHeading,
    // rate,
  },
  data() {
    return {
      bookForm: {
        package: {title: "", price: 0, id: 0},
        counters: [],
      },
      scrollClass:'h-show',
      error: {
        package: "", counter: "",
      },
    }
  },

  computed: {
    ...mapGetters({
      detail: "services/getServiceDetail",
      isLoggedIn: "auth/getIsLoggedIn",
      isMobile: 'app/getIsMobile'
    }),

    closePercent() {
      return (((this.detail.max_people - this.detail.available_people) / this.detail.max_people) * 100).toFixed(1);
    },

    calculatedPrice() {
      if (this.detail.offer != null)
        return this.detail.price - ((this.detail.price * this.detail.offer.discount_percent) / 100).toFixed(2);
      return this.detail.price;
    },

    lang() {
      return this.$route.params.lang;
    },

    bookLink() {
      let lang = this.$route.params.lang;
      let slug = this.$route.params.slug;
      return "/" + lang + "/services/" + slug + "/book";
    },

    validate: function () {
      return this.error.counter.length === 0 && this.error.package.length === 0;
    },
    favorite() {
      return this.detail.is_favorite;
    },

    calDiscount() {
      let total = this.calTotal
      return total - this.calcPriceWithDiscount(total)
    },

    calTotal() {
      let total = 0;
      this.bookForm.counters.forEach(counter => {
        total += ((parseFloat(this.bookForm.package.price) + parseFloat(counter.price)) * counter.count);
      });
      return total;
    },

    calAfterDiscount() {
      return this.calTotal - this.calDiscount;
    },

    calTax() {
      return ((this.calAfterDiscount * this.detail.tax) / 100);
    },

    calTotalAfterTax() {
      return (this.calAfterDiscount + this.calTax)
    },

  },
  watch: {
    $route(to, from) {
      if (from !== to) {
        this.getDetail();
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    share() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(window.location.href).then(() => {
          this.$store.commit('app/successMessage', "clipboard");
        })
      }
    },

    calcPriceWithDiscount(price) {
      if (this.detail.offer != null)
        return price - ((price * this.detail.offer.discount_percent) / 100).toFixed(2);
      return price;
    },

    async getDetail() {
      await this.$store.dispatch("services/getServiceDetail", this.$route.params.slug) .catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({name:'404'})
        }

      });
      await this.$store.dispatch("auth/checkIsLoggedIn");
    },

    serviceCounter(counter) {
      if (this.bookForm.counters.some(item => item.id === counter.id))
        this.bookForm.counters.splice(this.bookForm.counters.indexOf(counter), 1)
      // else
      if (counter.count > 0)
        this.bookForm.counters.push(counter);
      this.error.counter = "";

      // this.bookForm.counters.includes(counter) ? this.bookForm.counters.splice(this.bookForm.counters.indexOf(counter), 1) : this.bookForm.counters.push(counter);
      // this.bookForm.counters.map(c => {
      //   if (c.id === counter.id) {
      //     c.count = counter.count;
      //   } else {
      //     this.bookForm.counter.push(c);
      //   }
      // })

      let peopleCount = this.bookForm.counters.reduce((acc, cur) => {
        return acc + cur.count;
      }, 0);
      if (peopleCount > this.detail.available_people) {
        this.error.counter = this.$t("rules.counter.reached_max");
      }
    },
    chooseServicePackage(e) {
      this.bookForm.package = {
        id: e.target.value,
        price: e.target.getAttribute("data-price"),
        title: e.target.getAttribute("data-title"),
      }
      this.error.package = "";
    },
    goToBookPage() {
      if (this.bookForm.package.id == 0) {
        this.error.package = this.$t("rules.package.required");
      }
      if (this.bookForm.counters.length === 0) {
        this.error.counter = this.$t("rules.counter.required");
      } else {
        let counter = this.bookForm.counters.reduce((acc, cur) => {
          return acc + cur.count;
        }, 0);
        if (counter > this.detail.available_people) {
          this.error.counter = this.$t("rules.counter.reached_max");
        }

      }

      if (!this.validate)
        return;

      this.$store.dispatch("services/bookings/setBookData",

          {
            ...this.bookForm,
            id: this.detail.id,
            slug: this.detail.slug,
            name: this.detail.title,
            startDate: this.detail.start_date,
            total: this.calTotal,
            discount: this.calDiscount,
            afterDiscount: this.calAfterDiscount,
            taxPercent: this.detail.tax,
            tax: this.calTax,
            totalAfterTax: this.calTotalAfterTax
          });
      this.$router.push(this.bookLink);
    },


    favoriteClick() {
      if (this.isLoggedIn) {
        this.$store.dispatch("user/favorites/toggleFavorite", this.detail.id).then(async () => {
          if (this.favorite) {
            this.detail.is_favorite = false;
            this.$store.commit("app/successMessage", "favorite.remove");
          } else {
            this.detail.is_favorite = true;
            this.$store.commit("app/successMessage", "favorite.add");
          }
        })
      } else {
        this.$store.commit("app/failMessage", "login.login");
      }
    },

    scrollToSection(section) {
      window.scroll({
        top: document.getElementById(section).offsetTop - 150,
        left: 0,
        behavior: 'smooth'
      });
    },


    onScroll(e) {
      if (this.isMobile) {
        let position = e.target.documentElement.scrollTop;
        if (position > 480) {
          this.scrollClass = " service-heading ";
        } else if (position < 320) {
          this.scrollClass = "h-show";
        }
      }
    },


    addPaddingTop(){
      if (this.isMobile) {
        let serviceDetail = document.getElementById('service-detail-container')
        let serviceSlider = 300;/// document.getElementById('main-swiper')
        let serviceInfo = document.getElementById('main-info');

        serviceDetail.style.paddingTop = serviceSlider + serviceInfo.offsetHeight - 120 + 'px'
      }
    }
  },
  async created() {

    this.$store.commit('app/setNotification404', false)

    await this.getDetail().then(() => {

      setTimeout(() => {
        // Now you can use Swiper
        new Swiper("#main-swiper", {
          // Install modules
          modules: [Navigation, Pagination],
          arrows: true,
          speed: 700,
          slidesPerView: 1,
          spaceBetween: 10,
          pagination: {
            el: ".swiper-pagination",
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });

      }, 3000)
     this.addPaddingTop();
    });
  },
  updated() {
    this.addPaddingTop();
  },

  async mounted() {
    window.addEventListener("scroll", this.onScroll)

    moment.locale('en');
    await new Swiper(".swiper-similar", {
      // Install modules
      arrows: true,
      speed: 700,
      slidesPerView: "auto",
      // centeredSlides: true,
      // centeredSlidesBounds: true,
      // centerInsufficientSlides: true,

    });


    // Now you can use Swiper
    new Swiper("#main-swiper", {
      // Install modules
      modules: [Navigation, Pagination],
      arrows: true,
      speed: 700,
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });

    this.addPaddingTop();
    this.toggleMobileBottomBar(true)

  },

  beforeDestroy() {

    this.$store.commit('app/setNotification404', true)

    window.removeEventListener("scroll", this.onScroll)
    this.toggleMobileBottomBar(false)
  }
}
</script>