export const state = {
    destinations: [],
    destinationsCount: 0,
    pageCount: 0,

    detail:{}
}






 
