export const state = {
    favorites: [],
    favoritesCount: 0,
    pageCount: 0,

    allFavoritesIds: [],
    isGetAllFavoritesIds: false,
}






 
