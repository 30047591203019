export const mutations = {
    setFavorites: (state, data) => {
        state.favorites = data.content
        state.pageCount = data.last_page
        state.favoritesCount = data.total
    },

    setAllFavoritesIds: (state, data) => {
        state.allFavoritesIds = data
        state.isGetAllFavoritesIds = true
    },

}