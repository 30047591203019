export const mutations = {

    setProviderDetail: (state, data) => {
        state.providerDetail = data;
        state.providerComments = data.review.comments.content;
    },

    setProviderComments: (state, data) => {
        state.providerComments = data
    },

    setProviderBasic: (state, data) => {
        state.providerBasic = data;
    }


}