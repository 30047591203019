<template>

  <div class="comments details-container">

    <div class="details--heading">
      <h3>{{ $t("common.comments.title") }}</h3>
    </div>
    <div class="details-divider"></div>

    <div class="comments-content">
      <div class="rates-container " :class="rateStatus">

        <div class="average">
          <div class="number">{{ review.rates.rate_avg }}</div>
          <rate class="RateCustom" :length="5" :value="review.rates.rate_avg" :readonly="true" iconref="icon-star"/>
          <div class="count">({{ review.rates.rate_count }}) {{ $t("common.comments.count") }}</div>
        </div>

        <div class="rates-divider"></div>
        <div class="rates-data">
          <div class="rate--item"
               v-for="(rate,index) in review.rates.rates"
               :key="index"
          >
            <div>{{ rate.rate }}</div>
            <KProgress :percent="calcRatePercent(rate.rate_count)" color="#FED029" bg-color="#EDF0F5" :line-height="4"/>
          </div>

        </div>
        <div class="rates-divider how-divider"></div>

        <div class="how">
          <div class="question">
            <div class="icon">
              <service-question-icon/>
            </div>
            {{ $t("common.comments.question") }}
          </div>
          <div class="answer">
            {{ $t("common.comments.answer") }}
          </div>
        </div>

      </div>
      <div v-if="comments.length > 0">

        <comment
            v-for="comment in comments"
            :key="comment.id"
            :comment="comment"
        />


        <paginate
            v-if="review.comments.last_page>1"
            v-model="page"
            class="mt-4"
            :page-count="review.comments.last_page"
            :page-range="4"
            :margin-pages="1"


            :prev-text="'<'"
            :next-text="'>'"

            :click-handler="paginationCallback"
            :container-class="'pagination gray'"
            :break-view-text="'...'"
            :page-class="'page-item'">
        </paginate>

      </div>
    </div>
  </div>

</template>

<script>

import Comment from "../../../../components/comment";
import paginate from "vuejs-paginate";
import KProgress from "k-progress";
import rate from "vue-rate/src/Rate"
import "vue-rate/dist/vue-rate.css"
import {mapGetters} from "vuex";


export default {
  name: "comments-section",

  props: {
    review: Object,
    serviceId: [String, Number],
    providerId: [String, Number],
    rateStatus: {
      type: String,
      default: "",
    },
  },

  components: {Comment, paginate, rate, KProgress},

  data() {
    return {
      page: 1,
    }
  },

  methods: {
    calcRatePercent(count) {
      if (count === 0) {
        return 0;
      }
      return parseInt(((count * 100) / this.review.rates.rate_count).toFixed(0));
    },

    paginationCallback(page) {
      this.page = page;

      if (this.serviceId != null)
        this.$store.dispatch("services/getServiceComments", {id: this.serviceId, page: this.page})
      else if (this.providerId != null)
        this.$store.dispatch("provider/getProviderComments", {id: this.providerId, page: this.page})

    },

  },

  computed: {
    ...mapGetters({
      serviceComments: "services/getServiceComments",
      providerComments: "provider/getProviderComments",
    }),

    comments() {
      if (this.serviceId != null) {
        return this.serviceComments
      }
      return this.providerComments
    }
  },

}
</script>