<template>

  <div class="profile-container">
    <div class="favorites">
      <div class="favorites-header h-show">
        <div class="text"> {{ $t("common.there-are") }} <span class="text-primary">{{ favoritesCount }} </span> {{
            $t("common.package")
          }}
        </div>
        <div class="delete" @click="showDeleteModal"  v-if="favorites.length>0">
          <profile-trash-icon/>
          {{ $t("btn.delete-all") }}
        </div>
      </div>
      <div class="favorites-content">

        <Nothing
            v-if="favorites.length===0"
            :title="$t('nothing.favorites.title')"
            :desc="$t('nothing.favorites.desc')"
        />

        <div class="favorites-content-grid" v-if="favorites.length>0">


          <ServiceCard v-for="favorite in favorites"
                       :key="favorite.id"
                       :isFavorite="true"
                       :on-favorite-click="favoriteClick"
                       :service="favorite"/>


        </div>

        <paginate
            v-if="pageCount>1"
            v-model="page"
            class="mt-12"
            :page-count="pageCount"
            :page-range="4"
            :margin-pages="1"

            :prev-text="'<'"
            :next-text="'>'"

            :click-handler="paginationCallback"

            :container-class="'pagination'"
            :break-view-text="'...'"
            :page-class="'page-item'">
        </paginate>


        <div class="modal-overlay" v-if="deleteModal">
          <div class="modal-content">
            <div class="icon">
              <modal-delete-icon/>
            </div>
            <div class="title">
              {{ $t("user.favorites.modal.title") }}
            </div>
            <div class="desc">
              {{ $t("user.favorites.modal.desc") }}
            </div>
            <div class="btns">
              <div class="btn btn-danger w-full" @click="deleteAllFavorites">{{ $t("btn.continue-delete") }}</div>
              <div class="btn btn-gray-3 w-full" @click="deleteModal=false">{{ $t("btn.cancel") }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginate from "vuejs-paginate";

import ServiceCard from "../../../components/ServiceCard";
import {mapGetters} from "vuex";
import Nothing from "../../../components/nothing";

export default {
  name: "Favorites",
  components: {Nothing, ServiceCard, paginate},
  data() {
    return {
      page: this.$route.query.page ?? 1,
      tab: 1,
      deleteModal: false,
    }
  },

  methods: {
    paginationCallback(page) {
      this.page = page;

      this.$router.push({query: {page: this.page}})
      this.getFavorites();
    },
    getFavorites() {
      this.$store.dispatch("user/favorites/getFavorites", {page: this.page})
    },


    favoriteClick() {
      this.getFavorites();
    },

    showDeleteModal() {
      this.deleteModal = true;
    },

    deleteAllFavorites() {
      this.$store.dispatch("user/favorites/deleteAllFavorites").then(() => {
        this.deleteModal = false;
      })
    },
  },
  computed: {
    ...mapGetters({
      favorites: "user/favorites/getFavorites",
      favoritesCount: "user/favorites/getFavoritesCount",
      pageCount: "user/favorites/getPageCount",
    }),
  },
  created() {
    this.getFavorites();
  },
}
</script>