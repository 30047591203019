export const mutations = {
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo;
    },


    setNotifications(state, data) {
        state.notifications = data.content
        state.notificationsPageCount = data.last_page
    },
}