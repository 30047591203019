<template>
  <div class="container  mobile-container">
    <RegistrationMobileNav :title="$t('confirm.title')" :desc="$t('confirm.desc')"/>
    <div class="forget-password">
      <div class="forget-password-form  ">

        <div class="login-nav hav-bg-height"></div>

        <h2 class="title h-show">{{ $t("confirm.title") }}</h2>
        <small class="desc h-show">{{ $t("confirm.desc") }}</small>
        <form class="mt-8 flex flex-col gap-4" @submit.prevent="submit()">

          <div class="input-wrapper">
            <div class="text-reg-14 text-gray-1 mb-2">{{ $t("confirm.input") }}</div>
            <PincodeInput dir="ltr"
                          v-model="code"
                          placeholder=" "
                          :length="5"
                          class="confirm-code "
                          :class="error.code.length>0?'error':''"
            />

            <span class="input--error" v-if="error.code.length>0">{{ error.code }}</span>
          </div>


          <button class="btn btn-primary  btn-white" :disabled="!enableSubmit || loading ">
            <div class="text-center" v-if="!loading">
              <div>{{ $t("btn.send") }}</div>
            </div>
            <Loading :loading="loading" :color="'#fff'" :size="'14px'" class="flex justify-center py-1"></Loading>
          </button>

        </form>


        <div class="text-reg-14 text-gray-2 text-center mt-4">
          <div>
            {{ $t("confirm.not_receive") }}

            <countdown :time="countDownTime" @end="enableResendCode">
              <template slot-scope="props">
                {{ props.minutes }}:{{ props.seconds }}
              </template>
            </countdown>
          </div>

          <div :class="`mt-2 btn-text ${resendVerifyCode?'':'disabled'}`">
            {{ $t("btn.resend") }}
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import PincodeInput from "vue-pincode-input";

import Loading from "vue-spinner/src/PulseLoader"
import {mapGetters} from "vuex";
import RegistrationMobileNav from "../components/registration-mobile-nav";

export default {
  name: "ConfirmCode",
  components: {RegistrationMobileNav, PincodeInput, Loading},
  data: () => ({
    valid: false,
    code: "",
    enableSubmit: true,
    error: {code: ""},
    rules: ["required"],


    countDownTime: 3 * 60 * 1000,
    resendVerifyCode: false,
  }),
  computed: {
    ...mapGetters({
      loading: "app/getInputLoading",
      forgetEmail: "auth/getForgetEmail",
    }),
    validate: function () {
      return this.error.code.length === 0;
    },
  },
  watch: {

    code: function (value) {
      value = value.trimStart();
      this.inputValue = value;

      if (this.rules.includes("required") && value.length === 0) {
        this.error.code = this.$t("rules.required");
        return;
      }

      this.error.code = "";

    },

  },
  methods: {
    submit() {
      if (this.code.length < 5) {
        this.error.code = this.$t("rules.required");
      } else this.error.code = ""

      if (this.validate) {
        this.$store.dispatch("auth/confirmCode", {
          code: this.code,
          email: this.forgetEmail,
        })
      }
    },


    enableResendCode() {
      //todo:: implement post request
      this.resendVerifyCode = true;
    },
  },
  mounted() {

    this.toggleMobileBottomBar(true);
    this.toggleMobileCopyright(true);
  },

  beforeDestroy() {
    this.toggleMobileBottomBar(false)
    this.toggleMobileCopyright(false)
  }

}
</script>