<template>
  <accordion class="itinerary-accordion">
    <template v-slot:header>
      <div class="flex gap-3 items-start">
        <div class="no">{{ no }}</div>
        <div class="title">
          <h4 class="text-reg-16 text-gray-1">{{ title }}</h4>
          <div class="flex items-center gap-1 text-reg-14 text-gray-3">
            <time> {{ from | moment("h:mm a") }}</time>
            <double-arrow-icon class="time-arrow  icon-reverse-ltr"/>
            <time> {{ to | moment("h:mm a") }}</time>
          </div>
        </div>
      </div>
      <div class="arrow">
        <arrow-down-icon/>
      </div>
    </template>

    <p slot="content">
      <slot/>
    </p>
  </accordion>

</template>

<script>

import Accordion from "../../../../components/Accordion";

export default {
  name: "itinerary-accordion",
  components: {Accordion},
  props: {
    no: [String, Number],
    title: String,
    from: String,
    to: String,
  },
}
</script>