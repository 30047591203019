<template>
  <div :class="' input '.concat(required ?'required':'') ">
    <label class="input--label" v-if="label">{{ label }}</label>

    <div class="input--input " :class="errorMessage.length>0?'error':''">
      <slot name="prefix-icon"/>
      <input :name="name" :type="type" :placeholder="placeholder" :required="required" :maxlength="maxLength"
             v-model="inputValue"
             :disabled="disabled"
             @input="$emit('inputValue', $event.target.value)"
             @keyup="keyUp"/>
      <slot name="suffix-icon"/>
    </div>
    <span class="input--error" v-if="errorMessage.length>0">{{ errorMessage }}</span>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    placeholder: String,
    label: String,
    name: String,
    maxLength: Number,
    rules: Array,
    keyUp: {
      type: Function,
      default: function () {
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showRealValue: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    value: [String, Number],
    confirm: String,
    error: String,
  },

  data() {
    return {
      input: this.value,
      errorMessage: this.error ?? "",
      emailPattern: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    }
  },
  computed: {

    inputValue: {
      get() {
        if (this.showRealValue) {
          return this.value
        }
        if (this.input) {
          if (this.input.length > 0)
            return this.input;
        }
        return this.value;
      },
      set(value) {
        this.input = value;
        this.$emit("inputValue", value)
      },
    },
  },
  watch: {
    error: function (value) {
      this.errorMessage = value;
    },
    errorMessage: function (value) {
      this.$emit("isReady", value.length === 0)
    },
    inputValue: function (value) {
      this.inputValue = value;

      if (this.rules != null && this.rules.length > 0) {
        if (this.rules.includes("required") && (value == null || value.length === 0)) {
          this.errorMessage = this.$t("rules.required");
          return;
        } else if (this.rules.includes("email") && !(this.emailPattern.test(value))) {
          this.errorMessage = this.$t("rules.email.valid");
          return;
        } else if (this.rules.includes("password") && value.length < 6) {
          this.errorMessage = this.$t("rules.password.valid");
          return;
        } else if (this.rules.includes("confirm") && value !== this.confirm) {
          this.errorMessage = this.$t("rules.password.match");
          return;
        }
      }

      this.errorMessage = "";
      this.inputValue = value;

    },

  },
}
</script>

