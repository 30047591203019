<template>
  <div >

    <div class="mobile-container">
      <PageHeading :title="$t('partners.title')"/>

      <div class="pt-4 details-container details-container-transparent ">

        <div>
          <h3 class="section-title-center mb-12">{{ $t('partners.how_to_register') }}</h3>
          <div class="container partners-steps-grid mb-10">
            <partner-step no="01" :translate-key="'one'"/>
            <partner-step no="02" :translate-key="'two'"/>
            <partner-step no="03" :translate-key="'three'"/>
            <partner-step no="04" :translate-key="'four'"/>
          </div>
        </div>

        <div class=" bg-primary py-8">
          <h3 class="section-title-center mb-1">
            <span class="text-white mr-2">
            {{ $t('partners.package.choose') }}
            </span>
            <span class="text-secondary">{{ $t('partners.package.right') }}</span>
          </h3>

          <div class="text-white text-reg-18 text-center mb-3">
            {{ $t('partners.package.desc') }}
          </div>

          <div class="subscription-switch">
            <div class="values">
              <div class="choose" :class="subscribeType==='monthly'?'active':''" @click="subscribeType='monthly'">
                {{ $t('subscription.monthly') }}
              </div>
              <div class="choose" :class="subscribeType==='annual'?'active':''" @click="subscribeType='annual'">
                {{ $t('subscription.annual') }}
              </div>
            </div>
          </div>
          <div class="container subscription-grid">
            <div v-for=" (subscription,index) in subscriptionData" :key="index">
              <subscription-package :data="subscription" :subscribe-type="subscribeType"/>
            </div>
          </div>
        </div>

        <about-section class="h-show"/>


        <home-slider :title="$t('home.articles')" link="articles"
                     class="home-articles home-slider home-slider-articles" v-if="articles.length>0">

          <SwiperSlide v-for="article in articles" :key="article.id">
            <ArticleCard :article="article"/>
          </SwiperSlide>

        </home-slider>

<!--        <partners-section/>-->
      </div>
    </div>

  </div>
</template>
<script>

import AboutSection from "../home/sections/about";
import HomeSlider from "../home/components/slider";
import ArticleCard from "../../components/ArticleCard";
// import PartnersSection from "../home/sections/partners";

import {SwiperSlide} from "vue-awesome-swiper"
import {mapGetters} from "vuex";
import PageHeading from "../../components/PageHeading";
import PartnerStep from "./components/step";
import SubscriptionPackage from "./components/package";

export default {
  name: "Partners",
  components: {
    SubscriptionPackage,
    PartnerStep, PageHeading,/* PartnersSection, */ArticleCard, HomeSlider, AboutSection, SwiperSlide
  },
  data() {
    return {
      subscribeType: "monthly"
    }
  },
  computed: {
    ...mapGetters({
      subscriptionData: "partners/getSubscriptions",
      articles: "partners/getArticles",
    }),
  },
  created() {
    this.$store.dispatch("partners/getPartnersContent")
  },
}
</script>
