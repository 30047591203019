<template>
  <div class="partner-step relative">
    <div class="icon">
      <partners-data-icon/>
    </div>
    <div>
      <h3 class="title">{{ $t('partners.steps.' + translateKey + '.title') }}</h3>
      <p class="description">
        {{ $t('partners.steps.' + translateKey + '.desc') }}
      </p>
    </div>
    <div class="no">{{ no }}</div>

  </div>
</template>

<script>

export default {
  name: "partner-step",
  props: {
    translateKey: String,
    no: Number,
  },


}
</script>
