<template>
  <div v-if="isLoading" class="loading-page">
    <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        class="loading-color"
        :color="'#fff'"
    >
      <template v-slot:before class="loading-logo">
        <footer-logo-icon/>
      </template>

    </loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
import {mapGetters} from "vuex"
// loading: '~/components/material/loading',
export default {
  components: {
    Loading,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters({isLoading: "app/getLoading"}),
  },
  mounted() {


  },
}
</script>
