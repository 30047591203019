<template>
  <div class="nothing">

    <div class="box " :class="small?'box-small':''">
      <nothing-icon/>
      <div class="text">
        <h2 class="title"> {{ title }}</h2>
        <p class="desc"> {{ desc }} </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "nothing",
  props: {
    title: String,
    desc: String,
    small: {
      type: Boolean,
      default: false
    }
  }

}
</script>