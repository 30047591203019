<template>
  <div id="app">
    <navbar :top-class="topClass"/>
    <router-view/>
    <AppFooter/>
    <loading/>
  </div>
</template>

<script>
import Navbar from "../../components/navbar";
import Loading from "../../components/loading";
import AppFooter from "../../components/AppFooter";

export default {
  name: "MainLayout",
  props: {
    topClass: {type: String, default: ""},
  },
  components: {
    AppFooter,
    Loading,
    Navbar,
  },
}
</script>
