export const state = {
    userInfo: {
        id: "0",
        name: "",
        email: "",
        phone: "",
        country: "",
        image: "",
    },



    notifications: [],
    notificationsPageCount: 0,
}






 
