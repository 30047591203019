<template>

  <aside class="profile-sidebar">
    <div class="user">
      <img :src="userInfo.image!=null?userInfo.image:''" @error="replaceByDefault" :alt="userInfo.name">
      <div class="flex flex-col gap-2 overflow-hidden">
        <div class="name">{{ userInfo.name }}</div>
        <div class="email">{{ userInfo.email }}</div>
      </div>
    </div>
    <ul>
      <li>
        <router-link :to="{name:'user-profile'}">
          <span class="icon">
          <profile-settings-icon/>
        </span>
          <span class="name">{{ $t('user.nav.edit') }}</span>
        </router-link>
      </li>

      <li v-if="userInfo.provider == null">
        <router-link :to="{name:'user-change-password'}">
          <span class="icon">
          <profile-lock-icon/>
        </span>
          <span class="name">{{ $t('user.nav.password') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{name:'user-interests'}">
          <span class="icon">
          <profile-interest-icon/>
        </span>
          <span class="name">{{ $t('user.nav.interests') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{name:'user-bookings'}">
          <span class="icon">
          <profile-calendar-icon/>
        </span>
          <span class="name">{{ $t('user.nav.bookings') }}</span>
        </router-link>
      </li>

      <!--      <li>-->
      <!--        <router-link to="/ar/profile/saved-payments">-->
      <!--          <span class="icon">-->
      <!--          <profile-payment-icon/>-->
      <!--        </span>-->
      <!--          <span class="name">طرق الدفع المحفوظة</span>-->
      <!--        </router-link>-->
      <!--      </li>-->

      <li>
        <router-link :to="{name:'user-favorites'}">
          <span class="icon">
          <profile-heart-icon/>
        </span>
          <span class="name">{{ $t('user.nav.favorites') }}</span>
        </router-link>
      </li>

      <li>
        <a @click="logoutModal=true" class="cursor-pointer">
          <span class="icon">
          <profile-logout-icon/>
        </span>
          <span class="name">{{ $t('user.nav.logout') }}</span>
        </a>
      </li>

    </ul>


    <div class="modal-overlay" v-if="logoutModal">
      <div class="modal-content">
        <div class="icon logout">
          <modal-logout-icon/>
        </div>
        <div class="title">
          {{ $t("user.logout.modal.title") }}
        </div>
        <div class="desc">
          {{ $t("user.logout.modal.desc") }}
        </div>
        <div class="btns">
          <div class="btn btn-danger w-full" @click="logout">{{ $t("btn.logout") }}</div>
          <div class="btn btn-gray-3 w-full" @click="logoutModal=false">{{ $t("btn.cancel") }}</div>
        </div>
      </div>
    </div>
  </aside>


</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "UserSideBar",
  data() {
    return {
      logoutModal: false,
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/getIsLoggedIn",
      userInfo: "user/getUserInfo"

    }),
  },
  methods: {

    async logout() {
      await this.$store.dispatch("auth/logout")
      this.logoutModal = false;
    }
  },
  async created() {
    await this.$store.dispatch("auth/checkIsLoggedIn")
    if (this.isLoggedIn) {
      await this.$store.dispatch('user/getUserInfo');
    }
  }
}
</script>