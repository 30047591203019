<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="detail.name" class="mb-12" :paths="headingPath"/>
      <symbol id="icon-star" viewBox="0 0 12 12">
        <path id="Path_147238" data-name="Path 147238"
              d="M13.649,7.759a1.271,1.271,0,0,0,.294-1.3A1.255,1.255,0,0,0,12.928,5.6l-2.576-.4a.084.084,0,0,1-.054-.048l-1.129-2.4a1.268,1.268,0,0,0-2.312-.018l-1.129,2.4a.1.1,0,0,1-.06.048L3.1,5.6a1.273,1.273,0,0,0-1.021.858,1.289,1.289,0,0,0,.3,1.3l1.9,1.95a.1.1,0,0,1,0,.078L3.83,12.51a1.273,1.273,0,0,0,1.88,1.326l2.24-1.242a.078.078,0,0,1,.072,0l2.24,1.242a1.279,1.279,0,0,0,1.88-1.326L11.727,9.8a.072.072,0,0,1,0-.072Z"
              transform="translate(-2.012 -2)"/>
      </symbol>

      <div class="provider-detail provider-detail-container ">

        <div class="provider-detail-heading ">
          <img class="img" :src="detail.image" :alt="detail.name">
          <div class="name">{{ detail.name }}</div>
          <div class="rate">

            <rate class="RateCustom" :length="5" :value="detail.rate" :readonly="true" iconref="icon-star"/>

          </div>
        </div>

        <div class=" details-container mb-5">
          <div class="details--heading">
            <h3>{{$t('provider.about')}}</h3>
          </div>
          <div class="details-divider"></div>

          <div class="px-4 text-reg-14 text-gray-2">
            {{ detail.about }}
          </div>
        </div>


        <div class=" details-container mb-5" v-if="detail.contacts.length>0">
          <div class="details--heading">
            <h3>{{$t('provider.contact')}}</h3>
          </div>
          <div class="details-divider"></div>

          <div class="px-4 flex items-center gap-3">
            <a :href="contact.platform.base_url+contact.username" v-for="(contact,index) in detail.contacts"
               :key="index">
              <img :src="contact.platform.icon" :alt="contact.platform.name">
            </a>
          </div>
        </div>

        <div class=" details-container mb-5" v-if="detail.interests.length>0">
          <div class="details--heading">
            <h3>{{$t('provider.interests')}}</h3>
          </div>
          <div class="details-divider"></div>

          <div class="px-4 flex flex-wrap items-center gap-3">
            <div v-for="interest in detail.interests" :key="interest.id" class="interests-tag">
              <img :src="interest.image" :alt="interest.title" class="icon">
              <h3>{{ interest.title }}</h3>
            </div>

          </div>
        </div>
        <comments-section
            :review="detail.review"
            :provider-id="detail.id"
        />

      </div>

    </div>

    <div class="similar mt-1">
      <div class="container text-semi-20 text-gray-1 my-4">{{$t('provider.services')}}</div>
      <!-- Slider main container -->
      <div class="swiper-similar pb-8 service-swiper ">


        <!-- Additional required wrapper -->
        <div class="swiper-wrapper" id="similar-swiper-wrapper">
          <!-- Slides -->
          <div class="swiper-slide" v-for="service in detail.services" :key="service.id">

            <ServiceCard cssClass="small max-w" :service="service"/>

          </div>

        </div>

      </div>


    </div>
  </div>
</template>

<style>

.swiper-similar .swiper-slide {
  width: 296px;
  display: inline-block;
  box-sizing: border-box;

}

.swiper-similar {
  width: 80%;
  max-width: 1152px !important;
  margin-left: auto;
  margin-right: auto;
  overflow: visible;
  /*justify-content: center;*/
  /*transform: translate3d(-175px, 0px, 0px) !important;*/
}
</style>
<script>
import PageHeading from "../../components/PageHeading";

import "swiper/swiper.scss"
import "swiper/modules/pagination/pagination.scss"
import "swiper/modules/navigation/navigation.scss"

import Swiper from "swiper";


import rate from "vue-rate/src/Rate"
import "vue-rate/dist/vue-rate.css"


import ServiceCard from "../../components/ServiceCard";
import {mapGetters} from "vuex";
import CommentsSection from "@/views/services/detail/detailSections/comments";

export default {
  name: "ServiceDetail",

  components: {
    CommentsSection,
    ServiceCard,
    PageHeading, rate,
  },
  computed: {
    ...mapGetters({
      detail: "provider/getProviderDetail",
    }),

    headingPath() {
      let lang = this.$route.params.lang;
      if (this.$route.params.serviceSlug != null) {
        let offersLink = "/" + lang + "/offers";
        let serviceLink = "/" + lang + "/services/" + this.detail.service_path.slug;

        return [
          {title: this.$t('nav.our-offers'), link: offersLink},
          {title: this.detail.service_path.title, link: serviceLink},
        ]
      }
      return []
    },


  },

  async created() {

    this.$store.commit('app/setNotification404', false)

    await this.$store.dispatch('provider/getProviderDetail', {
      slug: this.$route.params.providerSlug,
      service: this.$route.params.serviceSlug
    }).catch((error) => {
      if (error.response.status === 404) {
        this.$router.push({name:'404'})
      }

    });
  },

  mounted() {

    new Swiper(".swiper-similar", {
      // Install modules
      arrows: true,
      speed: 700,
      slidesPerView: "auto",
      // centeredSlides: true,
      // centeredSlidesBounds: true,
      // centerInsufficientSlides: true,

    });


  },

  beforeDestroy() {

    this.$store.commit('app/setNotification404', true)

  }
}
</script>