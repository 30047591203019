<template>
  <div class="container mobile-container">
    <PageHeading :title="$t('others.chat')"/>
    <div class="chat-container">

      <div class="chats pt-4">

        <router-link :to="`./chat/${getId(chat)}`" class="chat-card " v-for="(chat,index) in rooms" :key="index"
                     :class="getId(chat)"
        >

          <img :src="chat.avatar" alt="">

          <div class="content">
            <div class="title">
              <p>
                {{ chat.roomName }}
              </p>
              <div class="time">
                <profile-clock-icon/>
                {{ chat.lastMessage.timestamp | moment("from", "now", true) }}
              </div>
            </div>
            <div class="desc">
              {{ chat.lastMessage.content }}
            </div>

          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";
import {collection, getDocs, getFirestore, onSnapshot, query, where} from "firebase/firestore";

import moment from 'moment';

export default {
  name: "ChatList",
  components: {PageHeading},
  data() {
    return {
      rooms: [],
      loading: true,
      loadingText: "",
    }
  },
  methods: {

    getId(chat) {
      const array = chat.roomId.split('-');
      if (array.length == 1)
        return 'support';
      return array.filter(id => id !== this.getProfileInfo.id.toString())[0];
    },
    compareId(id) {
      return id.split('-')[0] === this.getProfileInfo.id.toString();
    },


    unreadMessage(data) {
      const isAdmin = this.compareId(data.id);
      if (isAdmin) {
        return data.unreadMessagesMemberA;
      }
      return data.unreadMessagesMemberB;
    },

    member(data) {
      const isAdmin = this.compareId(data.id);
      if (isAdmin) {
        return data.memberB;
      }
      return data.memberA;
    },

    // start
    async getChat() {
      moment.locale('en');
      const rooms = [];
      const support = {
        roomId: this.getProfileInfo.id.toString(),
        roomName: this.$i18n.t('common.technical_support'),
        index: 9999999999999,

        lastMessage: '',
        avatar:
            'https://halabkstorage.s3.eu-central-1.amazonaws.com/support.svg',
        lastMessageDate: moment().format(),
        users: [
          {
            _id: this.getProfileInfo.id,
            username: this.getProfileInfo.name,
          },
        ],
      };
      const db = getFirestore();
      this.$store.commit("app/setLoading", true, {root: true})

      const q = query(
          collection(db, 'convrsations'),
          where('members', 'array-contains', this.getProfileInfo.id.toString()),
      );

      await getDocs(q).then(querySnapshot => {
        querySnapshot.forEach(doc => {
          if (doc.data().id === this.getProfileInfo.id.toString()) {
            rooms.push({
              roomId: doc.data().id,
              roomName: this.$i18n.t('common.technical_support'),
              index: 9999999999999,
              avatar:
                  'https://halabkstorage.s3.eu-central-1.amazonaws.com/support.svg',
              unreadCount: doc.data().unreadMessagesMemberA,
              users: [
                {
                  _id: this.getProfileInfo.id,
                  username: this.getProfileInfo.name,
                },
              ],
              lastMessage: {
                content: doc.data().lastMessage,
                senderId: parseInt(doc.data().memberA.id),
                username: doc.data().memberA.name,
                timestamp: new Intl.DateTimeFormat('default', {
                  hour12: true,
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(doc.data().lastMessageDate)),
              },
            });
          } else {
            rooms.push({
              roomId: doc.data().id,
              roomName: this.member(doc.data()).name,
              index: new Date(doc.data().lastMessageDate),
              avatar: this.member(doc.data()).photo,
              unreadCount: this.unreadMessage(doc.data()),
              users: [
                {
                  _id: parseInt(this.member(doc.data()).id),
                  username: this.member(doc.data()).name,
                  avatar: this.member(doc.data()).photo,
                },
                {
                  _id: this.getProfileInfo.id,
                  username: this.getProfileInfo.name,
                },
              ],
              lastMessage: {
                content: doc.data().lastMessage,
                senderId: parseInt(this.member(doc.data()).id),
                username: this.member(doc.data()).name,
                timestamp: new Intl.DateTimeFormat('default', {
                  hour12: true,
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(doc.data().lastMessageDate)),
              },
            });
          }
        });
        const isAdminExist = rooms.some(
            room => room.roomId === this.getProfileInfo.id.toString(),
        );
        if (isAdminExist) {
          this.rooms = rooms;
        } else {
          this.rooms = [...rooms, support];
        }
        this.getAllRooms();
      });
      this.$store.commit("app/setLoading", false, {root: true})
    },

    async getAllRooms() {
      const db = await getFirestore();
      const convrsations = query(
          collection(db, 'convrsations'),
          where('members', 'array-contains', this.getProfileInfo.id.toString()),
      );

      const support = {
        roomId: this.getProfileInfo.id.toString(),
        roomName: 'الدعم الفني',
        index: 9999999999999,

        lastMessage: '',
        avatar:
            'https://halabkstorage.s3.eu-central-1.amazonaws.com/support.svg',
        lastMessageDate: moment().format(),
        users: [
          {
            _id: this.getProfileInfo.id,
            username: this.getProfileInfo.name,
          },
        ],
      };

      onSnapshot(convrsations, querySnapshot => {
        const rooms = [];
        querySnapshot.forEach(doc => {
          if (doc.data().id === this.getProfileInfo.id.toString()) {
            rooms.push({
              roomId: doc.data().id,
              roomName: 'الدعم الفني',
              index: 9999999999999,
              avatar:
                  'https://halabkstorage.s3.eu-central-1.amazonaws.com/support.svg',
              unreadCount: doc.data().unreadMessagesMemberA,
              users: [
                {
                  _id: this.getProfileInfo.id,
                  username: this.getProfileInfo.name,
                },
              ],
              lastMessage: {
                content: doc.data().lastMessage,
                senderId: parseInt(doc.data().memberA.id),
                username: doc.data().memberA.name,
                timestamp: new Intl.DateTimeFormat('default', {
                  hour12: true,
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(doc.data().lastMessageDate)),
              },
            });
          } else {
            rooms.push({
              roomId: doc.data().id,
              roomName: this.member(doc.data()).name,
              index: new Date(doc.data().lastMessageDate),
              avatar: this.member(doc.data()).photo,
              unreadCount: this.unreadMessage(doc.data()),
              users: [
                {
                  _id: parseInt(this.member(doc.data()).id),
                  username: this.member(doc.data()).name,
                  avatar: this.member(doc.data()).photo,
                },
                {
                  _id: this.getProfileInfo.id,
                  username: this.getProfileInfo.name,
                },
              ],
              lastMessage: {
                content: doc.data().lastMessage,
                senderId: parseInt(this.member(doc.data()).id),
                username: this.member(doc.data()).name,
                timestamp: new Intl.DateTimeFormat('default', {
                  hour12: true,
                  hour: 'numeric',
                  minute: 'numeric',
                }).format(new Date(doc.data().lastMessageDate)),
              },
            });
          }
        });
        const isAdminExist = rooms.some(
            room => room.roomId === this.getProfileInfo.id.toString(),
        );
        if (isAdminExist) {
          this.rooms = rooms;
        } else {
          this.rooms = [...rooms, support];
        }
      });
    },

  },
  computed: {
    ...mapGetters({
      getProfileInfo: 'user/getUserInfo',
    }),
  },
  async created() {

    await this.$store.dispatch('user/getUserInfo');
    await this.getChat();
  },
}
</script>