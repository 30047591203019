<template>
  <accordion class="faq-accordion">
    <template v-slot:header>
      <div class="flex gap-3 items-start">
        <h4 class="faq-accordion-header">{{ title }}</h4>
      </div>
      <div class="flex-none mr-2">
        <div class="arrow pb-0.5">
          <arrow-down-icon/>
        </div>
      </div>
    </template>

    <p slot="content" v-html="$slots.default[0].text">

    </p>
  </accordion>

</template>

<script>

import Accordion from "./Accordion";

export default {
  name: "faq-accordion",
  components: {Accordion},
  props: {
    title: String,
  },

}
</script>