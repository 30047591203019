export const getters = {

    getService: state => state.service,
    getCompleteFirstStep: state => state.completeFirstStep,

    getBookInfo: state => state.bookInfo,
    getPaymentInfo: state => state.paymentInfo,
    getCoupon: state => state.coupon,

    getBookModal: state => state.bookModal
}