<template>
  <div class="container mobile-container">

    <RegistrationMobileNav :title="$t('login.title')" :desc="$t('login.desc')" :show-logo="true"/>


    <div class="login ">
      <div class="login-form  ">

        <div class="login-nav hav-bg-height"></div>
        <h2 class="title h-show">{{ $t("login.title") }}</h2>
        <small class="desc h-show">{{ $t("login.desc") }}</small>

        <form class="my-8 flex flex-col gap-4" @submit.prevent="submit()">

          <TextInput
              :label='$t("input.email.title")'
              :placeholder='$t("input.email.placeholder")'
              :rules="['required','email']"
              :error="error.email"
              @inputValue="(value)=>this.email=value"
              @isReady="(value) => this.valid=value"
          >
            <template v-slot:prefix-icon>
              <envelope-fontawesome/>
            </template>
          </TextInput>

          <TextInput
              :label='$t("input.password.title")'
              :placeholder='$t("input.password.placeholder")'
              :type="showPassword ? 'text' : 'password'"
              :rules="['required','password']"
              :error="error.password"
              @inputValue="(value)=>this.password=value"
              @isReady="(value) => this.valid=value"
          >
            <template v-slot:prefix-icon>
              <profile-lock-icon/>
            </template>
            <template v-slot:suffix-icon>
              <eye-fontawesome @click="showPassword=!showPassword"/>
            </template>
          </TextInput>

          <div class="text-left">
            <router-link :to="`/${this.$route.params.lang}/forget-password`" class="login-forget-password">
              {{ $t("login.forget-password") }}
            </router-link>
          </div>

          <button class="btn btn-primary  btn-white" :disabled="!enableSubmit || loading ">
            <div class="btn-flex" v-if="!loading">
              <div> {{ $t("login.title") }}</div>
              <circle-arrow-icon class="icon-reverse-ltr"/>
            </div>
            <Loading :loading="loading" :color="'#fff'" :size="'14px'" class="flex justify-center py-1"></Loading>
          </button>

          <div class="login-or">
            {{ $t("login.or") }}
          </div>

          <div class="btn btn-gray-3 btn-flex" @click="googleSignIn">
            <div>{{ $t("login.with-google") }}</div>
            <google-color-icon/>
          </div>
          <!--          <div class="btn btn-black btn-flex" @click="appleSignIn">-->
          <!--            <div>{{ $t("login.with-apple") }}</div>-->
          <!--            <apple-icon />-->
          <!--          </div>-->
        </form>

        <div class="text-reg-14 text-gray-2 flex items-center gap-1 justify-center">
          {{ $t("login.dont-have-account") }}
          <router-link :to="`/${this.$route.params.lang}/signUp`" class="text-primary" href="/">
            {{ $t("login.click-here") }}
          </router-link>
        </div>
      </div>
      <div class="left-line"></div>
      <div class="login-svg">
        <login-svg/>
      </div>
    </div>

  </div>
</template>

<script>
import TextInput from "../../../components/inputs/text";
import {mapGetters} from "vuex";
import Loading from "vue-spinner/src/PulseLoader"
import {getAuth, GoogleAuthProvider, signInWithPopup,} from "firebase/auth";
import RegistrationMobileNav from "../components/registration-mobile-nav";

export default {
  name: "Login",
  components: {RegistrationMobileNav, TextInput, Loading},
  data: () => ({
    valid: false,
    password: "",
    email: "",
    showPassword: false,
    enableSubmit: true,
    error: {email: "", password: ""},
  }),
  computed: {
    ...mapGetters({
      loading: "app/getInputLoading",
      isMobile: "app/getIsMobile"
    }),
    validate: function () {
      return this.error.email.length === 0 && this.error.password.length === 0 && this.valid;
    },
  },
  methods: {

    goBack() {
      this.$router.go(-1);
    },

    submit() {
      if (this.email.length === 0) {
        this.error.email = this.$t("rules.required");
      } else this.error.email = ""

      if (this.password.length === 0) {
        this.error.password = this.$t("rules.required");
        return;
      } else this.error.password = "";

      if (this.validate) {
        this.$store.dispatch("auth/login", {
          email: this.email,
          password: this.password,
        }).catch((error) => {
          if (error.response.status === 401) {
            this.$store.commit("app/failMessage", 'unauth');
          }

        });
      }
    },


    async googleSignIn() {
      // Sign in using a popup.
      const provider = new GoogleAuthProvider();
      provider.addScope("profile");
      provider.addScope("email");

      await signInWithPopup(getAuth(), provider).then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;


        this.$store.dispatch("auth/loginWithGoogle", {token: token})
            .catch(() => {
              this.$store.commit("app/failMessage", "login.google");

            })
      }.bind(this));
    },


    //
    // async appleSignIn() {
    //   // Sign in using a popup.
    //   const provider = new OAuthProvider('apple.com');
    //   provider.addScope("name");
    //   provider.addScope("email");
    //
    //   await signInWithPopup(getAuth(), provider).then(function (result) {
    //
    //     // Apple credential
    //     const credential = OAuthProvider.credentialFromResult(result);
    //     // const accessToken = credential.accessToken;
    //     const idToken = credential.idToken;
    //
    //     this.$store.dispatch("auth/loginWithApple", {token: idToken})
    //         .catch((e) => {
    //           console.log(e)
    //           this.$store.commit("app/failMessage", "login.apple");
    //
    //         })
    //   }.bind(this));
    // },


  },
  created() {
    this.$store.dispatch("auth/resetSignUp");

  },

  mounted() {
    if (this.isMobile) {
      document.getElementsByTagName("body")[0].style.background = "#fff";
    }
    this.toggleMobileBottomBar(true);
    this.toggleMobileCopyright(true);
    setTimeout(() => {

      this.toggleMobileBottomBar(true);
      this.toggleMobileCopyright(true);
    }, 10);
  },

  beforeDestroy() {
    if (this.isMobile) {
      document.getElementsByTagName("body")[0].style.background = "rgba(250, 251, 252, var(--tw-bg-opacity))";
    }
    this.toggleMobileBottomBar(false)
    this.toggleMobileCopyright(false)
  }

}
</script>