<template>
  <div class="dropdown" v-click-outside="hideMenu">

    <div class="flex items-center gap-2" @click="showMenu" >
      <slot/>
      <div class="max-1-lines">{{ text }}</div>
      <div>
        <arrow-icon/>
      </div>
    </div>

    <div class="dropdown-menu" ref="dropdown-menu" style="height: 0" @click="hideMenu">
      <slot name="dropdown-menu"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props: {
    text: String,
  },
  methods: {
    showMenu() {
      this.$refs["dropdown-menu"].style.getPropertyValue("height") === "0px" ? this.$refs["dropdown-menu"].style.height = this.$refs["dropdown-menu"].scrollHeight + "px" : this.$refs["dropdown-menu"].style.height = "0px";
      // this.$refs["dropdown-menu"].style.height = this.$refs["dropdown-menu"].scrollHeight + "px";
    },
    hideMenu() {
      this.$refs["dropdown-menu"].style.height = "0px";
    },
  },
  mounted() {
    this.$refs["dropdown-menu"].offsetHeight;
  }
}
</script>

