<template>

  <div class="container my-24 mb-32 section-discover">
    <img class="discover--img" src="@/assets/images/discover-bg.webp" alt="">
    <div class="overlay">

      <div class="section-discover-text">
        <h3 class="discover--title">{{ $t('home.discover.title') }}</h3>
        <p class="discover--desc">{{ $t("home.discover.desc") }}</p>
        <a href="#" class="discover--btn">{{$t("home.discover.btn")}}</a>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "discover-section",
}
</script>
