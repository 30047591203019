<template>
  <div class="page-head-height " :class="'is-nav'">
    <div :class="`desktop-page-head page-head  ${scrollClass} ${hide}`">
      <h1 class="heading max-1-lines" :title="title">{{ title }}</h1>

      <div class="path max-1-lines">

        <router-link :to="{name:'home'}" class="flex items-center gap-1">
          <home-icon class="fill-white"/>
          {{ $t("link.home") }}
        </router-link>
        <template v-for="path in paths">
          <double-arrow-icon :key="path.title" class="fill-white " :class="language==='en'?'icon-reverse-2':''"/>
          <router-link :key="path.link" :to="path.link" class="hover:text-secondary   max-1-lines">{{
              path.title
            }}
          </router-link>
        </template>
        <double-arrow-icon class="fill-white " :class="language==='en'?'icon-reverse-2':''"/>
        <div class="path-current  max-1-lines">{{ title }}</div>
      </div>
    </div>


    <!-- *******************************
       **  mobile navigation bar
       **  mobile nav bar
       **  mobile navbar
    ******************************-->

    <div class="mobile-nav-bar show">
      <div class="nav-bg">
        <img class="w-full nav-pattern" src="@/assets/patterns/pattern_1.png" alt="halabk pattern">
        <div class="flex items-center justify-between gap-4 relative px-4 w-full nav-content">

          <div class="flex gap-3" v-if="showText">
            <div class=" nav-box  h-hide icon-reverse-ltr" @click="goBack" v-if="showBack">
              <mobile-back-icon/>
            </div>
            <h3 class="mobile-nav-title">{{ mobileNavTitle }}</h3>
          </div>

          <slot name="text"/>

          <div class="flex gap-3" v-if="showIcons">
            <div class=" nav-box show-in-filter-page h-hide" @click="showMobileSearch" id="mobile-search-btn"
                 style="display: none !important;">
              <search2-icon/>
            </div>
            <div class=" nav-box show-in-filter-page h-hide" @click="showMobileFilter" id="mobile-filter-btn"
                 style="display: none !important;">
              <filter-icon/>
            </div>
            <router-link :to="`/${lang}/notifications`" class=" nav-box  hide-in-filter-page">
              <notification-icon/>
            </router-link>
            <router-link :to="`/${lang}/chat`" class=" nav-box fill-white  hide-in-filter-page ">
              <chat-icon/>
            </router-link>
          </div>
          <slot name="icons"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "PageHeading",
  props: {
    title: String,
    navTitle: {
      type: String,
      default: null
    },
    paths: Array,
    showBack: {
      default: true,
      type: Boolean
    },
    showIcons: {
      default: true,
      type: Boolean
    },
    showText: {
      default: true,
      type: Boolean
    },
  },
  data() {
    return {
      scrollClass: "",
      hide: "",
    }
  },
  computed: {
    ...mapGetters({
      language: "app/getLanguage",
      isMobile: "app/getIsMobile"
    }),
    mobileNavTitle() {
      if (this.navTitle != null && this.navTitle.length > 0)
        return this.navTitle;
      return this.title;
    },
    lang() {
      return this.$route.params.lang;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    showMobileSearch() {
      document.getElementById('filter_search_container').classList.add('show-mobile')
    },
    showMobileFilter() {
      document.getElementById('filter_container').classList.add('show-mobile')
    },

    onScroll(e) {
      if (this.isMobile === false) {
        let position = e.target.documentElement.scrollTop;
        if (position > 480) {
          this.scrollClass = " page-head-fixed";
          this.hide = "";
        } else if (position > 320 && position < 480) {
          this.hide = " hide";
        } else if (position < 320) {
          this.scrollClass = "";
          this.hide = "";
        }
      }
    },
  },
}
</script>
