// import Vue from 'vue'

// // Lib imports
// import axios from 'axios'

// Vue.prototype.$http = axios
// s
import {store} from "../store/index"
import Vue from "vue"
import axios from "axios";
// import router from "../routes";


Vue.prototype.$http = axios

var href = window.location.href;
if (href.indexOf('/en/') > 0) {
    localStorage.setItem('locale', 'en')
} else {
    localStorage.setItem('locale', 'ar')
}

Vue.prototype.$http.defaults.baseURL = "https://backend.halabk.sa/api/" + localStorage.getItem('locale')
// Vue.prototype.$http.defaults.baseURL = "http://hlabk.test/api/" + localStorage.getItem('locale')
// Vue.prototype.$http.defaults.baseURL = "https://halabk.wameedprojects.com/api/" + localStorage.getItem('locale')
Vue.prototype.$http.defaults.headers.common["currency"] = localStorage.getItem('currency')
Vue.prototype.$http.defaults.headers.post["Content-Type"] = "application/json;  charset=UTF-8"

Vue.prototype.$http.interceptors.request.use((request) => {

    // Update token axios header
    var authToken = request.headers.common["Authorization"]

    if (!authToken) {
        let loginData = Vue.$cookies.get("login-data", {parseJSON: true});
        if (loginData) {
            request.headers.common["Authorization"] = "Bearer " + loginData.token.access_token;
        }
    }

    return request;
});


Vue.prototype.$http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.log(error.response.status)
        if (error.response.status === 400) {
            store.commit("app/failMessage", error.response.data.code ? error.response.data.code : "error")

        }

        if (error.response.status === 401) {

            store.dispatch("logout");
            store.commit("failMessage", error.response.status)


        }
        if (error.response.status === 422
            || error.response.status === 403
            || error.response.status === 500) {


            store.commit("app/failMessage", error.response.status)

        }

        if (error.response.status === 404 && store.getters['app/getNotification404']) {

            store.commit("app/failMessage", error.response.status)

        }

        return Promise.reject(error);
    },
);

