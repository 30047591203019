export const actions = {

    async getUserInfo({commit}) {

        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get("user/profile")
            .then((response) => {
                if (response.status === 200) {

                    commit("setUserInfo", response.data.data)
                    commit("app/setLoading", false, {root: true})
                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})

                return Promise.reject(error);
            })


    },

    async updateProfile({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("user/profile/update-profile", data)
            .then((response) => {
                if (response.status === 200) {

                    commit("app/successMessage", "update.profile", {root: true});
                    commit("setUserInfo", response.data.data)

                    let loginData = this._vm.$cookies.get("login-data", {parseJSON: true})
                    let token = {...loginData.token}
                    let responseData = {
                        id: loginData.id,
                        name: response.data.data.name,
                        email: response.data.data.email,
                        image: response.data.data.image,
                        token: token,
                        complete_status: "done",
                        exp_date: loginData.expires_in,
                    }


                    this._vm.$cookies.set("login-data", JSON.stringify(responseData), null, "/",


                        // httpOnly:true,
                        // sameSite: true
                    )

                    commit("app/setInputLoading", false, {root: true})
                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})

                return Promise.reject(error);
            })
    },

    async deleteAccount({commit}) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.post("delete-account")
            .then((respo) => {
                if (respo.status === 200) {


                    this._vm.$cookies.remove("login-data")
                    window.location.href = "/";
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },


    async changePassword({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("user/profile/change-password", data)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("app/successMessage", "update.password", {root: true});
                    commit("app/setInputLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                throw error
            })
    },

    async getUserNotifications({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`user/notifications?page=${data.page}`, data)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setNotifications", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

}