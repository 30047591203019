<template>
  <footer class="footer">
    <div class="container  footer-grid">
      <div class="footer-info">
        <footer-logo-icon/>
        <p class="desc">
          {{ footer.content }}
        </p>
        <div class="footer-socials">
          <a target="_blank" :href="social.platform.base_url+social.username"
             v-for="(social ,index) in footer.social" :key="index">
            <img :src="social.platform.icon" :alt="social.platform.name"/>
          </a>

        </div>
      </div>
      <div class="footer-grid-2">
        <div class="footer-list">
          <h3 class="heading">{{ $t('footer.heading.important-links') }}</h3>
          <ul class="list">
            <li>
              <router-link  :to="{name:'home'}">{{ $t('footer.links.home') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'about-us'}">{{ $t('footer.links.about') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'offers'}">{{ $t('footer.links.special_offers') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'services'}">{{ $t('footer.links.latest_offers') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'articles'}">{{ $t('footer.links.blog') }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-list" v-if="isLoggedIn===true">
          <h3 class="heading">{{ $t('footer.heading.account') }}</h3>
          <ul class="list">
            <li>
              <router-link :to="{name:'user-bookings'}">{{ $t('footer.links.bookings') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'user-favorites'}">{{ $t('footer.links.favorites') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'notifications'}">{{ $t('footer.links.notifications') }}</router-link>
            </li>
            <li>
              <router-link :to="{name:'chat'}">{{ $t('footer.links.chat') }}</router-link>
            </li>
          </ul>
        </div>
        <div class="footer-list" v-if="isLoggedIn===false">
          <h3 class="heading">{{ $t('footer.heading.partners') }}</h3>
          <ul class="list">

            <li>
              <a  target="_blank" href="https://www.provider.halabk.sa/">{{ $t('footer.links.login') }}</a>
            </li>
            <li>
              <a target="_blank" :href="`https://www.provider.halabk.sa/${lang}/signUp`">{{ $t('footer.links.signUp') }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer-list">
        <h3 class="heading">{{ $t('footer.heading.customers_service') }}</h3>
        <ul class="list">
          <li>
            <router-link :to="{name:'contact-us'}">{{ $t('footer.links.contact') }}</router-link>
          </li>
          <li>
            <router-link :to="{name:'faqs'}">{{ $t('footer.links.faq') }}</router-link>
          </li>
          <li>
            <router-link :to="{name:'terms'}">{{ $t('footer.links.terms') }}</router-link>
          </li>
          <li>
            <router-link :to="{name:'privacy-policy'}">{{ $t('footer.links.privacy') }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container footer-divider"></div>
    <div class="footer-copyright">
      <p>{{ $t('footer.copyrights') }} </p>
      <p class="text-secondary">{{ $t('footer.platform') }}</p>
    </div>
  </footer>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "app-footer",
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/getIsLoggedIn",
      footer: "others/getFooter"
    }),
    lang() {
      return this.$route.params.lang
    },
  },
  created() {
    this.$store.dispatch("others/getFooter")
  },
}
</script>
