<template>
  <div class="subscription-card">
    <span v-if="data.recommended === 1" class="best-card">
          {{ $t('subscription.the_best') }}
    </span>

    <p class="text-semi-20 ">
      {{ data.name }}
    </p>

    <div class="mb-2">
          <span class="text-semi-30 text-primary">
            {{ PriceCalc(price) }}
          </span>
      <sub class="text-gray-3 text-reg-18">
        / {{ paymentType }}
      </sub>

    </div>

    <p class=" description">
      {{ data.desc }}
    </p>
    <div class="subscription-list">
      <div
          v-for="(feature, index) in data.feature_details"
          :key="feature + index"
          class=" subscription-list--item"
          :class="hasFeature(feature)"
      >
        {{ feature.feature.title }}
      </div>
    </div>

    <a :href="`https://www.provider.halabk.sa/${lang}/signUp`" class="block subscription-btn mt-6 " :class="data.recommended === 1?'primary':''">
      {{ $t('subscription.choose_package') }}
    </a>
<!--    <a href="" class="details">{{ $t('link.show-details') }}</a>-->

  </div>
</template>

<script>

export default {
  name: "subscription-package",
  props: {
    data: {},
    subscribeType: {
      type: String,
      default: "monthly"
    }
  },

  computed: {
    lang() {
      return this.$route.params.lang
    },
    price() {
      if (this.subscribeType === 'monthly') {
        return this.data.month_price;
      }
      return this.data.year_price;
    },

    paymentType() {
      if (this.subscribeType === 'monthly') {
        return this.$t('subscription.monthly');
      }
      return this.$t('subscription.annual');
    },

  },
  methods: {

    hasFeature(feature) {
      if (feature.feature.type === 2 && feature.value === 1) {
        return 'contain';
      } else if (feature.feature.type === 2 && feature.value === 0) {
        return 'contain not';
      }
      return 'contain';
    }

  }


}
</script>
