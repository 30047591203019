export const state = {
    providerBasic: {
        id: 0,
        name: "",
        slug: "",
        image: "",
        rate: 0,
        active: false,
    },

    providerDetail: {
        id: 0,
        name: '',
        image: '',
        service_path: {
            title: '',
            slug: ''
        },
        contacts: [],
        interests: [],

        review: {
            comments: {
                content: [
                    {
                        id: 0,
                        image: "",
                        name: "",
                        rate: 0,
                        comment: "",
                        created_at: "",
                        review_images: "",
                    },

                ],
            }
            ,
            rates: {
                rates: [
                    {
                        rate_count: 0,
                        rate: 4,
                    },

                ],
                rate_count: 0,
                rate_avg: 0,
            },
        }
    },


    providerComments: [
        {
            id: 0,
            image: "",
            name: "",
            rate: 0,
            comment: "",
            created_at: "",
            review_images: [],
        },
    ],

}






 
