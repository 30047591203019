export const mutations = {

    setLatestServices: (state, data) => {
        state.latestServices = data.content
        state.latestPageCount = data.last_page
    },

    setServiceDetail: (state, data) => {
        state.serviceDetail = data;
        state.serviceComments = data.review.comments.content;
    },

    setServiceComments: (state, data) => {
        state.serviceComments = data
    },

    setServiceSuggestions: (state, data) => {
        state.serviceSuggestions = data
    },

    setServiceFilters: (state, data) => {
        state.serviceFilters = data
    },

    setFilteredServices: (state, data) => {
        state.filteredServices = data.content
        state.totalFilteredServices = data.total
        state.filteredPageCount = data.last_page
    },

}