import Vue from "vue"
import App from "./App.vue"
import i18n from "./i18n"
import router from "./routes"
import {store} from "./store/index"

import "./lib"

import "./assets/style/index.scss"
import "./assets/icon";
import "./assets/pattern"
import "./assets/svg"
import "./assets/fontawesome"
import VueCardFormat from "vue-credit-card-validation/src";


Vue.config.productionTip = false


Vue.use(VueCardFormat);


Vue.directive("click-outside", {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent)
    },
});

// Vue.directive('clipscroll', {
//     inserted: function (el, binding) {
//         let f = function (evt) {
//             var hasRun = false
//             if (!hasRun && window.scrollY > binding.value.start) {
//                 hasRun = true
//                 // TweenMax.to(el, 2, {
//                 //     morphSVG: binding.value.toPath,
//                 //     ease: Sine.easeIn
//                 // })
//             }
//             if (window.scrollY > binding.value.end) {
//                 window.removeEventListener('scroll', f)
//             }
//         }
//         window.addEventListener('scroll', f)
//     }
// })

// Vue.directive('scroll', {
//     inserted: function (el, binding) {
//         let f = function (evt) {
//             if (binding.value(evt, el)) {
//                 window.removeEventListener('scroll', f)
//             }
//         }
//         window.addEventListener('scroll', f)
//     }
// })
Vue.mixin({
    methods: {
        PriceCalc: (price) => {
            price = parseFloat(price) ?? 0;
            return (price).toFixed(2) + " " + localStorage.getItem("currency_symbol");
        },

        replaceByDefault(e) {
            e.target.src = 'https://halabkstorage.s3.eu-central-1.amazonaws.com/default.png';
        },
        toggleMobileBottomBar(hide = false) {
            let isMobile = store.getters['app/getIsMobile'];
            if (isMobile) {
                let mobileBottomBar = document.querySelector('.navbar-bottom');
                if (mobileBottomBar) {
                    mobileBottomBar.style.display = hide ? "none" : "grid";
                }
            }
        },
        toggleMobileCopyright(hide = false) {
            let isMobile = store.getters['app/getIsMobile'];
            if (isMobile) {
                let text = document.getElementById('layout_2_copywrites');
                if (text) {
                    text.style.display = hide ? "none" : "flex";
                }
            }
        },
        toggleMobileFooter(hide = false) {
            let isMobile = store.getters['app/getIsMobile'];
            if (isMobile) {
                let footer = document.querySelector('footer.footer');
                if (footer) {
                    footer.style.display = hide ? "none" : "flex";
                }
            }
        }
    },
})
window.addEventListener('message', (event) => {
    // console.log(`Received message: ${event}`);
    // console.log(JSON.stringify(event));
    // console.log(`Received message: ${event.data}`);
    // console.log(`Received message: ${event.data.type}`);
    if (event.data.type === 'payment_success') {
        store.dispatch('services/bookings/bookModal', {
            show: true,
            id: event.data.id
        })
    }
});


new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
}).$mount("#app")
