export const state = {
    accountStatus: "info",
    completeSteps: [],
    forgetEmail: "",
    isLoggedIn: false,

    userCacheData: null,
    nextRoute: null,

}






 
