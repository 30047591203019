<template>

  <div class=" details-container">
    <div class="details--heading">
      <h3>{{ $t("change-password.title") }}</h3>
    </div>
    <div class="details-divider"></div>

    <div class="details-content">
      <form ref="passwordForm"  class="flex flex-col gap-4">
        <TextInput :required="true"
                   :type="showOldPassword ? 'text' : 'password'"
                   :label='$t("input.old-password.title")'
                   :placeholder='$t("input.old-password.placeholder")'
                   :rules="['required','password']"
                   :error="this.error.oldPassword"

                   @inputValue="(value)=>this.form.oldPassword=value"
                   @isReady="(value) => this.valid=value"
        >
          <template v-slot:prefix-icon>
            <profile-lock-icon/>
          </template>

          <template v-slot:suffix-icon>
            <eye-fontawesome @click="showOldPassword=!showOldPassword"/>
          </template>
        </TextInput>


        <TextInput :required="true"
                   :type="showNewPassword ? 'text' : 'password'"
                   :label='$t("input.new-password.title")'
                   :placeholder='$t("input.new-password.placeholder")'
                   :rules="['required','password']"
                   :error='this.error.newPassword'

                   @inputValue="(value)=>this.form.newPassword=value"
                   @isReady="(value) => this.valid=value"
        >
          <template v-slot:prefix-icon>
            <profile-lock-icon/>
          </template>

          <template v-slot:suffix-icon>
            <eye-fontawesome @click="showNewPassword=!showNewPassword"/>
          </template>
        </TextInput>


        <TextInput :required="true"
                   :type="showConfirmPassword ? 'text' : 'password'"
                   :label='$t("input.confirm-password.title")'
                   :placeholder='$t("input.confirm-password.placeholder")'
                   :confirm="this.form.newPassword"
                   :error="this.error.confirmPassword"
                   :rules="['required','password','confirm']"

                   @inputValue="(value)=>this.form.confirmPassword=value"
                   @isReady="(value) => this.valid=value"
        >
          <template v-slot:prefix-icon>
            <profile-lock-icon/>
          </template>

          <template v-slot:suffix-icon>
            <eye-fontawesome @click="showConfirmPassword=!showConfirmPassword"/>
          </template>
        </TextInput>

        <LoadingButton
            :btn-class="'text-center'"
            :name="$t('btn.save')"
            :loading="loading" class="w-full text-center mt-4"
            :on-click="()=>changePassword()"
        />


      </form>
    </div>
  </div>

</template>

<script>
import TextInput from "../../components/inputs/text";
import {mapGetters} from "vuex";
import LoadingButton from "../../components/buttons/loadingButton";

export default {
  name: "ProfileChangePassword",
  components: {LoadingButton, TextInput},
  data() {
    return {
      valid: false,

      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },

      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,

      error: {oldPassword: "", newPassword: "", confirmPassword: ""},
    };
  },


  computed: {
    ...mapGetters({
      loading: "app/getInputLoading",
    }),
    validate: function () {
      return this.error.newPassword.length === 0 && this.error.oldPassword.length === 0
          && this.error.confirmPassword.length === 0 && this.valid;
    },
  },

  methods: {
    changePassword() {


      if (this.form.oldPassword.length === 0) {
        this.error.oldPassword = this.$t("rules.required");
      } else this.error.oldPassword = ""

      if (this.form.newPassword.length === 0) {
        this.error.newPassword = this.$t("rules.required");
      } else this.error.newPassword = ""

      if (this.form.confirmPassword.length === 0) {
        this.error.confirmPassword = this.$t("rules.required");
      } else this.error.confirmPassword = ""


      Object.keys(this.form).map(function (key) {

        if (this.form[key].length === 0) {
          this.error[key] = this.$t("rules.required");
        } else {
          this.error[key] = "";
        }

      }.bind(this))

      if (!this.validate)
        return;


      this.$store.dispatch("user/changePassword", {
        "current-password": this.form.oldPassword,
        "new-password": this.form.newPassword,
      }).then(()=>{
        this.form.oldPassword = "";
        this.form.newPassword = "";
        this.form.confirmPassword = "";
        this.$refs.passwordForm.reset(); // This will clear that form

      }).catch(function (error) {
        if (error.response.status === 400) {
          this.error.oldPassword= this.$t("error_messages." + error.response.data.code);
        }
      }.bind(this));

    },
  },

}
</script>