<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="$t('service.booking.form')" :paths="[
          {title:$t('nav.our-offers'),link:'../offers'},
          {title:service.name,link:'../'+service.slug},
          ]"/>

      <div class="steps-timeline mt-12">
        <div class="steps-timeline--step active">
          <div class="step-no">
            1
          </div>
          <div class="step-name">
            {{ $t('service.booking.select') }}
          </div>
        </div>
        <div class="steps-timeline--line active"></div>
        <div :class="'steps-timeline--step '.concat(paymentStep?'active':'current')">
          <div class="step-no">
            2
          </div>
          <div class="step-name">
            {{ $t('service.booking.contact') }}
          </div>
        </div>
        <div :class="'steps-timeline--line '.concat(paymentStep?'active':'')"></div>
        <div :class="'steps-timeline--step '.concat(paymentStep?'current':'')">
          <div class="step-no">
            3
          </div>
          <div class="step-name">
            {{ $t('service.booking.payment') }}
          </div>
        </div>
      </div>

      <div class="service-book overflow-hidden mt-7">
        <div class="form">

          <div v-if="!paymentStep" class=" details-container">
            <div class="details--heading">
              <h3> {{ $t('service.booking.contact') }}</h3>
            </div>
            <div class="details-divider"></div>
            <div class="p-4 pt-2">

              <div class="step-one">
                <TextInput :required="true" :label="$t('input.full-name.title')"
                           :placeholder="$t('input.full-name.placeholder')"
                           :rules="['required']"
                           :error="error.name"
                           :value="this.bookForm.name"
                           @inputValue="(value)=>this.bookForm.name=value"
                           @isReady="(value) => this.valid=value"/>
                <div class="group">
                  <TextInput :label='$t("input.phone.title")'
                             :placeholder='$t("input.phone.placeholder")'
                             :rules="['required','phone']"
                             v-model="bookForm.phone"
                             :value="this.bookForm.phone"
                             :error="error.phone"
                             :required="true"
                             @inputValue="(value)=>this.bookForm.phone=value"
                             @isReady="(value) => this.valid=value"/>

                  <TextInput :label='$t("input.phone.second-title")'
                             :placeholder='$t("input.phone.placeholder")'
                             :rules="['phone']"
                             v-model="bookForm.second_phone"
                             :value="this.bookForm.second_phone"
                             :error="error.second_phone"
                             @inputValue="(value)=>this.bookForm.second_phone=value"
                             @isReady="(value) => this.valid=value"/>


                </div>
                <TextInput :required="true" :label='$t("input.email.title")'
                           :placeholder='$t("input.email.placeholder")'
                           :rules="['required','email']"
                           :value="this.bookForm.email"
                           :error="error.email"
                           @inputValue="(value)=>this.bookForm.email=value"
                           @isReady="(value) => this.valid=value"/>


                <SelectInput :required="true"
                             :label="$t('input.choose-country.title')"
                             :placeholder="$t('input.choose-country.placeholder')"
                             :onSelect="onCitySelect"
                             :rules="['required']"
                             :error="error.city"
                >
                  <template v-slot:options>
                    <option class="text-reg-20"
                            v-for="city in bookInfo.cities"
                            :key="city['city_code']"
                            :value="city['city_code']"
                    >
                      {{ city["title"] }}
                    </option>
                  </template>
                </SelectInput>

                <div class="coupon">
                  <div class="coupon-label">
                    {{ $t('input.discount.title') }}
                  </div>
                  <div class="coupon-btn" @click="couponInput" v-if="!showCouponInput">
                    <service-coupon-icon/>
                    {{ $t('input.discount.placeholder') }}

                  </div>

                  <div class="coupon-input" v-if="showCouponInput">
                    <TextInput :placeholder="$t('input.discount.placeholder')" :maxLength="12"
                               :value="bookForm.coupon"
                               :show-real-value="true"
                               :disabled="showTrash"
                               @inputValue="(value)=>this.bookForm.coupon=value"
                    />
                    <div class="coupon-input-btn" v-if="showActive" @click="showTrashFun">{{
                        $t('input.discount.active')
                      }}
                    </div>
                    <div class="coupon-input-btn icon" v-if="showTrash" @click="deleteCoupon">
                      <service-trash-icon/>
                    </div>
                  </div>
                  <span class="text-reg-12 text-error" v-if="!coupon.active">{{ coupon.message }}</span>
                </div>
              </div>

              <div class="btn btn-primary mt-4" @click="toPayment">
                {{ $t('service.booking.complete') }}
              </div>

            </div>
          </div>

          <div v-show="paymentStep" class="flex flex-col gap-7">

            <div class=" details-container relative">
              <div class="details--heading">
                <h3>{{ $t('service.booking.payment') }}</h3>
              </div>
              <div class="details-divider"></div>

              <div class="p-4 pt-2 ">
                <iframe class="iframe-placeholder" ref="iframe" id="payment_iframe" :src="paymentInfo.payment_url"
                        @load="iframeLoad"
                        style="width: 100%;height: 350px;touch-action:pan-x pan-y"/>

              </div>
              <div class="iframe-refresh  text-reg-12" @click="refreshIframe">
                <refresh-icon />
              </div>
            </div>
          </div>


        </div>
        <div class="book-info">

          <div class="info details-container">
            <div class="details--heading">
              <h3>{{ $t('service.booking.summary') }}</h3>
            </div>
            <div class="details-divider"></div>

            <div class="book-info--service">
              <img :src="bookInfo.service.image" alt="">
              <div class="inline-block">
                <h4 class="name">{{ bookInfo.service.title }}</h4>
                <div class="location">
                  <location-fill-icon/>
                  <div>
                    {{ bookInfo.service.country }} , {{ bookInfo.service.city }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class=" details-container">
            <div class="details--heading">
              <h3>{{ $t('service.booking.detail') }}</h3>
            </div>
            <div class="details-divider"></div>

            <div class="book-info--book">

              <div class="book-flex">
                <div>{{ $t("user.bookings.booked_at") }}</div>
                <div class="text-med-14">{{ service.startDate | moment("LL") }}</div>
              </div>


              <div class="book-divider"></div>
              <div class="book-flex">
                <div>{{ $t('service.booking.people') }}</div>
                <div class="text-med-16">{{ totalCounter }}</div>
              </div>
              <ul class="terms-list">
                <li v-for="counter in service.counters" :key="counter.id">
                  <div>{{ counter.title }} , X{{ counter.count }}</div>
                  <div>{{ PriceCalc(counter.price) }}</div>
                </li>
              </ul>

              <div class="book-divider"></div>
              <div class="book-flex">
                <div>{{ $t('service.booking.packages') }}</div>
              </div>

              <ul class="terms-list">
                <li>
                  <div>{{ service.package.title }}</div>
                  <div>{{ PriceCalc(service.package.price) }}</div>
                </li>

              </ul>
              <div class="book-divider"></div>
              <div class="book-flex">
                <div class="name">{{ $t("common.price.total") }}:</div>
                <div class="value">{{ PriceCalc(service.total) }}</div>
              </div>

              <div class="book-flex " v-if="service.discount>0">
                <div class="name">{{ $t("common.price.discount") }}:</div>
                <div class="value text-error">- {{ PriceCalc(service.discount) }}</div>
              </div>

              <div class="divider my-1"></div>


              <div class="book-flex" v-if="service.discount>0">
                <div class="name">{{ $t("common.price.total-after-discount") }}:</div>
                <div class="value text-primary">{{ PriceCalc(service.afterDiscount) }}</div>
              </div>


              <div class="book-flex " v-if="coupon.discount>0">
                <div class="name">{{ $t("common.price.coupon-discount") }}:</div>
                <div class="value text-error">- {{ PriceCalc(calCouponDiscount) }}</div>
              </div>

              <div class="book-flex">
                <div>{{ $t("common.price.tax") }}:</div>
                <div>{{ PriceCalc(calcTax) }}</div>
              </div>

              <div class="divider my-1"></div>

              <div class="book-flex">
                <div>{{ $t("common.price.total-price") }}:</div>
                <div class="text-primary">{{ PriceCalc(caltotalAfterTax) }}</div>
              </div>

            </div>
          </div>
        </div>
      </div>


    </div>


    <div class="modal-overlay" v-if="bookModal.show">
      <div class="modal-content">
        <div class="icon">
          <modal-success-icon/>
        </div>
        <div class="title">{{ $t('book.modal.title') }}</div>
        <div class="desc">{{ $t('book.modal.desc', {id: bookModal.id}) }}</div>
        <div class="btns">
          <div @click="goHome" class="btn btn-primary w-full">{{ $t("link.back-home") }}</div>
          <div @click="goToBookings" class="btn btn-gray-3 w-full">{{ $t('link.complete-booking') }}</div>
        </div>
      </div>
    </div>


  </div>
</template>

<style>
.iframe-placeholder {
  background: url('https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif') center center no-repeat;

}

.iframe-refresh {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  box-shadow: 0 10px 20px rgba(237, 240, 245, .5);
  background: #5d3ebd;
  border-radius: 135px;
  padding: 6px ;
  display: flex;
  justify-content: center;
  align-items: center;

}

.iframe-refresh svg{
  width: 26px;
  height: 26px;
}
</style>

<script>
import PageHeading from "../../components/PageHeading";
import TextInput from "../../components/inputs/text";
import {mapGetters} from "vuex";
import SelectInput from "@/components/inputs/select";


export default {
  name: "ServiceBook",

  components: {
    SelectInput,
    TextInput,
    PageHeading,
  },

  data() {
    return {
      valid: true,
      bookForm: {
        id: "",
        name: '',
        phone: '',
        second_phone: "",
        email: '',
        city: "",
        coupon: "",
        package_id: "",
        service_counters: []
      },

      error: {name: "", phone: "", email: '', city: ""},

      showCouponInput: false,
      showTrash: false,
      showActive: true,
      paymentStep: false,

    }
  },
  computed: {
    ...mapGetters({
      service: "services/bookings/getService",
      bookInfo: "services/bookings/getBookInfo",
      bookModal: "services/bookings/getBookModal",
      paymentInfo: "services/bookings/getPaymentInfo",
      isLoggedIn: "auth/getIsLoggedIn",
      coupon: 'services/bookings/getCoupon',
    }),

    validate: function () {
      return this.error.name.length === 0 && this.error.phone.length === 0
          && this.error.email.length === 0 && this.error.city.length === 0
          && this.valid;
    },


    totalCounter() {
      return this.service.counters.reduce((total, counter) => {
        return total + counter.count;
      }, 0);
    },

    calCouponDiscount() {
      if (this.coupon.discount > 0) {
        return (this.service.afterDiscount * this.coupon.discount) / 100
      }
      return 0;
    },

    calcTax() {
      return ((this.service.afterDiscount - this.calCouponDiscount) * this.service.taxPercent) / 100
    },


    caltotalAfterTax() {
      return this.service.afterDiscount - this.calCouponDiscount + this.calcTax;
    },
  },
  methods: {
    iframeLoad() {
      this.$refs['iframe'].classList.remove('iframe-placeholder');
    },

    refreshIframe() {
      this.toPayment();
      // this.$refs['iframe'].src = this.paymentInfo.payment_url;
    },

    onCitySelect(event) {
      this.bookForm.city = event.target.value;
    },
    couponInput() {
      this.showCouponInput = true;
    },
    showTrashFun() {
      if (this.bookForm.coupon.length > 0) {


        this.$store.dispatch("services/bookings/checkCoupon", {
          coupon: this.bookForm.coupon,
          id: this.service.id,
        }).then(() => {
          if (this.coupon.active) {
            this.$store.commit("app/successMessage", "coupon.success");
            this.showTrash = true;
            this.showActive = false;
          } else {
            this.$store.commit("app/failMessage", "coupon.fail");
            this.showTrash = false;
            this.showActive = true;
          }
        }).catch(() => {
          this.$store.commit("app/failMessage", "coupon.faiasdl");

        });
      }
    },
    deleteCoupon() {
      this.showTrash = false;
      this.showActive = true;
      this.bookForm.coupon = '';
      this.$store.commit("services/bookings/setCoupon", 0);
    },
    async toPayment() {
      Object.keys(this.bookForm).map(function (key) {

        if (this.bookForm[key].length === 0 && key !== 'second_phone') {
          this.error[key] = this.$t("rules.required");
        } else {
          this.error[key] = "";
        }

      }.bind(this))

      if (!this.validate)
        return;

      await this.$store.dispatch("services/bookings/bookService", this.bookForm)
          .catch((error) => {
            if (error.response.status === 405 || error.response.status === 405) {
              this.$store.commit("app/failMessage", "book.fail");
            } else if (error.response.status === 503) {

              this.$store.commit("app/failMessage", "book.zero_payment");
            }

          });
      if (this.paymentInfo.payment_url.length > 0) {
        this.paymentStep = true;
        document.body.scrollTop = document.documentElement.scrollTop = 0;

      }
    },

    goHome() {
      this.$store.dispatch('services/bookings/bookModal', {
        show: false
      });
      this.$router.push({name: 'home'});
    },


    goToBookings() {
      this.$store.dispatch('services/bookings/bookModal', {
        show: false
      });
      this.$router.push({name: 'user-bookings'});
    },

  },


  async created() {
    // await this.$store.dispatch("services/bookings/setFirstStep", false);
    await this.$store.dispatch("services/bookings/getServiceBookInfo", this.$route.params.slug);

    if (!("slug" in this.service) || this.service.slug !== this.$route.params.slug) {
      await this.$router.push(this.$route.path + "/../").then(() => {
        this.$router.go();

      });
      // window.location.pathname = this.$route.path;
      window.location.reload();
    }
    this.bookForm.id = this.service.id;
    this.bookForm.package_id = this.service.package.id;
    for (let counter of this.service.counters) {
      this.bookForm.service_counters.push({
        id: counter.id,
        value: counter.count,
      })
    }
    this.bookForm.name = this.bookInfo.user.name;
    this.bookForm.phone = this.bookInfo.user.phone;
    this.bookForm.email = this.bookInfo.user.email;


  },


  destroyed() {
    this.$store.commit("services/bookings/setCoupon", 0);
  }

}
</script>