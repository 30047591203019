<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="$t('others.terms')"/>

      <div class="pt-4 page-container" >

        <div class="page-content" v-html="terms.content"></div>
      </div>
    </div>

  </div>
</template>


<script>
import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: "TermsAndConditions",

  components: {
    PageHeading,
  },
  methods: {

    getTerms() {
      this.$store.dispatch("others/getTerms")
    },
  },
  computed: {
    ...mapGetters({
      terms: "others/getTerms",
    }),
  },
  created() {
    this.getTerms();
  },
}
</script>