<template>
  <div class="hero-container">


    <div class="container hero">

      <div class="home-custom-swiper ">

        <swiper class="overflow-hidden  w-full  home-hero-slider" ref="mySwiper" :options="swiperOptions">

          <slot/>

        </swiper>


        <div class="custom-swiper-btns">
          <div @click="()=>{ this.swiper.slideNext();}" class="swiper-btn-next">
            <arrow-icon/>
          </div>
          <div @click="()=>{ this.swiper.slidePrev();}" class="swiper-btn-prev">
            <arrow-icon/>
          </div>
        </div>

      </div>

      <div class="hero-text">
        <h1 class="hero-title">{{ $t("home.slider.title") }}</h1>
        <p class="hero-subtitle">{{ $t("home.slider.desc") }}</p>
        <div class="h-28"></div>

        <form class="hero-search fixed" @submit.prevent="search">
          <input class="hero-search--input" type="text" :placeholder="$t('home.search')">
          <button class="hero-search--btn">
            {{ $t('common.search') }}
          </button>
        </form>

      </div>
    </div>

  </div>
</template>

<script>

import {directive, Swiper} from "vue-awesome-swiper"
import "swiper/swiper.scss"

export default {
  name: "hero-section",
  props: {
    msg: String,
  },

  components: {
    Swiper,
  }, directives: {
    swiper: directive,
  },
  data() {
    return {
      appliedFilters: {
        search: "",
      },


      swiperOptions: {
        arrows: true,
        pagination: true,
        slidesPerView: 1,
        spaceBetween: 30,

        loop: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
      },
    }
  },
  computed: {
    link() {
      let lang = this.$route.params.lang;
      return "/" + lang + "/offers?search=";
    }, swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
  methods: {
    search(e) {
      this.$router.push(this.link + e.target.querySelector('input').value);
    },

  },


}
</script>
