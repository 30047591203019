<template>
  <button :type="type" class="btn btn-primary  btn-white" :disabled=" loading " @click="onClick">
    <div :class="btnClass" v-if="!loading">
      <div> {{ name }}</div>
      <slot name="icon"></slot>
    </div>
    <Loading :loading="loading" :color="'#fff'" :size="'14px'" class="flex justify-center py-1"></Loading>
  </button>
</template>

<script>
import Loading from "vue-spinner/src/PulseLoader"

export default {
  name: "LoadingButton",
  components: {Loading},
  props: {
    btnClass: {
      type: String,
      default: "btn-flex",
    },
    name: {
      type: String,
      default: "Submit",
    },
    type: {
      type: String,
      default: "button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    onClick:Function
  },


}
</script>