<template>
  <div id="app">
    <navbar :top-class="topClass"/>


    <div class="container mobile-container">

      <PageHeading :title="defaultHeading" :nav-title="navTitle" :show-back="showBack"/>


      <div class="profile-flex ">
        <UserSideBar class="h-show" v-if="isLoggedIn"/>

        <div class="profile-content w-full">
          <router-view/>
        </div>
      </div>
    </div>

    <AppFooter/>

    <loading/>
  </div>
</template>

<script>
import Navbar from "../../../components/navbar";
import UserSideBar from "../components/sidebar";
import PageHeading from "../../../components/PageHeading";
import Loading from "../../../components/loading";
import {mapGetters} from "vuex";
import AppFooter from "../../../components/AppFooter";

export default {
  name: "ProfileLayout",
  props: {
    topClass: {type: String, default: ""},

    heading: String,
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/getIsLoggedIn",

    }),
    navTitle() {
     return  this.$t(this.$route.meta.title);
    },
    showBack(){
      return this.$route.meta.showBack;
    },

    defaultHeading() {
      return this.$t('user.profile');
    },
  },
  components: {
    AppFooter,
    PageHeading,
    UserSideBar,
    Navbar,
    Loading,
  },
  mounted() {
    console.log('nav title:', this.navTitle)
  }
}
</script>
