<template>
  <router-link :to="link" class="city-card">
    <div class="overlay"></div>

    <div class="city-card-info">
      <h4 class="name">
        {{ destination.title }}
      </h4>

      <div class="places">
        <div>{{ destination.services }}</div>
        <div>{{ $t("destinations.place") }}</div>
      </div>
    </div>

    <img :src="destination.image" alt="">

  </router-link>
</template>

<script>

export default {
  name: "DestinationCard",
  props: {
    destination: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    link() {
      let lang = this.$route.params.lang;
      return "/" + lang + "/destinations/" + this.destination.slug;
    },
  },
}
</script>
