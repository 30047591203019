export const state = {
    latestServices: [],
    latestPageCount: 1,

    services: [],
    servicesPageCount: 1,


    serviceDetail: {
        id: 0,
        geolocation: "24.774265, 46.738586",
        title: "",
        excerpt: "",
        content: "",

        slug: "",
        terms_and_conditions: [],
        locale: 1,
        city: "",
        country: "",
        service_type: "",
        service_period: 0,
        max_people: 0,
        service_supervisors: 0,
        available_people: 0,
        price: 0,
        excluded_service: [],
        included_service: [],
        similar:{},
        slider: [
            {
                id: 1,
                media: "",
                name: "",
                is_video: 0,
            },

        ],
        timeline: [
            {
                day_no: 0,
                from: "",
                to: "",
                title: "",
                content: "",
            },

        ],
        review: {
            comments: {
                content: [
                    {
                        id: 0,
                        image: "",
                        name: "",
                        rate: 0,
                        comment: "",
                        created_at: "",
                        review_images: "",
                    },

                ],
            }
            ,
            rates: {
                rates: [
                    {
                        rate_count: 0,
                        rate: 4,
                    },

                ],
                rate_count: 0,
                rate_avg: 0,
            },
        }
        ,
        faqs: [
            {
                id: 0,
                question: "",
                answer: "",
            },
        ],
        service_counters:
            [
                {
                    id: 0,
                    title: "",
                    price: 0,
                },

            ],
        service_packages:
            [
                {
                    id: 0,
                    title: "",
                    additional_price: 0,
                },

            ],
        offer: null,
        provider: {
            id: 0,
            name: "",
            slug: "",
            image: "",
            rate: 0,
        },
    },


    serviceComments: [
        {
            id: 0,
            image: "",
            name: "",
            rate: 0,
            comment: "",
            created_at: "",
            review_images: [],
        },
    ],
    serviceSuggestions:[],


    //filters

    serviceFilters: [],
    filteredServices: [],
    totalFilteredServices: 0,
    filteredPageCount: 1

}







