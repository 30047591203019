<template>


  <label for="upload-avatar">
    <div class="image-preview">
      <div class="flex items-center justify-center image-preview-placeholder">
        <img class="" :src="preview" alt="" v-if="previewImage!=null||avatar!=null">
        <image-placeholder-icon v-if="previewImage==null && avatar==null"/>
      </div>
      <div class="upload-avatar-plus">
        <plus-icon/>
      </div>
    </div>


    <input
        id="upload-avatar"
        ref="fileInput"
        type="file" accept="image/*"
        @input="pickFile"
    />

  </label>
</template>

<script>


export default {
  name: "UploadAvatar",
  props: {
    avatar: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      previewImage: null,
    };
  },
  computed: {
    preview() {
      return this.previewImage ?? this.avatar;
    }
  },
  methods: {
    selectImage() {
      this.$refs.fileInput.click()
    },
    async pickFile() {

      this.previewImage = null;

      let input = this.$refs.fileInput
      let file = input.files
      if (file && file[0]) {
        let reader = new FileReader
        reader.onload = e => {
          this.previewImage = e.target.result
        }
        reader.readAsDataURL(file[0])
        this.$emit("input", file[0])

        const formData = new FormData();
        formData.append("file", file[0]);
         this.$store.dispatch("app/upload", formData).then(() => {

        this.$emit('changeAvatar', this.$store.getters["app/getUploadedFileLink"]);
        });
      }
    },
  },
}
</script>