<template>

  <div class="profile-container">
    <div class=" details-container">

      <div class="details--heading">
        <h3>
          {{ $t("sign-up.interests.title") }}
        </h3>
      </div>

      <div class="details-divider"></div>

      <div class="px-4 ">
        <div class="interests-grid">

          <div :class="`interests-container ${selectedInterests.includes(interest.id)?'active':''}`"
               v-for="interest in interests"
               :key="interest.id" @click="toggleInterests(interest.id)">

            <img :src="interest.image" :alt="interest.title" class="icon">
            <h3 class="text-center">{{ interest.title }}</h3>
          </div>

        </div>
        <span class="input--error" v-if="error.selectedInterests.length>0">{{ error.selectedInterests }}</span>


        <LoadingButton
            :btn-class="'text-center'"
            :name="$t('btn.send')"
            :loading="loading" class="w-full text-center mt-4"
            :on-click="()=>saveInterests()"
        />


      </div>

    </div>
  </div>

</template>

<script>
import LoadingButton from "../../../components/buttons/loadingButton";
import {mapGetters} from "vuex";

export default {
  name: "UserInterests",
  components: {LoadingButton},
  data() {
    return {
      selectedInterests: [],
      error: {selectedInterests: ""},
    }
  },
  computed: {
    ...mapGetters({
      interests: "app/getInterests",
      loading: "app/getInputLoading",
    }),
  },


  methods: {
    toggleInterests(interestId) {
      //toggle selected interests array
      if (this.selectedInterests.includes(interestId)) {
        this.selectedInterests.splice(this.selectedInterests.indexOf(interestId), 1);
      } else {
        this.selectedInterests.push(interestId);
      }
    },


    saveInterests() {
      if (this.selectedInterests.length === 0) {
        this.error.selectedInterests = this.$t("rules.interests.required");
        return;
      } else {
        this.error.selectedInterests = "";
      }


      this.$store.dispatch("auth/userInterests", {interests: this.selectedInterests}).then(() => {
        this.$store.commit("app/successMessage", "update.interests");
      })
    },
  },

  created() {
    this.$store.dispatch("app/getInterests").then(function () {
      this.selectedInterests = this.interests.filter((interest) => interest.isSelected === 1).map((interest) => interest.id);
    }.bind(this))
  },


}
</script>