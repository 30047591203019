<template>
  <div>

    <div class="container mobile-container ">
      <PageHeading :title="$t('others.settings')"/>


      <div class="page-container others-settings mb-5">


        <div class="others others-settings-links mb-4">
          <ul class="links-list">
            <div class="links-list--link" @click="openLanguage">
              <div class="title">{{ $t('others.language') }}</div>
              <mobile-arrow-icon class="arrow"/>
            </div>
            <div class="links-list--link" @click="openCurrency">
              <div class="title">{{ $t('others.currency') }}</div>
              <mobile-arrow-icon class="arrow"/>
            </div>

          </ul>
        </div>

        <div class="others others-settings-links">
          <ul class="links-list">
            <router-link :to="{name:'terms'}" class="links-list--link">
              <div class="title">{{ $t('others.terms') }}</div>
              <mobile-arrow-icon class="arrow"/>
            </router-link>
            <router-link :to="{name:'privacy-policy'}" class="links-list--link">
              <div class="title">{{ $t('others.policy') }}</div>
              <mobile-arrow-icon class="arrow"/>
            </router-link>

          </ul>
        </div>
      </div>
    </div>

    <vue-bottom-sheet ref="currencyModel" class="bottom-sheet">
      <h1 class="title">{{ $t('bottomSheet.currency.title') }}</h1>
      <h2 class="desc">{{ $t('bottomSheet.currency.desc') }}</h2>

      <div class="bottom-sheet-item" :class=" curr.title==currencyTitle?'active':''" v-for="curr in currencies"
           :key="curr.id" @click="()=>changeCurrency(curr)">
        <p class="prefix">{{ curr.symbol }}</p>
        <p class="name">{{ curr.title }}</p>
      </div>
    </vue-bottom-sheet>

    <vue-bottom-sheet ref="languageModel" class="bottom-sheet">
      <h1 class="title">{{ $t('bottomSheet.currency.title') }}</h1>
      <h2 class="desc">{{ $t('bottomSheet.currency.desc') }}</h2>

      <div class="bottom-sheet-item" :class=" lang.code==currentLang?'active':''" v-for="(lang,index) in languages" :key="index"
           @click="()=>changeLanguage(lang)">
        <p class="prefix"><img :src="lang.icon" :alt="lang.title"></p>
        <p class="name">{{ $t(`language.${lang.code}`) }}</p>
      </div>


    </vue-bottom-sheet>
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import PageHeading from "@/components/PageHeading";

import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";

export default {
  name: "OthersSettings",

  components: {VueBottomSheet, PageHeading},

  data() {
    return {

      languages: [
        {
          code: "ar",
          title: "العربية",
          icon: "https://halabkstorage.s3.eu-central-1.amazonaws.com/ar_lang.png"
        },
        {
          code: "en",
          title: "English",
          icon: "https://halabkstorage.s3.eu-central-1.amazonaws.com/en_lang.png"
        }
      ],
      currencyTitle: "",
    }
  },
  methods: {
    currency_title() {
      const code = localStorage.getItem("currency");
      if (code) {
        this.currencies.forEach(currency => {
          if (currency.code == code) {
            this.currencyTitle = currency.title;
            this.changeCurrency(currency, false);
          }
        });
      } else {
        this.changeCurrency(this.currencies[0]);
        this.currencyTitle = this.currencies[0].title;
      }

    },

    changeCurrency(currency, reload = true) {

      this.$store.dispatch("app/setCurrency", {currency: currency, reload: reload})
    },

    changeLanguage(lang) {
      if (this.languages.includes(lang)) {
        this.$store.dispatch("app/setLanguage", lang.code);
      }
    },

    openLanguage() {
      this.$refs.languageModel.open();
    },

    openCurrency() {
      this.currency_title();
      this.$refs.currencyModel.open();
    }
  },
  computed: {
    ...mapGetters({
      currencies: "app/getCurrencies",
    }),
    currentLang() {
      return this.$route.params.lang;
    },
  },
  created() {
    this.currency_title();
  },
}
</script>