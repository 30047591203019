<template>
  <div class="section-work my-28">
    <div class="section-work-bg">
      <img class="w-full" src="@/assets/patterns/pattern_1.png" alt="halabk pattern">

      <h2 class="section-work-title">{{ $t('home.work.title') }}</h2>
    </div>
    <div class="h-20"></div>

    <div class="section-work-container">

      <div class="work-card">
        <div class="work-card--icon">
          <location-outline-icon/>
        </div>
        <h3 class="work-card--title">{{ $t("home.work.first.title") }}</h3>
        <p class="work-card--desc">{{ $t('home.work.first.desc') }}</p>
      </div>

      <long-arrow-icon class="arrow white " :class="language==='en'?'work-icon-reverse':''"/>

      <div class="work-card">
        <div class="work-card--icon">
          <dropdown-outline-icon/>
        </div>
        <h3 class="work-card--title">{{ $t("home.work.second.title") }}</h3>
        <p class="work-card--desc">{{ $t('home.work.second.desc') }}</p>
      </div>

      <long-arrow-icon class="arrow " :class="language==='en'?'work-icon-reverse':''"/>

      <div class="work-card">
        <div class="work-card--icon">
          <calendar-outline-icon/>
        </div>
        <h3 class="work-card--title">{{ $t("home.work.third.title") }}</h3>
        <p class="work-card--desc">{{ $t('home.work.third.desc') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "work-section",
  computed: {
    ...mapGetters({
      language: "app/getLanguage",
    }),
  },
}
</script>
