<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="$t('others.faqs')"/>


      <div class="faq-container">

        <div class="pt-4 pb-12">

          <faq-accordion v-for="faq in faqs"
                         :key="faq.id"
                         class="faq-white"
                         :title="faq.question"
          >
            {{ faq.answer }}
          </faq-accordion>

        </div>
      </div>

    </div>

  </div>
</template>


<script>
import PageHeading from "../../components/PageHeading";
import FaqAccordion from "../../components/faqAccordion";
import {mapGetters} from "vuex";

export default {
  name: "FAQs",

  components: {
    FaqAccordion,
    PageHeading,
  },

  methods: {

    getFaqs() {
      this.$store.dispatch("others/getFaqs")
    },
  },
  computed: {
    ...mapGetters({
      faqs: "others/getFaqs",
    }),
  },
  created() {
    this.getFaqs();
  },
}
</script>