export const getters = {


    getProviderDetail(state) {
        return state.providerDetail
    },

    getProviderComments(state) {
        return state.providerComments
    },

    getProviderBasic(state) {
        return state.providerBasic
    }


}