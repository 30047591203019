export const getters = {
    getPolicy(state) {
        return state.policy
    },
    getTerms(state) {
        return state.terms
    },
    getFaqs(state) {
        return state.faqs
    },
    getAbout(state) {
        return state.about;
    },

    getFooter(state) {
        return state.footer;
    }

}