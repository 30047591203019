export const mutations = {
    setCurrentBookings: (state, data) => {
        state.currentBookings = data.content
        state.currentPageCount = data.last_page
    },

    setCompleteBookings: (state, data) => {
        state.completeBookings = data.content
        state.completePageCount = data.last_page
    },

    setBookDetails: (state, data) => {
        state.bookDetails = data
    },


    setComment: (state, data) => {
        state.comment = data
    },

    setBookingCount: (state, data) => {
        state.bookingCount = data;
    }
}