import Vue from "vue"

import app from "./app"
import home from "./home"
import auth from "./auth"
import user from "./user"
import articles from "./articles"
import others from "./others"
import destinations from "./destinations"
import services from "./services"
import provider from "./provider"
import partners from "./partners";


import Vuex from "vuex"

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        app,
        home,
        partners,
        auth,
        user,
        provider,
        articles,
        destinations,
        services,
        others,
    },

    strict: true,

})
