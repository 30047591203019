export const state = {
    locales: ["ar", "en"],
    language: {
        locale: "ar",
        dir: "rtl",
    },

    isMobile: false,
    loading: false,
    inputLoading: false,
    mobileLinkStatus: true,
    currentActiveRoute: 'home',

    currencies: [],
    currentCurrency: 'SAR',
    countries: [],
    interests: [],
    affiliateData: {},


    uploadedFileLink: "",
    notification404:true,
}






 
