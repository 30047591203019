<template>

  <div class="pref-tile">
    <div class="icon">
      <slot/>
    </div>
    <div class="text">

      <div class="name">{{ name }}</div>
      <div class="value">{{ value }}</div>
    </div>
  </div>

</template>

<script>

export default {
  name: "pref-tile",
  props: {
    name: String,
    value: [Number,String],
  },
}
</script>