<template>
  <label class="flex items-center justify-between text-reg-14 ">
    <div class="flex items-center gap-2 relative">
      <input type="checkbox" :value="value" @change="onChange">
      <span>{{ label }}</span>
    </div>
    <div class="text-gray-3">({{ count ? count : 0 }})</div>
  </label>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    value: [String, Number],
    label: String,
    count: Number,
    onChange: Function,
  },

}
</script>

