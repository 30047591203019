<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title='$t("nav.latest-offers")'/>

      <div class="services-container">

        <Nothing
            v-if="services.length===0"
            :title="$t('nothing.services.title')"
            :desc="$t('nothing.services.desc')"
        />

        <div class="services-grid pt-6">
          <ServiceCard v-for="service in services"
                       :key="service.id"
                       :service="service"/>

        </div>

      </div>

      <paginate
          v-if="pageCount>1"
          v-model="page"
          class="mt-12"
          :page-count="pageCount"
          :page-range="4"
          :margin-pages="1"

          :prev-text="'<'"
          :next-text="'>'"

          :click-handler="paginationCallback"

          :container-class="'pagination'"
          :break-view-text="'...'"
          :page-class="'page-item'">
      </paginate>

    </div>


  </div>
</template>


<script>
import PageHeading from "../../../components/PageHeading";

import paginate from "vuejs-paginate";


import ServiceCard from "../../../components/ServiceCard";
import {mapGetters} from "vuex";
import Nothing from "../../../components/nothing";

export default {
  name: "Services",

  components: {
    Nothing,
    ServiceCard,
    PageHeading, paginate,
  },
  data() {
    return {
      page: this.$route.query.page ?? 1,
    }
  },
  methods: {
    paginationCallback(page) {
      this.page = page;

      this.$router.push({query: {page: this.page}})
      this.getServices();
    },
    getServices() {
      this.$store.dispatch("services/getLatestServices", {page: this.page})
    },
  },
  computed: {
    ...mapGetters({
      services: "services/getLatestServices",
      pageCount: "services/getLatestPageCount",
    }),
  },
  created() {
    this.getServices();
  },
}
</script>