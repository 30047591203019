// import axios from '@/plugins/axios';
import i18n from "@/i18n";
import router from "../../routes";
import {store} from "../index"

async function cacheUserAndRedirect(response, isLogin) {
    let date = new Date()
    let token = {...response.data.data.token}

    let responseData = {
        token: token,
        id: response.data.data.id,
        name: response.data.data.name,
        email: response.data.data.email,
        image: response.data.data.image,
        complete_status: response.data.data.complete_status,
        exp_date: date.setSeconds(date.getSeconds() + response.data.data.token.expires_in),
    }

    localStorage.setItem("id", response.data.data.id)


    await this._vm.$cookies.set("login-data", JSON.stringify(responseData), null, "/",

        // httpOnly:true,
        // sameSite: true
    )
    let loginData = this._vm.$cookies.get("login-data", {parseJSON: true})

    if (loginData) {
        this._vm.$http.defaults.headers.common["Authorization"] = `Bearer ${loginData.token.access_token}`
        if (loginData.complete_status === "verify") {

            // store.commit("auth/setAccountStatus", "verify")
            // store.commit("auth/setCompleteSteps", ["info"])
            // router.push("/" + i18n.locale + "/signUp")

            await actions.redirectVerifyStatus(this)

        } else if (loginData.complete_status === "interests") {
            // store.commit("auth/setAccountStatus", "interests")
            // store.commit("auth/setCompleteSteps", ["info", "verify"])
            // router.push("/" + i18n.locale + "/signUp")
            await actions.redirectInterestsStatus(this)
        } else {

            if (isLogin) {

                store.commit("auth/setIsLoggedIn", true)
                let nextRoute = store.getters["auth/getNextRoute"];
                if (nextRoute) {
                    await router.push(nextRoute.path).catch(() => {
                    });
                } else {
                    await router.push("/" + i18n.locale )
                }
            } else {
                // dispatch('loadUserDetail')
                // commit('setLoggedInuser', loginData)
                await router.push("/" + i18n.locale + "/profile")
            }
        }

    }
}

export const actions = {

    async resetSignUp({commit}) {
        commit("setAccountStatus", "info")
        commit("setCompleteSteps", [])

        commit("setIsLoggedIn", false)
        this._vm.$cookies.remove("login-data")
    },

    async redirectVerifyStatus({commit}) {

        commit("auth/setAccountStatus", "verify")
        commit("auth/setCompleteSteps", ["info"])
        await router.push("/" + i18n.locale + "/signUp").catch(() => {
        });
    },

    async redirectInterestsStatus({commit}) {
        commit("auth/setAccountStatus", "interests", {root: true})
        commit("auth/setCompleteSteps", ["info", "verify"], {root: true})
        await router.push("/" + i18n.locale + "/signUp").catch(() => {
        });
    },

    async login({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("login", data)
            .then((respo) => {
                if (respo.status === 200) {

                    cacheUserAndRedirect.call(this, respo, true);

                    commit("app/setInputLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                throw error
            })
    },

    async forgetPassword({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("forget-password", data)
            .then((respo) => {
                if (respo.status === 200) {

                    router.push("/" + i18n.locale + "/confirm-token")

                    commit("app/setInputLoading", false, {root: true})
                    commit("setForgetEmail", data.email)

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                throw error
            })
    },


    async confirmCode({commit, getters}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("confirm-code", data)
            .then((respo) => {
                if (respo.status === 200) {

                    router.push("/" + i18n.locale + "/reset-password?token=" + respo.data.data + "&email=" + getters.getForgetEmail);

                    commit("app/setInputLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                throw error
            })
    },


    async resetPassword({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("reset-password", data)
            .then((respo) => {
                if (respo.status === 200) {

                    cacheUserAndRedirect.call(this, respo);

                    commit("app/setInputLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setInputLoading", false, {root: true})
                throw error
            })
    },


    async signUp({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("signup", data)
            .then((respo) => {
                if (respo.status === 200) {

                    cacheUserAndRedirect.call(this, respo);

                    commit("app/setInputLoading", false, {root: true})

                    return respo;
                }
            }).catch((error) => {

                commit("app/setInputLoading", false, {root: true})

                return Promise.reject(error);
            })

    },

    async loginWithGoogle({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("google/login", data)
            .then((respo) => {
                if (respo.status === 200) {

                    cacheUserAndRedirect.call(this, respo, true);

                    commit("app/setInputLoading", false, {root: true})

                    return respo;
                }
            }).catch((error) => {

                commit("app/setInputLoading", false, {root: true})

                return Promise.reject(error);
            })

    },

    // async loginWithApple({commit}, data) {
    //     commit("app/setInputLoading", true, {root: true})
    //     await this._vm.$http.post("apple/login", data)
    //         .then((respo) => {
    //             if (respo.status === 200) {
    //
    //                 cacheUserAndRedirect.call(this, respo, true);
    //
    //                 commit("app/setInputLoading", false, {root: true})
    //
    //                 return respo;
    //             }
    //         }).catch((error) => {
    //
    //             commit("app/setInputLoading", false, {root: true})
    //
    //             return Promise.reject(error);
    //         })
    //
    // },

    async verify({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("verify-account", data)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setAccountStatus", "interests")
                    commit("setCompleteSteps", ["info", "verify"])

                    commit("app/setInputLoading", false, {root: true})

                    return respo;
                }
            }).catch((error) => {

                commit("app/setInputLoading", false, {root: true})

                return Promise.reject(error);
            })

    },

    async resendVerifyCode({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("resent-verify", data)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("app/setInputLoading", false, {root: true})

                    return respo;
                }
            }).catch((error) => {

                commit("app/setInputLoading", false, {root: true})

                return Promise.reject(error);
            })

    },


    async userInterests({commit}, data) {
        commit("app/setInputLoading", true, {root: true})
        await this._vm.$http.post("account-interests", data)
            .then((respo) => {
                if (respo.status === 200) {

                    let loginData = this._vm.$cookies.get("login-data", {parseJSON: true})
                    let token = {...loginData.token}
                    let responseData = {
                        id: loginData.id,
                        name: loginData.name,
                        email: loginData.email,
                        image: loginData.image,
                        token: token,
                        complete_status: "done",
                        exp_date: loginData.expires_in,
                    }


                    this._vm.$cookies.set("login-data", JSON.stringify(responseData), null, "/",

                        // httpOnly:true,
                        // sameSite: true
                    )

                    commit("setAccountStatus", "done")
                    commit("setCompleteSteps", ["info", "verify", "interests"])

                    commit("app/setInputLoading", false, {root: true})

                    return respo;
                }
            }).catch((error) => {

                commit("app/setInputLoading", false, {root: true})

                return Promise.reject(error);
            })

    },

    async checkIsLoggedIn({commit}) {
        let loginData = this._vm.$cookies.get("login-data", {parseJSON: true})


        if (loginData) {
            commit("setUserCacheData", loginData)
            commit("setIsLoggedIn", true)
        } else {
            commit("setIsLoggedIn", false)
        }
    },

    async logout() {

        this._vm.$cookies.remove("login-data")
        window.location.href = "/"
        // commit("app/setLoading", true, {root: true})
        // await this._vm.$http.post("logout")
        //     .then((respo) => {
        //         if (respo.status === 200) {
        //
        //
        //             this._vm.$cookies.remove("login-data")
        //             commit("app/setLoading", false, {root: true})
        //
        //             commit("setIsLoggedIn", false)
        //             return respo;
        //         }
        //     }).catch((error) => {
        //         commit("app/setLoading", false, {root: true})
        //
        //         return Promise.reject(error);
        //     })
    },
}