<template>

  <div class="location details-container">
    <div class="details--heading">
      <h3>
        {{ $t("service.detail.tabs.location") }}
      </h3>
      <a
          :href="`https://www.google.com/maps/place/${locationLat},${locationLng}/@${locationLat},${locationLng},15z`"
         target="_blank"
         class="text-semi-14 text-primary">
        {{$t('common.open-map')}}
      </a>
    </div>
    <div class="details-divider"></div>

    <div class="location-content">
      <GmapMap
          :center="center"
          :zoom="8"
          map-style-id="roadmap"
          :options="mapOptions"
          ref="mapRef"
      >
        <GmapMarker
            :position="marker.position"
        />
      </GmapMap>
    </div>
  </div>

</template>

<script>



export default {
  name: "location-section",
  props: {
    locationLat: [Number, String],
    locationLng: [Number, String],
  },

  computed: {
    marker() {
      let tempLat = parseFloat(this.locationLat);
      let tempLng = parseFloat(this.locationLng);

      return {
        position: {
          lat: tempLat,
          lng: tempLng,
        },
      }
    },
    center() {
      let tempLat = parseFloat(this.locationLat);
      let tempLng = parseFloat(this.locationLng);
      return {
        lat: tempLat,
        lng: tempLng,
      }
    },
  },

  data() {
    return {

      mapOptions: {
        disableDefaultUI: true,
      },
    }
  },
}
</script>