import {actions} from "./actions.js"
import {mutations} from "./mutations.js"
import {getters} from "./getters.js"
import {state} from "./state.js"

import favorites from "./favorites"
import bookings from "./bookings"
import chats from "./chats"

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

    modules: {
        favorites,
        bookings,
        chats
    },
}