export const mutations = {
    setDestinations: (state, data) => {
        state.destinations = data.content
        state.pageCount = data.last_page
        state.destinationsCount = data.total
    },

    setDestinationDetail: (state, data) => {
        state.detail = data
    },
}