export const getters = {
    getAccountStatus: state => state.accountStatus,

    getCompleteSteps: state => state.completeSteps,

    getForgetEmail: state => state.forgetEmail,

    getNextRoute: state => state.nextRoute,

    getIsLoggedIn: state => state.isLoggedIn,

    getUserCacheData: state => state.userCacheData,

}