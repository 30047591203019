<template>
  <router-link :to="link" class="article-card">
    <div class="article-card-header">
      <div class="overlay"></div>

      <img :src="article.image" alt="">

    </div>
    <div class="article-card-footer">
      <div class="date">
        <calendar-small-icon/>
        <time class="text-light-10">{{ article.created_at | moment("LL") }}</time>

      </div>
      <p class="title">{{ article.title }}</p>
      <p class="desc">
        {{ article.excerpt }}
      </p>
      <router-link class="link mt-2" :to="link">
        {{ $t("link.read-more") }}
        <double-arrow-icon class="icon-reverse-ltr"/>
      </router-link>
    </div>
  </router-link>
</template>

<script>

export default {
  name: "ArticleCard",
  props: {
    article: {
      type: Object,
      required: true
    },
  },
  computed: {
    link() {
      let lang = this.$route.params.lang;
      return "/" + lang + "/articles/" + this.article.slug;
    },
  },

}
</script>
