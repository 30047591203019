<template>
  <div class="container mobile-container">
    <PageHeading :title="$t('notifications.title')"/>

    <div class="notification-container">

      <div class="notifications pt-4 " :class="notifications.length===0?'justify-center':''">


        <Nothing
            v-if="notifications.length===0"
            :title="$t('nothing.notifications.title')"
            :desc="$t('nothing.notifications.desc')"
        />


        <div class="notification-card " v-for="notification in notifications" :key="notification.id">
          <div class="icon">
            <profile-bell-icon/>
          </div>
          <div class="content">
            <div class="flex items-center justify-between">
              <div class="title">
                {{ notification.title }}
              </div>
              <div class="time">
                <profile-clock-icon/>
                <time class="text-light-10">{{ notification.created_at | moment("from", "now", true) }}</time>

              </div>
            </div>
            <div class="desc">
              {{ notification.title }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <paginate
        v-if="pageCount>1"
        v-model="page"
        class="mt-12"
        :page-count="pageCount"
        :page-range="4"
        :margin-pages="1"

        :prev-text="'<'"
        :next-text="'>'"

        :click-handler="paginationCallback"

        :container-class="'pagination'"
        :break-view-text="'...'"
        :page-class="'page-item'">
    </paginate>
  </div>
</template>

<script>
import paginate from "vuejs-paginate";

import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";
import Nothing from "../../components/nothing";

export default {
  name: "Notifications",
  components: {Nothing, PageHeading, paginate},
  data() {
    return {
      page: this.$route.query.page ?? 1,
    }
  },
  methods: {
    paginationCallback(page) {
      this.page = page;

      this.$router.push({query: {page: this.page}})
      this.getNotifications();
    },
    getNotifications() {
      this.$store.dispatch("user/getUserNotifications", {page: this.page})
    },
  },
  computed: {
    ...mapGetters({
      notifications: "user/getNotifications",
      pageCount: "user/getNotificationsPageCount",
    }),
  },
  created() {
    this.getNotifications();
  },
  mounted() {

    this.toggleMobileBottomBar(true)
  },
  beforeDestroy() {
    this.toggleMobileBottomBar(false)
  }
}
</script>