export const actions = {

    async getAllFavoritesIds({commit}) {
        await this._vm.$http.get(`user/favorites/ids`,)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setAllFavoritesIds", respo.data.data)


                }
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    },


    async getFavorites({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`user/favorites?page=${data.page}`, data)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setFavorites", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


    async deleteAllFavorites({commit}) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.post(`user/favorites/remove-all`)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setFavorites", {
                        content: [],
                        total: 0,
                        last_page: 0,
                    })

                    commit("app/successMessage", "favorite.remove-all", {root: true})
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


    async toggleFavorite({commit}, service_id) {
        // commit("app/setLoading", true, {root: true})
        await this._vm.$http.post(`user/favorites/toggle/${service_id}`)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                // commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


}