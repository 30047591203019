<template>
  <div class="home-custom-swiper relative">

    <div class="container mt-28 mb-20">
      <SectionTile :title="title" :link="link"/>


      <Nothing
          v-if="length===0"
          :small="true"
          :title="$t('nothing.'+nothing_key+'.title')"
          :desc="$t('nothing.'+nothing_key+'.desc')"
      />

      <swiper class="overflow-hidden pb-8" ref="mySwiper" :options="swiperOptions" v-if="length>0">

        <slot/>

      </swiper>
    </div>
    <div class="custom-swiper-btns" v-if="length>0">
      <div @click="()=>{ this.swiper.slideNext();}" class="swiper-btn-next">
        <arrow-icon/>
      </div>
      <div @click="()=>{ this.swiper.slidePrev();}" class="swiper-btn-prev">
        <arrow-icon/>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTile from "../../../components/SectionTile.vue"

import {directive, Swiper} from "vue-awesome-swiper"
import "swiper/swiper.scss"
import Nothing from "../../../components/nothing";

export default {
  name: "home-slider",
  props: {
    title: String,
    link: String,
    nothing_key: {
      type: String,
      default: 'data'
    },
    slides: Number,
    length: {
      type: Number,
      default: 1
    }
  },

  components: {
    Nothing,
    Swiper,
    SectionTile,
  }, directives: {
    swiper: directive,
  },

  data() {
    return {
      swiperOptions: {
        arrows: true,
        slidesPerView: this.slides ?? 3,
        spaceBetween: 30,
        loop: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: this.slides ?? 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: this.slides ?? 3,
            spaceBetween: 20,
          },

          0: {
            slidesPerView: "auto",
            spaceBetween: 10,
          }
        }
      },
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    },
  },
}
</script>
