export const actions = {


    async getChatList({commit}) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`user/chats`)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setChats", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

    async getChatMessages({commit}, id) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`user/chats/${id}`)
            .then((respo) => {
                if (respo.status === 200) {
                    commit("setChatMessages", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },


    async sendChatMessage({commit}, data) {
        await this._vm.$http.post(`user/chats/${data.id}`, data)
            .then((respo) => {
                if (respo.status === 200) {
                    commit("setChatMessages", respo.data.data)
                }
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    },


}