export const mutations = {
    setBookings(state, data) {
        state.service = data;
        state.completeFirstStep = true;
    },

    setFirstStep(state, data) {
        state.completeFirstStep = data;
    },

    setPaymentInfo(state, data) {
        state.paymentInfo = data;

    },

    setBookInfo(state, data) {
        state.bookInfo = data;
    },

    setCoupon(state, data) {
        state.coupon = data;
    },

    setBookModal(state, data) {
        state.bookModal = data;
    }
}