<template>


  <div class="container">

    <div>

      <SectionTile :title="$t('home.latest-offers')"/>

      <Nothing
          v-if="services.length===0"
          :small="true"
          :title="$t('nothing.services.title')"
          :desc="$t('nothing.services.desc')"
      />

      <div class="home-services-grid">
        <ServiceCard v-for="service in services"
                     :key="service.id"
                     :service="service"/>
      </div>
      <div class="text-center" v-if="services.length>0">

        <router-link :to="{name:'services'}"
                     class="offer-btn">
          {{ $t('common.show-all') }}
          <div class="rotate-90">
            <arrow-icon/>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import ServiceCard from "../../../components/ServiceCard";
import SectionTile from "../../../components/SectionTile";
import Nothing from "../../../components/nothing";

export default {
  name: "offers-section",
  components: {Nothing, SectionTile, ServiceCard},
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
}
</script>
