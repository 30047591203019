<template>
  <router-link :to="link"
               :class="'service-card '.concat(favorite?'isFavorite ':'') + this.cssClass">
    <div class="service-card-header">
      <div class="overlay"></div>

      <div class="header-info">
        <div class="offers">
          <!--          <slot/>-->

          <div class="service-card-offer-tag bg-secondary" v-if="closePercent>=50">
            {{ $t("common.close-soon") }}
            {{ closePercent }}%
          </div>
          <div class="service-card-offer-tag bg-primary" v-if="service.offer!=null">
            {{ $t("common.discount") }}
            {{ service.offer.discount_percent }}%
          </div>

        </div>

        <div class="favorite" @click="(e)=>{e.preventDefault(); favoriteClick(service.id)}">
          <heart-empty-icon/>
        </div>
      </div>

      <img :src="service.image" alt="">

    </div>
    <div class="service-card-footer">
      <div class="provider">
        <h3>{{ service.provider }}</h3>
<!--        <div class="rate">
          <star-fill-icon/>
          <div>{{ service.rate }}</div>
        </div>-->
      </div>
      <p class="desc" :title="service.title">
        {{ service.title }}
      </p>

      <div class="info">
        <!--todo:: fix the price-->
        <div class="price-info">
          <div class="price" :class="service.offer==null?' mb-2':''">
            {{ PriceCalc(servicePrice) }}
            <div class="price--old" v-if="service.offer!=null">
              {{ PriceCalc(service.price) }}
            </div>
          </div>

        </div>

        <div class="info-end">

          <p> {{ $t("service.person-price") }}</p>
          <div class="location-info">
            <location-fill-icon/>
            <div class=" max-1-lines " :title="`${service.city}  ,  ${service.country}`">
              {{ service.city }} , {{ service.country }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </router-link>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "ServiceCard",
  props: {
    service: {
      type: Object,
      default: () => {
        return {}
      },
    },
    onFavoriteClick: {
      type: Function,
      default: () => {
      },
    },
    image: String,
    cssClass: String,
    isFavorite: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      favoritesIds: "user/favorites/getAllFavoritesIds",
      isGetAllIds: "user/favorites/getIsGetAllFavoritesIds",
      isLoggedIn: "auth/getIsLoggedIn",
    }),
    favorite() {

      if (this.isGetAllIds)
        return this.favoritesIds.includes(this.service.id);
      else return this.isFavorite || this.service.is_favorite;

    },
    link() {
      let lang = this.$route.params.lang;
      return "/" + lang + "/services/" + this.service.slug;
    },
    servicePrice() {
      if (this.service.offer != null)
        return this.service.price - ((this.service.price * this.service.offer.discount_percent) / 100).toFixed(2);
      return this.service.price;
    },
    closePercent() {
      return (((this.service.max_people - this.service.available_people) / this.service.max_people) * 100).toFixed(1);
    },
  },

  methods: {
    getFavoritesIds() {
      this.$store.dispatch("user/favorites/getAllFavoritesIds");
    },


    favoriteClick(id) {
      this.$store.dispatch("auth/checkIsLoggedIn");

      if (this.isLoggedIn) {
        this.$store.dispatch("user/favorites/toggleFavorite", id).then(async () => {
          if (this.favorite) {
            this.$store.commit("app/successMessage", "favorite.remove");
          } else {
            this.$store.commit("app/successMessage", "favorite.add");
          }
          await this.getFavoritesIds();
          this.onFavoriteClick();
        })
      }else{
        this.$store.commit("app/failMessage", "login.login");
      }
    },
  },

}
</script>
