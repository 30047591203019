export const state = {
    chats: [],
    chatMessages: {
        chatId: null,
        currentUserId: null,
        messages: [
            {
                id: "",
                sender_id: 0,
                body: "",
                type: "",
                created_at: "2022-04-03T11:35:04.579962"
            }
        ],
        members: [
            {
                id: "",
                name: "",
                image: "",
            }
        ],
        provider: {
            id: 0,
            image: "",
            name: "",
            rate: 0,
            slug: ""
        }
    },
}






 
