<template>

  <div class="container mobile-container about">
    <PageHeading :title="$t('others.404.heading')"/>


    <div class=" details-container mb-5 mt-28" style="background: transparent">

      <div class="flex gap-4 justify-center items-center">
        <page-404-svg style="flex: none"/>
        <div class="flex flex-col gap-1 items-start">
          <h3 class="text-semi-24 mb-2">{{ $t('others.404.title') }}</h3>
          <p class="text-med-20 text-gray-2 mb-10">{{ $t('others.404.desc') }}</p>

            <router-link :to="{name:'home'}" class="btn btn-primary  btn-white">
              <div class="btn-flex gap-2">
                <arrow-icon class="icon-reverse-ltr icon-rotate-90" fill="#fff"/>
                <div> {{ $t('others.404.btn') }}</div>
              </div>
            </router-link>

        </div>
      </div>

    </div>

  </div>
</template>


<script>
import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: "Page404",

  components: {
    PageHeading,
  },

  methods: {

    getAbout() {
      this.$store.dispatch("others/getAbout")
    },
  },
  computed: {
    ...mapGetters({
      about: "others/getAbout",
    }),
  },
  created() {
    this.getAbout();
  },
}
</script>