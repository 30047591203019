export const getters = {
    getArticles(state) {
        return state.articles
    },
    getPageCount(state) {
        return state.pageCount
    },
    getDetail(state) {
        return state.detail
    },

}