import ToastificationContent from "@/components/Toastification.vue";
import Vue from "vue";
import i18n from "@/i18n";

export const mutations = {


    setLanguage(state, lang) {

        if (state.locales.includes(lang)) {
            state.language.locale = lang
            if (lang === "en") {
                state.language.dir = "ltr"
            }
            localStorage.setItem('locale',lang)
        }
    },
    setNotification404(state,status){
        state.notification404=status;
    },
    setLoading(state, status) {
        state.loading = status;
    },
    setIsMobile(state, status) {
        state.isMobile = status;
    },

    setInputLoading(state, status) {
        state.inputLoading = status;
    },

    setMobileLinkStatus(state,status){
        state.mobileLinkStatus = status;
    },

    setCurrencies(state, status) {
        state.currencies = status;
    },

    setCurrentActiveRoute(state, status) {
        state.currentActiveRoute = status;
    },


    setCurrentCurrency(state, status) {
        state.currentCurrency = status;
    },


    setCountries(state, status) {
        state.countries = status;
    },

    setInterests(state, status) {
        state.interests = status;
    },
    setAffiliateData(state, status) {
        state.affiliateData = status;
    },

    setUploadedFileLink(state, status) {
        state.uploadedFileLink = status;
    },


    successMessage: (state, data) => {
        Vue.$toast(
            {
                component: ToastificationContent,
                props: {
                    title: i18n.t("success_messages." + data),
                },
            },
            {
                toastClassName: "success-class",
                position: "bottom-right",
            },
        );
        state.messages = "success";
    },

    failMessage: (state, data) => {
        state.messages = "error";
        Vue.$toast(
            {
                component: ToastificationContent,
                props: {
                    title: i18n.t("error_messages." + data),
                },
            },
            {
                toastClassName: "fail-class",

                position: "bottom-right",
            },
        );
    },


}