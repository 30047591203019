import {actions} from "./actions.js"
import {mutations} from "./mutations.js"
import {getters} from "./getters.js"
import {state} from "./state.js"
import bookings from "./bookings";

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules: {
        bookings
    },
}