export const actions = {
    async getLatestServices({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`services?page=${data.page}`, data)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setLatestServices", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getServiceDetail({commit}, slug) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`services/${slug}`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setServiceDetail", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getServiceComments({commit}, data) {
        // commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`services/${data.id}/reviews?page=${data.page}`)
            .then((respo) => {
                if (respo.status === 200) {
                   commit("setServiceComments", respo.data.data.content)
                    // commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                // commit("app/setLoading", false, {root: true})
                throw error
            })
    },


    async getServicesSuggestions({commit}) {
        // commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`services/search-suggestions`)
            .then((respo) => {
                if (respo.status === 200) {
                   commit("setServiceSuggestions", respo.data.data)
                    // commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                // commit("app/setLoading", false, {root: true})
                throw error
            })
    },


    async getFilteredServices({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`services`, {params: {filters:true,...data}})
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setServiceFilters", respo.data.data.filters)
                    commit("setFilteredServices", respo.data.data.services)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },
}

