export const getters = {
    getCurrentBookings: state => state.currentBookings,
    getCurrentPageCount: state => state.currentPageCount,

    getCompleteBookings: state => state.completeBookings,
    getCompletePageCount: state => state.completePageCount,

    getBookDetails: state => state.bookDetails,

    getComment: state => state.comment,

    getBooingCount: state => state.bookingCount,
}