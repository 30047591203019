<template>
  <div>

    <template v-if="bookComment===null">


      <!--         add your comment button-->
      <div class=" details-container" v-if="!showForm">
        <div class="details--heading">
          <h3>{{ $t("user.bookings.comment.add_comment") }}</h3>
        </div>
        <div class="details-divider">

        </div>
        <div class="px-3 ">
          <div class="btn btn-warning" @click="showCommentForm">{{ $t("btn.add_comment") }}</div>
        </div>
      </div>


      <!--         add your comment form-->
      <div class=" details-container" v-if="showForm">
        <div class="details--heading">
          <h3>{{ $t("user.bookings.comment.add_comment") }}</h3>
        </div>
        <div class="details-divider">

        </div>
        <div class="px-3 add-comment-form">
          <p class="text-reg-14 text-gray-3 mb-3">
            {{ $t("user.bookings.comment.text") }}
          </p>
          <div class="flex flex-col gap-3">

            <div class="input required">
              <label class="input--label">{{ $t("input.stars.title") }}</label>

              <symbol id="icon-star" viewBox="0 0 12 12">
                <path id="Path_147238" data-name="Path 147238" d="M13.649,7.759a1.271,1.271,0,0,0,.294-1.3A1.255,1.255,0,0,0,12.928,5.6l-2.576-.4a.084.084,0,0,1-.054-.048l-1.129-2.4a1.268,1.268,0,0,0-2.312-.018l-1.129,2.4a.1.1,0,0,1-.06.048L3.1,5.6a1.273,1.273,0,0,0-1.021.858,1.289,1.289,0,0,0,.3,1.3l1.9,1.95a.1.1,0,0,1,0,.078L3.83,12.51a1.273,1.273,0,0,0,1.88,1.326l2.24-1.242a.078.078,0,0,1,.072,0l2.24,1.242a1.279,1.279,0,0,0,1.88-1.326L11.727,9.8a.072.072,0,0,1,0-.072Z" transform="translate(-2.012 -2)"/>
              </symbol>

              <rate class="RateCustom large"
                    :length="5" :value="reviewForm.rate"
                    @after-rate="onAfterRate"
                    iconref="icon-star"/>
              <span class="input--error" v-if="error.rate.length>0">{{ error.rate }}</span>

            </div>

            <div class="input required">
              <label class="input--label">{{ $t("input.comment.title") }}</label>

              <div class="input--input" :class="error.comment.length>0?'error':''">
                  <textarea class="resize-no"
                            @input="textAreaInput"
                            :placeholder="$t('input.comment.placeholder')" required="required"
                            rows="5"></textarea>
              </div>
              <span class="input--error" v-if="error.comment.length>0">{{ error.comment }}</span>

            </div>
<!--
            <div>
              <label class="text-reg-14 text-gray-1">{{ $t("input.images.title") }}</label>
              <MultiUploadImages :max="maxUploadImages" clear-all="" @changed="uploadImages" @removed="removeImage"/>

            </div>-->
            <button class="btn btn-primary" @click="submitReview">{{ $t("btn.add_comment") }}</button>
          </div>


        </div>

        <div class="modal-overlay" v-if="commentSuccessfully">
          <div class="modal-content">
            <div class="icon">
              <modal-success-icon/>
            </div>
            <div class="title">{{ $t("user.bookings.comment.success-modal.title") }}</div>
            <div class="desc">{{ $t("user.bookings.comment.success-modal.desc") }}</div>
            <div class="btns">
              <router-link :to="{name:'home'}" class="btn btn-primary w-full">{{ $t("link.back-home") }}</router-link>
            </div>
          </div>
        </div>
      </div>

    </template>


    <!--         the comment-->
    <div class=" details-container" v-if="bookComment">
      <div class="details--heading">
        <h3>{{ $t("user.bookings.your_comment") }}</h3>
      </div>
      <div class="details-divider">

      </div>

      <div class="px-3 ">

        <comment :comment="bookComment"/>

      </div>
    </div>

  </div>
</template>

<script>

import Comment from "../../../../components/comment";
import rate from "vue-rate/src/Rate"
import "vue-rate/dist/vue-rate.css"

export default {
  name: "BookCommentSection",
  components: {Comment, rate},
  data() {
    return {
      maxUploadImages: 4,
      commentButton: true,
      commentSuccessfully: false,
      showForm: false,
      reviewForm: {
        rate: "",
        comment: "",
        images: [],
      },
      error: {
        rate: "",
        comment: "",
      },
      comment: null,
    }
  },
  props: {
    commentObject: {},
    serviceId: [Number, String],
  },
  computed: {
    bookComment: function () {
      if (this.commentObject) {
        return this.commentObject;
      } else if (this.comment) {
        return this.comment;
      }
      return null;
    },
    validate: function () {
      return this.error.rate.length === 0 && this.error.comment.length === 0;
    },
  },
  methods: {
    uploadImages(files) {


      const formData = new FormData();
      formData.append("file", files[0]);
      this.$store.dispatch("app/upload", formData)
          .then(() => {
                this.reviewForm.images.push(this.$store.getters["app/getUploadedFileLink"]);
              },
          )
    },

    removeImage(index) {
      this.reviewForm.images.splice(index, 1);
    },

    textAreaInput(e) {
      let value = e.target.value;
      this.reviewForm.comment = value;

      if (value.length > 0) {
        this.valid = true;
        this.error.comment = "";
      } else {
        this.valid = false;
        this.error.comment = this.$t("rules.required");
      }
    },

    onAfterRate(rate) {

      this.reviewForm.rate = rate;

      if (this.reviewForm.rate > 0) {
        this.valid = true;
        this.error.rate = "";
      } else {
        this.valid = false;
        this.error.rate = this.$t("rules.required");
      }
    },

    showCommentForm() {
      this.commentButton = false;
      this.showForm = true;
    },

    submitReview() {

      Object.keys(this.reviewForm).map(function (key) {

        if (this.reviewForm[key] != null && this.reviewForm[key].length === 0) {
          this.error[key] = this.$t("rules.required");
        } else {
          this.error[key] = "";
        }

      }.bind(this))

      if (!this.validate)
        return;


      this.$store.dispatch("user/bookings/sendReview", {id: this.serviceId, ...this.reviewForm})
          .then(() => {
                this.showForm = false;
                this.commentSuccessfully = true;
                this.comment = this.$store.getters["user/bookings/getComment"];
              },
          )
          .catch(error => {
                let inputErrors = error.response.data.data;
                Object.keys(inputErrors).map(function (key) {
                  this.error[key] = inputErrors[key][0];
                }.bind(this))
              },
          )


    },


  },


}
</script>