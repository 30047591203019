export const getters = {


    getLoading(state) {
        return state.loading
    },

    getIsMobile(state) {
        return state.isMobile
    },

    getAffiliateData(state) {
        return state.affiliateData
    },

    getInputLoading(state) {
        return state.inputLoading
    },

    getCurrencies(state) {
        return state.currencies;
    },
    getMobileAppLinkStatus(state) {
        return state.mobileLinkStatus;
    },

    getCurrentActiveRoute(state) {
        return state.currentActiveRoute;
    },

    getCurrentCurrency(state) {
        return state.currentCurrency;
    },

    getNotification404(state){
        return state.notification404;
    },


    getLanguageTest(state) {
        return state.language
    },

    getLanguage(state) {
        return state.language.locale
    },

    getCountries(state) {
        return state.countries
    },

    getInterests(state) {
        return state.interests
    },

    getUploadedFileLink(state) {
        return state.uploadedFileLink
    },
}