<template>
  <div>

    <div class="container mobile-container about">
      <PageHeading :title="$t('others.about')"/>


      <div class=" details-container mb-5">


        <div class="px-5 py-6 text-reg-14 text-gray-2">
          <div>
            <about-logo-icon/>
            <div class="flex items-center gap-1 mt-3">
              <location-fill-icon/>
              <div class="text-med-14 text-gray-1">
               {{$t('about.halabk')}}
              </div>
            </div>
          </div>
          {{ about.content }}
        </div>
      </div>

      <div class=" details-container mb-5">


        <div class="about-contact">
          <div>
            <h3 class="text-med-14 text-gray-3">{{$t('about.phones')}}</h3>
            <ul>
              <li v-for="(contact ,index) in about.contacts" :key="index">{{ contact }}</li>
            </ul>
          </div>
          <div>
            <h3 class="text-med-14 text-gray-3">{{$t('about.info')}} </h3>
            <ul>
              <li v-for="(contact ,index) in about.info_emails" :key="index">{{ contact }}</li>
            </ul>
          </div>
          <div>
            <h3 class="text-med-14 text-gray-3">{{$t('about.support')}} </h3>
            <ul>
              <li v-for="(contact ,index) in about.support_emails" :key="index">{{ contact }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="about-social">

        <a  target="_blank" :href="social.platform.base_url+social.username" class="icon-container" v-for="(social ,index) in about.social" :key="index">
          <img :src="social.platform.icon" :alt="social.platform.name"/>
        </a>

      </div>
    </div>

  </div>
</template>


<script>
import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: "AboutUs",

  components: {
    PageHeading,
  },

  methods: {

    getAbout() {
      this.$store.dispatch("others/getAbout")
    },
  },
  computed: {
    ...mapGetters({
      about: "others/getAbout",
    }),
  },
  created() {
    this.getAbout();
  },
}
</script>