export const getters = {
    getLatestServices(state) {
        return state.latestServices
    },
    getLatestPageCount(state) {
        return state.latestPageCount
    },

    getServices(state) {
        return state.services
    },
    getServicesPageCount(state) {
        return state.servicesPageCount
    },

    getServiceSuggestions(state) {
        return state.serviceSuggestions
    },

    getServiceDetail(state) {
        return state.serviceDetail
    },

    getServiceComments(state) {
        return state.serviceComments
    },


    getServiceFilters(state) {
        return state.serviceFilters
    },

    getFilteredServices(state) {
        return state.filteredServices
    },

    getTotalFilteredServices(state) {
        return state.totalFilteredServices
    },

    getFilteredPageCount(state) {
        return state.filteredPageCount
    },

}