<template>
  <div class="container mobile-container">
    <PageHeading :title='$t("blog.title")'/>
    <div class="articles-container " :class="articles.length===0 ? ' justify-center':''">

      <Nothing
          :class="'m-auto'"
          v-if="articles.length===0"
          :title="$t('nothing.articles.title')"
          :desc="$t('nothing.articles.desc')"
      />

      <div class="articles-grid pt-6" v-if="articles.length>0">

        <ArticleCard v-for="article in articles"
                     :article="article"
                     :key="article.id"
        />

      </div>

    </div>
    <paginate
        v-if="pageCount>1"
        v-model="page"
        class="mt-12"
        :page-count="pageCount"
        :page-range="4"
        :margin-pages="1"

        :prev-text="'<'"
        :next-text="'>'"

        :click-handler="paginationCallback"

        :container-class="'pagination'"
        :break-view-text="'...'"
        :page-class="'page-item'">
    </paginate>

  </div>
</template>

<script>
import paginate from "vuejs-paginate";
import PageHeading from "../../components/PageHeading";
import ArticleCard from "../../components/ArticleCard";
import {mapGetters} from "vuex";
import Nothing from "../../components/nothing";

export default {
  name: "Articles",
  components: {Nothing, ArticleCard, PageHeading, paginate},
  data() {
    return {
      page: this.$route.query.page ?? 1,
    }
  },
  methods: {
    paginationCallback(page) {
      this.page = page;

      this.$router.push({query: {page: this.page}})
      this.getArticles();
    },
    getArticles() {
      this.$store.dispatch("articles/getArticles", {page: this.page})
    },
  },
  computed: {
    ...mapGetters({
      articles: "articles/getArticles",
      pageCount: "articles/getPageCount",
    }),
  },
  created() {
    this.getArticles();
  },
}
</script>