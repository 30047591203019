export const state = {

    policy: {},
    terms: {},
    faqs: [],
    about: {},
    footer: {},

}






 
