export const actions = {
    async getArticles({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`articles?page=${data.page}`, data)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setArticles", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },

    async getDetail({commit}, slug) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.get(`articles/${slug}`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setDetail", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },
}