<template>
  <div :class="`${scrollClass}-place`">
    <div class="desktop-nav-bar " :class="`${scrollClass}  ${hide}`">

      <div :class="'top-primary ' + topClass " id="halabk-navbar">
        <div class=" w-full h-full">
          <img class="w-full pattern" src="@/assets/patterns/pattern_1.png" alt="Halabk pattern">
        </div>
      </div>
      <div class="py-4 container mobile-nav-container">

        <div class="flex justify-between items-center ">
          <router-link  :to="{name:'home'}">

            <logo-icon class="nav-logo"/>
          </router-link>

          <div class="flex gap-3">

            <DropDown class="h-show currency" :text="currencyTitle">
              <template v-slot:dropdown-menu>
                <div class="item" :class=" curr.title==currencyTitle?'active':''" v-for="curr in currencies"
                     :key="curr.id" @click="()=>changeCurrency(curr)">
                  <p class="name">{{ curr.title }}</p>
                </div>

              </template>
            </DropDown>

            <DropDown class="h-show" :text="$t(`language.${lang}`)">
              <language-icon/>
              <template v-slot:dropdown-menu>
                <div class="item" :class=" code==lang?'active':''" v-for="(code,index) in languages" :key="index"
                     @click="()=>changeLanguage(code)">
                  <p class="name">{{ $t(`language.${code}`) }}</p>
                </div>

              </template>
            </DropDown>
            <div class=" nav-box show-in-filter-page h-hide" @click="showMobileSearch" id="mobile-search-btn"
                 style="display: none !important;">
              <search2-icon/>
            </div>
            <div class=" nav-box show-in-filter-page h-hide" @click="showMobileFilter" id="mobile-filter-btn"
                 style="display: none !important;">
              <filter-icon/>
            </div>
            <router-link :to="`/${lang}/notifications`" class=" nav-box  hide-in-filter-page"
                         :class="!isLoggedIn?' box-hide':''">
              <notification-icon/>
            </router-link>
            <router-link :to="`/${lang}/chat`" class=" nav-box fill-white  hide-in-filter-page "
                         :class="!isLoggedIn?' box-hide':''">
              <chat-icon/>
            </router-link>

            <DropDown v-if="isLoggedIn" class="h-show p-1 max-180">
              <div class="flex items-center gap-3">

                <img :src="userInfo.image!=null?userInfo.image:''" :alt="userInfo.name" @error="replaceByDefault"
                     class="img w-9 h-9 border-radius-small"/>
                <div class="flex flex-col gap-2 text-reg-10 ">
                  <span class="leading-none max-1-lines max-80-chars ">{{ userInfo.name }}</span>
                  <span class="leading-none max-1-lines max-80-chars ">{{ userInfo.email }}</span>
                </div>
              </div>
              <template v-slot:dropdown-menu>
                <router-link :to="{name:'user-profile'}" class="item">
                  <div class="flex items-center gap-2">
                    <profile-settings-icon class="secondary"/>
                    <p class="name">{{ $t('user.nav.edit') }}</p>
                  </div>
                </router-link>
                <router-link :to="{name:'user-change-password'}" class="item">
                  <div class="flex items-center gap-2">
                    <profile-lock-icon class="secondary"/>
                    <p class="name">{{ $t('user.nav.password') }}</p>
                  </div>
                </router-link>
                <router-link :to="{name:'user-interests'}" class="item">
                  <div class="flex items-center gap-2">
                    <profile-interest-icon class="secondary"/>
                    <p class="name">{{ $t('user.nav.interests') }}</p>
                  </div>
                </router-link>
                <router-link :to="{name:'user-bookings'}" class="item">
                  <div class="flex items-center gap-2">
                    <profile-calendar-icon class="secondary"/>
                    <p class="name">{{ $t('user.nav.bookings') }}</p>
                  </div>
                </router-link>
                <router-link :to="{name:'user-favorites'}" class="item">
                  <div class="flex items-center gap-2">
                    <profile-heart-icon class="secondary"/>
                    <p class="name">{{ $t('user.nav.favorites') }}</p>
                  </div>
                </router-link>
                <div class="item red" @click="logoutModal=true">
                  <div class="flex items-center gap-2">
                    <profile-logout-icon class="secondary"/>
                    <p class="name">{{ $t('user.nav.logout') }}</p>
                  </div>
                </div>

              </template>
            </DropDown>

            <div v-if="!isLoggedIn" @click="toLogin"
                 class="block border border-white border-solid border-radius py-2 px-5 text-white text-reg-14 max-1-lines h-show cursor-pointer">
              {{ $t("nav.login") }}
            </div>

          </div>
        </div>

        <div class="navbar">
          <ul class="navbar-links">


            <router-link :to="{name:'home'}" :class="currentActiveRoute==='home'?'active':''">
              {{ $t("nav.home") }}
            </router-link>


            <router-link :to="{name:'destinations'}" :class="currentActiveRoute==='destinations'?'active':''">
              {{ $t("nav.discover") }}
            </router-link>

            <router-link :to="{name:'offers'}" :class="currentActiveRoute==='offers'?'active':''">
              {{ $t("nav.offers") }}
            </router-link>


            <router-link :to="{name:'services'}" :class="currentActiveRoute==='services'?'active':''">
              {{ $t("nav.latest-offers") }}
            </router-link>


            <router-link :to="{name:'articles'}" :class="currentActiveRoute==='articles'?'active':''">
              {{ $t("nav.blog") }}
            </router-link>


          </ul>


          <a href="https://halabkmobile.page.link/appLinks" target="_blank"
             class="px-4 py-2 text-white flex justify-between items-center gap-3">
            <phone-icon/>
            <h6 class="text-med-12">{{ $t("nav.download") }}</h6>
          </a>
        </div>

      </div>

    </div>



    <div class="mobile-download-app h-hide" :class="mobileLinkStatus===true?'':'hide'">
      <a href="https://halabkmobile.page.link/appLinks" target="_blank">
        <phone-icon/>
        <h6 class="text-med-12">{{ $t("nav.go_app") }}</h6>
      </a>
      <close-icon class="p-0.5 close-link" @click="closeLink"/>
    </div>

    <div class="navbar-bottom h-hide">
      <router-link :to="{name:'home'}" class="bottom-box" :class="currentActiveRoute==='home'?'active':''">
        <bottom-home-icon v-if="currentActiveRoute==='home'"/>
        <bottom-home-inactive-icon v-else/>
        <h1 class="name">{{ $t("nav.bottom.home") }}</h1>
      </router-link>

      <router-link :to="{name:'offers'}" class="bottom-box" :class="currentActiveRoute==='offers'?'active':''">
        <bottom-offer-icon v-if="currentActiveRoute==='offers'"/>
        <bottom-offer-inactive-icon v-else/>
        <h1 class="name">{{ $t("nav.bottom.offer") }}</h1>
      </router-link>
      <router-link :to="{name:'user-bookings'}" class="bottom-box"
                   :class="currentActiveRoute==='user-bookings'?'active':''">
        <bottom-bookings-icon v-if="currentActiveRoute==='user-bookings'"/>
        <bottom-bookings-inactive-icon v-else/>
        <h1 class="name">{{ $t("nav.bottom.bookings") }}</h1>
      </router-link>
      <router-link :to="{name:'others'}" class="bottom-box" :class="currentActiveRoute==='others'?'active':''">
        <bottom-more-icon v-if="currentActiveRoute==='others'"/>
        <bottom-more-inactive-icon v-else/>
        <h1 class="name">{{ $t("nav.bottom.more") }}</h1>
      </router-link>
    </div>


    <!--    **************************-->
    <!--    Modals ********************-->
    <!--    **************************-->
    <div class="modal-overlay" v-if="loginModal">
      <div class="modal-content bg-transparent modal-md">
        <div class="modal-tile mb-6" @click="modalToLogin">
          <div class="icon">
            <partners-user-login-icon/>
          </div>
          <div class="text">
            <h2 class="title">{{ $t("partners.login.user.title") }}</h2>
            <p class="desc">{{ $t("partners.login.user.desc") }}</p>
          </div>
          <div class="arrow">
            <partners-arrow-icon/>
          </div>
        </div>

        <div class="modal-tile" @click="toProvider">
          <div class="icon">
            <partners-provider-login-icon/>
          </div>
          <div class="text">
            <h2 class="title">{{ $t("partners.login.provider.title") }}</h2>
            <p class="desc">{{ $t("partners.login.provider.desc") }}</p>
          </div>
          <div class="arrow">
            <partners-arrow-icon/>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-overlay" v-if="logoutModal">
      <div class="modal-content">
        <div class="icon logout">
          <modal-logout-icon/>
        </div>
        <div class="title">
          {{ $t("user.logout.modal.title") }}
        </div>
        <div class="desc">
          {{ $t("user.logout.modal.desc") }}
        </div>
        <div class="btns">
          <div class="btn btn-danger w-full" @click="logout">{{ $t("btn.logout") }}</div>
          <div class="btn btn-gray-3 w-full" @click="logoutModal=false">{{ $t("btn.cancel") }}</div>
        </div>
      </div>
    </div>


  </div>


</template>

<script>

import DropDown from "./dropDown";
import {mapGetters} from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      loginModal: false,
      logoutModal: false,
      languages: ['ar', 'en'],
      scrollClass: "",
      hide: "",
      currencyTitle: "",
    }
  },
  props: {
    topClass: {
      type: String,
      default: " top-rounded",
    },
  },
  components: {
    DropDown,
  },
  computed: {
    ...mapGetters({
      currencies: "app/getCurrencies",
      mobileLinkStatus: "app/getMobileAppLinkStatus",
      isLoggedIn: "auth/getIsLoggedIn",
      userInfo: "user/getUserInfo",
      currentActiveRoute: "app/getCurrentActiveRoute",
    }),
    lang() {
      return this.$route.params.lang;
    },


  },
  async created() {
    await this.$store.dispatch("app/init")
    await this.$store.dispatch("auth/checkIsLoggedIn")
    if (this.isLoggedIn) {
      await this.$store.dispatch('user/getUserInfo');
    }
    this.currency_title();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    showMobileSearch() {
      document.getElementById('filter_search_container').classList.add('show-mobile')
    },
    showMobileFilter() {
      document.getElementById('filter_container').classList.add('show-mobile')
    },
    modalToLogin() {
      const lang = this.$route.params.lang;
      this.$router.push(`/${lang}/login`);
    },
    toLogin() {
      const showModal = this.$router.currentRoute.path.includes('partners');
      if (showModal) {
        this.loginModal = true;
      } else {
        this.modalToLogin();
      }
    },
    closeLink() {
      this.$store.dispatch("app/changeMobileLinkStatus", false);
    },
    toProvider() {

      window.location = "https://www.provider.halabk.sa/";
    },
    currency_title() {
      const code = localStorage.getItem("currency");
      if (code) {
        this.currencies.forEach(currency => {
          if (currency.code == code) {
            this.currencyTitle = currency.title;
            this.changeCurrency(currency, false);
          }
        });
      } else {
        this.changeCurrency(this.currencies[0]);
        this.currencyTitle = this.currencies[0].title;
      }
      // return this.currencies[0].title;
      // return this.$t(`currency.code.${code}`);
    },

    onScroll(e) {
      let position = e.target.documentElement.scrollTop;
      if (position > 480) {
        this.scrollClass = " navbar-fixed";
        this.hide = "";
      } else if (position > 350 && position < 480) {
        this.hide = " hide";
      } else if (position < 320) {
        this.scrollClass = "";
        this.hide = "";
      }
    },

    changeCurrency(currency, reload = true) {
      this.$store.dispatch("app/setCurrency", {currency: currency, reload: reload})
    },

    changeLanguage(lang) {
      if (this.languages.includes(lang)) {
        this.$store.dispatch("app/setLanguage", lang);

      }
    },

    async logout() {
      await this.$store.dispatch("auth/logout")
      this.logoutModal = false;
    }
  },
}
</script>

