<template>
  <div :class="`${scrollClass}-place`">
    <div class="desktop-nav-bar " :class="`${scrollClass}  ${hide}`">

      <div :class="'top-primary ' + topClass " id="halabk-navbar">
        <div class=" w-full h-full">
          <img class="w-full pattern" src="@/assets/patterns/pattern_1.png" alt="Halabk pattern">
        </div>
      </div>
      <div class="py-4 container mobile-nav-container">

        <div class="flex justify-between items-center ">
          <router-link :to="{name:'home'}">

            <logo-icon class="nav-logo"/>
          </router-link>

          <div class="flex gap-3">

            <DropDown class="h-show" :text="$t(`language.${lang}`)">
              <language-icon/>
              <template v-slot:dropdown-menu>
                <div class="item" :class=" code==lang?'active':''" v-for="(code,index) in languages" :key="index"
                     @click="()=>changeLanguage(code)">
                  <p class="name">{{ $t(`language.${code}`) }}</p>
                </div>

              </template>
            </DropDown>


          </div>
        </div>
        <div class="mt-24"></div>

      </div>

    </div>


  </div>


</template>

<script>


import DropDown from "@/components/dropDown";

export default {
  name: "navbar",
  components: {DropDown},
  data() {
    return {
      languages: ['ar', 'en'],
      scrollClass: "",
      hide: "",
    }
  },
  props: {
    topClass: {
      type: String,
      default: " top-rounded",
    },
  },

  computed: {

    lang() {
      return this.$route.params.lang;
    },


  },
  async created() {
    await this.$store.dispatch("app/init")
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {

    onScroll(e) {
      let position = e.target.documentElement.scrollTop;
      if (position > 480) {
        this.scrollClass = " navbar-fixed";
        this.hide = "";
      } else if (position > 350 && position < 480) {
        this.hide = " hide";
      } else if (position < 320) {
        this.scrollClass = "";
        this.hide = "";
      }
    },

    changeLanguage(lang) {
      if (this.languages.includes(lang)) {
        this.$store.dispatch("app/setLanguage", lang);

      }
    },

  },
}
</script>

