<template>
  <div class="profile-container">

    <div class=" details-container">
      <div class="details--heading">
        <h3>{{ $t('user.edit_account') }}</h3>
        <div
            @click="deleteModal=true;"
            class="delete bg-error flex justify-center items-center text-white p-2 border-radius-small text-reg-12 gap-2 cursor-pointer">
          <profile-trash-icon class="fill-white"/>
          {{ $t("btn.delete-account") }}
        </div>
      </div>
      <div class="details-divider"></div>

      <div class="details-content">
        <div class="flex flex-col gap-4">

          <upload-avatar class="mx-auto mb-2" :avatar="userInfo.image" @changeAvatar="(value)=>this.infoForm.image=value"/>

          <TextInput :required="true"
                     :label="$t('input.full-name.title')"
                     :placeholder="$t('input.full-name.placeholder')"
                     :rules="['required']"

                     v-model="userInfo.name"
                     :error="error.name"
                     @inputValue="(value)=>this.infoForm.name=value"
                     @isReady="(value) => this.valid=value">
            <template v-slot:prefix-icon>
              <person-icon/>
            </template>
          </TextInput>

          <TextInput :label='$t("input.phone.title")'
                     :placeholder='$t("input.phone.placeholder")'
                     :rules="['required','phone']"
                     v-model="userInfo.phone"
                     :error="error.phone"
                     @inputValue="(value)=>this.infoForm.phone=value"
                     @isReady="(value) => this.valid=value">
            <template v-slot:prefix-icon>
              <phone2-icon/>
            </template>
          </TextInput>


          <TextInput :required="true"
                     :label='$t("input.email.title")'
                     :placeholder='$t("input.email.placeholder")'
                     :rules="['required','email']"
                     v-model="userInfo.email"
                     :error="error.email"
                     @inputValue="(value)=>this.infoForm.email=value"
                     @isReady="(value) => this.valid=value">
            <template v-slot:prefix-icon>
              <envelope-fontawesome/>
            </template>
          </TextInput>

          <LoadingButton
              :btn-class="'text-center'"
              :name="$t('btn.save')"
              :loading="loading" class="w-full text-center mt-4"
              :on-click="()=>saveChanges()"
          />

        </div>
      </div>
    </div>

    <div class="modal-overlay" v-if="deleteModal">
      <div class="modal-content">
        <div class="icon logout">
          <modal-delete-icon/>
        </div>
        <div class="title">
          {{ $t("user.delete.modal.title") }}
        </div>
        <div class="desc">
          {{ $t("user.delete.modal.desc") }}
        </div>
        <div class="btns">
          <div class="btn btn-danger w-full" @click="deleteAccount">{{ $t("btn.delete") }}</div>
          <div class="btn btn-gray-3 w-full" @click="deleteModal=false">{{ $t("btn.cancel") }}</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TextInput from "../../components/inputs/text";
import LoadingButton from "../../components/buttons/loadingButton";
import {mapGetters} from "vuex";

import UploadAvatar from "@/components/uploadAvatar";

export default {
  name: "Profile",

  components: {LoadingButton, TextInput, UploadAvatar},

  data() {
    return {
      valid: true,
      deleteModal: false,

      error: {
        name: "",
        email: "",
        phone: "",
      },
    }
  },

  computed: {
    ...mapGetters({
      userInfo: "user/getUserInfo",
      countries: "app/getCountries",
      loading: "app/getInputLoading",
    }),
    validate: function () {
      return this.error.email.length === 0 && this.error.name.length === 0 &&
          this.valid;
    },

    infoForm: function () {
      return {
        name: this.userInfo.name,
        email: this.userInfo.email,
        phone: this.userInfo.phone,
      }
    }
  },

  methods: {


    saveChanges() {

      Object.keys(this.infoForm).map(function (key) {

        if (this.infoForm[key].length === 0) {
          this.error[key] = this.$t("rules.required");
        } else {
          this.error[key] = "";
        }

      }.bind(this))

      if (!this.validate)
        return;


      this.$store.dispatch("user/updateProfile", this.infoForm)
          .then(() => {
                // this.currentStep = next;
                // this.completeSteps.push(current)
              },
          )
          .catch(error => {
                let inputErrors = error.response.data.data;
                Object.keys(inputErrors).map(function (key) {
                  this.error[key] = inputErrors[key][0];
                }.bind(this))
              },
          )
    },

    deleteAccount() {
      this.$store.dispatch("user/deleteAccount")
    }
  },

  async created() {
    await this.$store.dispatch("user/getUserInfo")

  },

}
</script>