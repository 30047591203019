import Vue from "vue"

import logo from "./logo.svg";
import aboutLogo from "./about-logo.svg";
import footerLogo from "./footer-logo.svg";

import arrow from "./icons/arrow.svg";
import refresh from "./icons/refresh.svg";
import doubleArrow from "./icons/double-arrow.svg";
import circleArrow from "./icons/circle-arrow.svg";
import longArrow from "./icons/long-arrow.svg";
import phone from "./icons/phone.svg";
import phone2 from "./icons/phone-2.svg";
import language from "./icons/language.svg";
import calendarService from "./icons/service/service-calendar.svg";
import calendarOutline from "./icons/calendar-outline.svg";
import calendarOutlineSmall from "./icons/calendar-outline-small.svg";
import dropdownOutline from "./icons/dropdown-outline.svg";
import emailOutline from "./icons/email-outline.svg";
import email2 from "./icons/email2.svg";
import heartEmpty from "./icons/heart-empty.svg";
import locationFill from "./icons/location-fill.svg";
import filter from "./icons/filter.svg";
import locationOutline from "./icons/location-outline.svg";
import starFill from "./icons/star-fill.svg";
import dots from "./icons/dots.svg";
import send from "./icons/send.svg";

import home from "./icons/home.svg";
import chat from "./icons/chat.svg";
import notification from "./icons/notification.svg";
import search from "./icons/search.svg";
import search2 from "./icons/search2.svg";
import arrowDown from "./icons/arrow-down.svg";
import checkbox from "./icons/checkbox.svg";
import person from "./icons/person.svg";
import minus from "./icons/minus.svg";
import plus from "./icons/plus.svg";


import youtube2 from "./icons/social/v2/youtube-2.svg";
import twitter2 from "./icons/social/v2/twitter-2.svg";
import facebook2 from "./icons/social/v2/facebook-2.svg";
import insta2 from "./icons/social/v2/insta-2.svg";
import ticktok2 from "./icons/social/v2/tic-2.svg";
import snap2 from "./icons/social/v2/snap-2.svg";

import google from "./icons/social/google.svg";
import googleColor from "./icons/social/google-colored.svg";
import apple from "./icons/social/apple-logo.svg";
import youtube from "./icons/social/youtube.svg";
import twitter from "./icons/social/twitter.svg";
import facebook from "./icons/social/face.svg";

import linkedinBox from "./icons/social/linkedin-box.svg";
import whatsappBox from "./icons/social/whatsapp-box.svg";
import twitterBox from "./icons/social/twitter-box.svg";
import facebookBox from "./icons/social/facebook-box.svg";

import affiliateUsage from "./icons/affiliate/usage.svg";
import affiliateSales from "./icons/affiliate/sales.svg";
import affiliateProfit from "./icons/affiliate/profit.svg";


import nothing from "./icons/nothing.svg";

import heart from "./icons/heart.svg";
import share from "./icons/share.svg";
import close from "./icons/close.svg";
import imagePlaceHolder from "./icons/imagePlaceHolder.svg";
import serviceBaggage from "./icons/service/baggage.svg";
import servicePerson from "./icons/service/person.svg";
import serviceSupervisor from "./icons/service/superviser.svg";
import serviceTime from "./icons/service/time.svg";
import serviceQuestion from "./icons/service/question.svg";
import serviceCoupon from "./icons/service/coupon.svg";
import serviceTrash from "./icons/service/trash.svg";
import profileSettings from "./icons/profile/settings.svg";
import profileHeart from "./icons/profile/heart.svg";
import profileLock from "./icons/profile/lock.svg";
import profileCalendar from "./icons/profile/calendar.svg";
import profilePayment from "./icons/profile/payment.svg";
import profileLogout from "./icons/profile/logout.svg";
import profileInterest from "./icons/profile/interest.svg";
import profileClock from "./icons/profile/clock.svg";
import profileBell from "./icons/profile/bell.svg";
import profileTrash from "./icons/profile/trash.svg";

import paymentVisa from "./icons/profile/visa.svg";
import paymentMasterCard from "./icons/profile/mastercard.svg";

import modalLogout from "./icons/profile/logout-lg.svg";
import modalSuccess from "./icons/profile/successs.svg";
import modalDelete from "./icons/profile/delete.svg";
import interests1 from "./icons/interests/1.svg";
import bottomHome from "./icons/bottom/home.svg";
import bottomMore from "./icons/bottom/more.svg";
import bottomOffers from "./icons/bottom/offers.svg";
import bottomBookings from "./icons/bottom/bookings.svg";

import bottomInactiveHome from "./icons/bottom/home_inactive.svg";
import bottomInactiveMore from "./icons/bottom/more_inactive.svg";
import bottomInactiveOffers from "./icons/bottom/offers_inactive.svg";
import bottomInactiveBookings from "./icons/bottom/bookings_inactive.svg";

// partners
import partnersData from "./icons/partners/data.svg";
import partnersPackage from "./icons/partners/package.svg";
import partnersArrow from "./icons/partners/arrow.svg";
import partnersUser from "./icons/partners/user.svg";
import partnersUserLogin from "./icons/partners/login-user.svg";
import partnersProviderLogin from "./icons/partners/login-provider.svg";

// mobile
import mobileInterests from "./icons/mobile/interests.svg";
import mobileFavorites from "./icons/mobile/favorites.svg";
import mobilePayment from "./icons/mobile/payment.svg";
import mobileComingBookings from "./icons/mobile/coming-bookings.svg";
import mobileCompleteBookings from "./icons/mobile/complete-bookings.svg";
import mobileSettings from "./icons/mobile/settings.svg";
import mobileAbout from "./icons/mobile/about.svg";
import mobileLaw from "./icons/mobile/law.svg";
import mobileContact from "./icons/mobile/contact.svg";
import mobileFaqs from "./icons/mobile/faqs.svg";
import mobileArrow from "./icons/mobile/arrow.svg";
import mobileLogout from "./icons/mobile/logout.svg";
import mobileBack from "./icons/mobile/back.svg";
import mobileShare from "./icons/mobile/share.svg";

Vue.component("refresh-icon", refresh)
Vue.component("nothing-icon", nothing)

Vue.component("youtube2-icon", youtube2)
Vue.component("twitter2-icon", twitter2)
Vue.component("facebook2-icon", facebook2)
Vue.component("insta2-icon", insta2)
Vue.component("ticktok2-icon", ticktok2)
Vue.component("snap2-icon", snap2)

Vue.component("apple-icon", apple)
Vue.component("logo-icon", logo)
Vue.component("about-logo-icon", aboutLogo)
Vue.component("footer-logo-icon", footerLogo)

Vue.component("arrow-icon", arrow)
Vue.component("double-arrow-icon", doubleArrow)
Vue.component("circle-arrow-icon", circleArrow)
Vue.component("long-arrow-icon", longArrow)
Vue.component("language-icon", language)
Vue.component("phone-icon", phone)
Vue.component("phone2-icon", phone2)
Vue.component("calendar-service-icon", calendarService)
Vue.component("calendar-outline-icon", calendarOutline)
Vue.component("calendar-small-icon", calendarOutlineSmall)
Vue.component("dropdown-outline-icon", dropdownOutline)
Vue.component("email-outline-icon", emailOutline)
Vue.component("email2-icon", email2)
Vue.component("filter-icon", filter)
Vue.component("location-outline-icon", locationOutline)
Vue.component("location-fill-icon", locationFill)
Vue.component("star-fill-icon", starFill)
Vue.component("heart-empty-icon", heartEmpty)
Vue.component("dots-icon", dots)
Vue.component("send-icon", send)

Vue.component("home-icon", home)
Vue.component("chat-icon", chat)
Vue.component("notification-icon", notification)
Vue.component("search-icon", search)
Vue.component("search2-icon", search2)
Vue.component("arrow-down-icon", arrowDown)
Vue.component("checkbox-icon", checkbox)
Vue.component("person-icon", person)
Vue.component("minus-icon", minus)
Vue.component("plus-icon", plus)


Vue.component("google-icon", google)
Vue.component("google-color-icon", googleColor)
Vue.component("youtube-icon", youtube)
Vue.component("twitter-icon", twitter)
Vue.component("facebook-icon", facebook)

Vue.component("twitter-box-icon", twitterBox)
Vue.component("facebook-box-icon", facebookBox)
Vue.component("linkedin-box-icon", linkedinBox)
Vue.component("whatsapp-box-icon", whatsappBox)

Vue.component("heart-icon", heart)
Vue.component("share-icon", share)
Vue.component("close-icon", close)
Vue.component("image-placeholder-icon", imagePlaceHolder)

// Service detail icons and bookings


Vue.component("service-baggage-icon", serviceBaggage)
Vue.component("service-person-icon", servicePerson)
Vue.component("service-supervisor-icon", serviceSupervisor)
Vue.component("service-time-icon", serviceTime)
Vue.component("service-question-icon", serviceQuestion)
Vue.component("service-coupon-icon", serviceCoupon)
Vue.component("service-trash-icon", serviceTrash)


// profile icons


Vue.component("profile-settings-icon", profileSettings)
Vue.component("profile-heart-icon", profileHeart)
Vue.component("profile-lock-icon", profileLock)
Vue.component("profile-calendar-icon", profileCalendar)
Vue.component("profile-payment-icon", profilePayment)
Vue.component("profile-logout-icon", profileLogout)
Vue.component("profile-interest-icon", profileInterest)
Vue.component("profile-clock-icon", profileClock)
Vue.component("profile-bell-icon", profileBell)
Vue.component("profile-trash-icon", profileTrash)


Vue.component("payment-visa-icon", paymentVisa)
Vue.component("payment-mastercard-icon", paymentMasterCard)

Vue.component("modal-logout-icon", modalLogout)
Vue.component("modal-success-icon", modalSuccess)
Vue.component("modal-delete-icon", modalDelete)


// interests icons

Vue.component("interests-1-icon", interests1)


// bottom icons

Vue.component("bottom-home-icon", bottomHome)
Vue.component("bottom-more-icon", bottomMore)
Vue.component("bottom-offer-icon", bottomOffers)
Vue.component("bottom-bookings-icon", bottomBookings)
Vue.component("bottom-home-inactive-icon", bottomInactiveHome)
Vue.component("bottom-more-inactive-icon", bottomInactiveMore)
Vue.component("bottom-offer-inactive-icon", bottomInactiveOffers)
Vue.component("bottom-bookings-inactive-icon", bottomInactiveBookings)

// partners icons

Vue.component("partners-user-icon", partnersUser)
Vue.component("partners-user-login-icon", partnersUserLogin)
Vue.component("partners-provider-login-icon", partnersProviderLogin)
Vue.component("partners-package-icon", partnersPackage)
Vue.component("partners-data-icon", partnersData)
Vue.component("partners-arrow-icon", partnersArrow)

// mobile


Vue.component("mobile-interests-icon", mobileInterests)
Vue.component("mobile-favorites-icon", mobileFavorites)
Vue.component("mobile-payment-icon", mobilePayment)
Vue.component("mobile-coming-bookings-icon", mobileComingBookings)
Vue.component("mobile-complete-bookings-icon", mobileCompleteBookings)


Vue.component("mobile-settings-icon", mobileSettings)
Vue.component("mobile-about-icon", mobileAbout)
Vue.component("mobile-law-icon", mobileLaw)
Vue.component("mobile-contact-icon", mobileContact)
Vue.component("mobile-faqs-icon", mobileFaqs)
Vue.component("mobile-arrow-icon", mobileArrow)
Vue.component("mobile-logout-icon", mobileLogout)
Vue.component("mobile-back-icon", mobileBack)
Vue.component("mobile-share-icon", mobileShare)


//affiliate

Vue.component("affiliate-usage-icon", affiliateUsage)
Vue.component("affiliate-sales-icon", affiliateSales)
Vue.component("affiliate-profit-icon", affiliateProfit)