<template>
  <div id="app">
    <navbar :top-class="topClass"/>
    <router-view/>
    <div class="flex gap-2 justify-center text-reg-15 text-gray-1 py-3" id="layout_2_copywrites">
      <p>{{ $t('footer.copyrights') }} </p>
      <p class="text-primary">{{ $t('footer.platform') }}</p>
    </div>
  </div>
</template>

<script>
import Navbar from "../../components/navbar";

export default {
  name: "Layout2",
  props: {
    topClass: {type: String, default: ""},
  },
  components: {
    Navbar,
  },
}
</script>
