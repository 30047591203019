export const state = {
    currentBookings: [],
    currentPageCount: 0,

    completeBookings: [],
    completePageCount: 0,

    bookDetails: {
        "id": 0,
        "package_price": 0,
        "discount_percent": 0,
        "coupon_discount_percent": 0,
        "tax": 0,
        "cancel_reason": null,
        "name": "",
        "phone": "",
        "second_phone": null,
        "email": "",
        "status": 0,
        "created_at": "",
        "booked_at": "",
        "canceled_at": null,
        "country": "",
        "people_count": 0,
        "service": {
            "id": 0,
            "service_id": 0,
            "title": "",
            "slug": "",
            "image": "",
            "city": "",
            "country": "",
            "status": 0,
            "booked_at": "",
            "created_at": "",
        },
        "provider": {
            "id": 0,
            "name": "",
            "image": "",
            "slug": "",
            "rate": 0,
        },
        "service_counters": [
            {
                "id": 0,
                "title": "",
                "value": 0,
                "price": 0,
            },
        ],
        "service_packages": {
            "id": 0,
            "title": "",
            "additional_price": 0,
        },
        "commnet": null,
    },
    comment: {},

    bookingCount: {coming: 0, complete: 0}
}
