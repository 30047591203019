export const actions = {

    async getDestinations({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`destinations?page=${data.page}`, data)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setDestinations", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

    async getDestinationDetail({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.get(`destinations/${data.slug}`)
            .then((respo) => {
                if (respo.status === 200) {


                    commit("setDestinationDetail", respo.data.data)

                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

}

