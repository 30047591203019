
<template>

  <router-link :to="link" class="booking-card">
    <div class="booking-card-header">
      <div class="flex items-center gap-1">
        <div class="title">  {{ $t("user.bookings.no") }}</div>
        <span class="no">#{{ book.id }}</span>
      </div>

      <time class="date">{{ book.booked_at | moment("LL") }}</time>
    </div>

    <div class="booking-card-content">
      <img class="image" :src="book.image" alt="">
      <div class="text">
        <div class="top">
          <h2 class="title">{{ book.title }}</h2>
          <div class="location">
            <location-fill-icon/>
            {{ book.city }}, {{ book.country }}
          </div>
        </div>
        <div class="bottom">
          <div class="flex items-center gap-2">
            <div class="icon-box">
              <calendar-small-icon/>
            </div>
            <div class="title">  {{ $t("user.bookings.status") }}</div>
            <div :class="'status '.concat(getStatusClass)"> {{ getStatus }}</div>
          </div>
          <router-link :to="link" class="show-more">
            {{ $t("link.show-more") }}
            <arrow-icon class="rotate-90"/>
          </router-link>

        </div>
      </div>
    </div>
  </router-link>

</template>

<script>

export default {
  name: "BookCard",
  props: {
    book: {},
    statusCode: {
      type: Number,
      default: 0,
    },
    serviceLink: {
      type: Boolean,
      default: false,
    },

  },

  computed: {
    link() {
      let lang = this.$route.params.lang;
      if (this.serviceLink) {
      return "/" + lang + "/services/" + this.book.slug;
      }
      return "/" + lang + "/profile/bookings/" + this.book.id;
    },
    getStatus: function () {
      switch (this.statusCode) {
        case 0:
          return this.$t('status.booked');
        case 1:
          return this.$t('status.active');
        case 2:
          return this.$t('status.complete');
        default:
          return this.$t('status.canceled');
      }
    },
    getStatusClass: function () {
      switch (this.statusCode) {
        case 0:
          return "pending";
        case 1:
          return "active";
        case 2:
          return "completed";
        default:
          return "canceled";
      }
    },
  },
}
</script>