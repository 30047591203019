export const mutations = {

    setHomeContent: (state, data) => {
        state.slider = data.slider;
        state.services = data.services;
        state.latestServices = data.latest_services;
        state.destinations = data.destinations;
        state.articles = data.articles;
    },

}