export const state = {
    articles: [],
    pageCount: 1,

    detail: {
        id: "",
        title: "",
        related:[]
    },
}






 
