<template>
  <div class="container  mobile-container">
    <RegistrationMobileNav :title="$t('forget-password.title')" :desc="$t('forget-password.desc')"/>

    <div class="forget-password">
      <div class="forget-password-form  ">

        <div class="login-nav hav-bg-height"></div>
        <h2 class="title h-show">{{ $t("forget-password.title") }}</h2>
        <small class="desc h-show">{{ $t("forget-password.desc") }}</small>

        <form class="mt-8 flex flex-col gap-4" @submit.prevent="submit()">

          <TextInput
              :label='$t("input.email.title")'
              :placeholder='$t("input.email.placeholder")'
              :rules="['required','email']"
              :error="error.email"
              @inputValue="(value)=>this.email=value"
              @isReady="(value) => this.valid=value"
          >
            <template v-slot:prefix-icon>
              <envelope-fontawesome/>
            </template>
          </TextInput>


          <button class="btn btn-primary  btn-white" :disabled="!enableSubmit || loading ">
            <div class="text-center" v-if="!loading">
              <div>{{ $t("btn.send") }}</div>
            </div>
            <Loading :loading="loading" :color="'#fff'" :size="'14px'" class="flex justify-center py-1"></Loading>
          </button>

        </form>

      </div>
    </div>
    <div class="h-full" style="height: 190px;"></div>
  </div>
</template>

<script>
import TextInput from "../../../components/inputs/text";

import Loading from "vue-spinner/src/PulseLoader"
import {mapGetters} from "vuex";
import RegistrationMobileNav from "../components/registration-mobile-nav";

export default {
  name: "ForgetPassword",
  components: {RegistrationMobileNav, Loading, TextInput},
  data: () => ({
    valid: true,
    email: "",
    enableSubmit: true,
    error: {email: ""},
  }),
  computed: {
    ...mapGetters({
      loading: "app/getInputLoading",
    }),
    validate: function () {
      return this.error.email.length === 0 && this.valid;
    },
  },

  methods: {
    submit() {
      if (this.email.length === 0) {
        this.error.email = this.$t("rules.required");
      } else this.error.email = ""

      if (this.validate) {
        this.$store.dispatch("auth/forgetPassword", {
          email: this.email,
        }).catch((error) => {

          if (error.response.status === 405) {
            this.$store.commit("app/failMessage", 'have_provider');
          }

        });
      }
    },
  },
  created() {
    this.toggleMobileBottomBar(true);
    this.toggleMobileCopyright(true);
    setTimeout(() => {

      this.toggleMobileBottomBar(true);
      this.toggleMobileCopyright(true);
    }, 10);

  },

  beforeDestroy() {
    this.toggleMobileBottomBar(false)
    this.toggleMobileCopyright(false)
  }

}
</script>