<template>
  <div>

    <PageHeading :paths="[{title: $t('nav.our-offers'),link:'../offers'}]" :show-back="false"
                 :class="'home h-hide'"
                 :show-text="false">

      <template v-slot:text :class="scrollClass">
        <div class="home-heading">
          <h3 class="home-title">{{ $t('home.title') }}</h3>
          <p class="home-desc">{{ $t('home.desc') }}</p>
        </div>
      </template>


    </PageHeading>

    <hero-section class="mb-16">
      <SwiperSlide v-for="slide in slider" :key="slide.id">
        <img :src="slide.media" alt="image" class="home-slider-hero-img"/>
      </SwiperSlide>
    </hero-section>


    <home-slider :title="$t('home.special-offers')"
                 link="offers" class="home-slider home-slider-offers"
                 :length="services.length" nothing_key="services" v-if="services.length>0">

      <SwiperSlide v-for="service in services" :key="service.id">
        <ServiceCard :service="service"/>
      </SwiperSlide>

    </home-slider>


    <home-slider :slides="4" :title="$t('home.destinations')"
                 link="destinations" class="home-slider home-slider-destination"
                 :length="destinations.length" nothing_key="destinations">

      <SwiperSlide v-for="destination in destinations" :key="destination.id">
        <DestinationCard :destination="destination"/>
      </SwiperSlide>

    </home-slider>


    <work-section/>

    <offers-section :services="latestServices"/>


    <discover-section/>

    <about-section class="h-show"/>


    <home-slider :title="$t('home.articles')" link="articles" class="home-articles home-slider home-slider-articles"
                 v-if="articles.length>0">

      <SwiperSlide v-for="article in articles" :key="article.id">
        <ArticleCard :article="article"/>
      </SwiperSlide>

    </home-slider>

    <!--    <partners-section/>-->

  </div>
</template>

<script>
import HeroSection from "./sections/Hero"
import ServiceCard from "../../components/ServiceCard.vue"

import {SwiperSlide} from "vue-awesome-swiper"

import WorkSection from "./sections/work";
import AboutSection from "./sections/about";
import DiscoverSection from "./sections/discover";
// import PartnersSection from "./sections/partners";
import HomeSlider from "./components/slider";
import OffersSection from "./sections/offers";
import ArticleCard from "../../components/ArticleCard";
import DestinationCard from "../../components/DestinationCard";
import {mapGetters} from "vuex";
import PageHeading from "../../components/PageHeading";

export default {
  name: "Home",
  components: {
    PageHeading,
    DestinationCard,
    ArticleCard,
    OffersSection,
    HomeSlider,
    // PartnersSection,
    DiscoverSection,
    AboutSection,
    WorkSection,
    SwiperSlide,
    HeroSection,
    ServiceCard,
  },
  data() {
    return {
      scrollClass: ''
    }
  },
  computed: {
    ...mapGetters({
      slider: "home/getSlider",
      services: "home/getServices",
      destinations: "home/getDestinations",
      articles: "home/getArticles",
      latestServices: "home/getLatestServices",
    }),
  },
  methods: {

    onScroll(e) {
      if (this.isMobile) {
        let position = e.target.documentElement.scrollTop;
        console.log('position:', position)
        if (position > 480) {
          this.scrollClass = " service-heading ";
        } else if (position < 320) {
          this.scrollClass = "h-show";
        }
      }
    },
  },
  created() {
    this.$store.dispatch("home/getHomeContent")
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll)
  }
}
</script>
