<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="$t('others.policy')"/>

      <div class="pt-4 page-container">

        <div class="page-content" v-html="policy.content">

        </div>

      </div>
    </div>

  </div>
</template>


<script>
import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";

export default {
  name: "PrivacyPolicy",

  components: {
    PageHeading,
  },
  methods: {

    getPolicy() {
      this.$store.dispatch("others/getPolicy")
    },
  },
  computed: {
    ...mapGetters({
      policy: "others/getPolicy",
    }),
  },
  created() {
    this.getPolicy();
  },
}
</script>