<template>
  <div class="accordion" :class="{ 'not-expanded': !this.expanded }">

    <header class="accordion-header" @click="toggleCardState">
      <p v-if="title" class="accordion-header-title">
        {{ title }}
      </p>
      <a v-if="title"  class="accordion-header-icon">
        <arrow-down-icon/>
      </a>
      <slot name="header"/>

    </header>

    <div class="accordion-content" ref="accordion-content">
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: ["title", "expandAll"],
  data() {
    return {
      "expanded": false,
    }
  },

  methods: {
    toggleCardState() {
      this.$refs["accordion-content"].style.getPropertyValue("height") === "0px" ? this.$refs["accordion-content"].style.height = this.$refs["accordion-content"].scrollHeight + "px" : this.$refs["accordion-content"].style.height = "0px";

      this.expanded = !this.expanded;
    },
    showMenu() {
      this.$refs["accordion-content"].style.getPropertyValue("height") === "0px" ? this.$refs["accordion-content"].style.height = this.$refs["accordion-content"].scrollHeight + "px" : this.$refs["accordion-content"].style.height = "0px";
      // this.$refs["accordion-content"].style.height = this.$refs["accordion-content"].scrollHeight + "px";
    },
    hideMenu() {
      this.$refs["accordion-content"].style.height = "0px";
    },
  },
  
  mounted() {
    this.$refs["accordion-content"].offsetHeight;
    this.$refs["accordion-content"].style.height = "0px";
  },

}
</script>