<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="$t('contact-us.title')" class="mb-12"/>

      <div class="contact-us">

        <div class=" details-container mb-5 contact-form ">
          <div class="details--heading">
            <h3>{{ $t("contact-us.desc") }}</h3>
          </div>
          <div class="details-divider"></div>

          <div class="px-4 ">

            <form @submit.prevent="send" ref="contactForm" class="flex flex-col gap-4">
              <TextInput :required="true"
                         :label="$t('input.full-name.title')"
                         :placeholder="$t('input.full-name.placeholder')"
                         :rules="['required']"
                         :error="error.name"
                         @inputValue="(value)=>this.contactForm.name=value"
                         @isReady="(value) => this.valid=value">
                <template v-slot:prefix-icon>
                  <person-icon/>
                </template>
              </TextInput>

              <TextInput :required="true" :label="$t('input.phone.title')"
                         :placeholder="$t('input.phone.placeholder')" :rules="['required']"
                         :error="error.phone"
                         @inputValue="(value)=>this.contactForm.phone=value"
                         @isReady="(value) => this.valid=value">
                <template v-slot:prefix-icon>
                  <phone2-icon/>
                </template>
              </TextInput>

              <TextInput :required="true" :label="$t('input.message-subject.title')"
                         :placeholder="$t('input.message-subject.placeholder')" :rules="['required']"
                         :error="error.subject"
                         @inputValue="(value)=>this.contactForm.subject=value"
                         @isReady="(value) => this.valid=value">
                <template v-slot:prefix-icon>
                  <email2-icon/>
                </template>
              </TextInput>

              <div class="input required ">
                <label class="input--label">{{ $t("input.message-content.title") }}</label>
                <div class="input--input  start " :class="error.message.length>0?'error':''">
                  <email2-icon/>
                  <textarea
                      @input="textAreaInput"
                      class="resize-no"
                      :placeholder="$t('input.message-content.placeholder')"
                      required="required"
                      rows="5"></textarea>
                </div>
                <span class="input--error" v-if="error.message.length>0">{{ error.message }}</span>

              </div>
              <LoadingButton
                  :btn-class="'text-center'"
                  :loading="loading"
                  :name="$t('btn.send')"
                  :on-click="()=>{}"
                  type="submit"
              />
            </form>
          </div>
        </div>
      </div>
    </div>


    <div class="modal-overlay" v-if="completeModal">
      <div class="modal-content">
        <div class="icon">
          <modal-success-icon/>
        </div>
        <div class="title">{{ $t("contact-us.success-modal.title") }}</div>
        <div class="desc">
          {{ $t("contact-us.success-modal.desc") }}
        </div>
        <div class="btns">
          <router-link :to="{name:'home'}" class="btn btn-primary w-full">{{ $t("link.back-home") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PageHeading from "../../components/PageHeading";
import TextInput from "../../components/inputs/text";
import {mapGetters} from "vuex";
import LoadingButton from "../../components/buttons/loadingButton";

export default {
  name: "ContactUs",

  components: {
    LoadingButton,
    TextInput,
    PageHeading,
  },

  data() {
    return {
      valid: true,

      completeModal: false,

      contactForm: {name: "", phone: "", subject: "", message: ""},

      error: {name: "", phone: "", subject: "", message: ""},
    }
  },

  computed: {
    ...mapGetters({
      loading: "app/getInputLoading",
    }),
    validate: function () {
      return this.error.name.length === 0 && this.error.phone.length === 0
          && this.error.subject.length === 0 && this.error.message.length === 0
          && this.valid;
    },
  },

  methods: {
    textAreaInput(e) {
      let value = e.target.value;
      this.contactForm.message = value;

      if (value.length > 0) {
        this.valid = true;
        this.error.message = "";
      } else {
        this.valid = false;
        this.error.message = this.$t("rules.required");
      }
    },
    send() {


      Object.keys(this.contactForm).map(function (key) {

        if (this.contactForm[key].length === 0) {
          this.error[key] = this.$t("rules.required");
        } else {
          this.error[key] = "";
        }

      }.bind(this))

      if (!this.validate)
        return;

      this.$store.dispatch("others/sendContact", this.contactForm)
          .then(() => {
            this.completeModal = true;

            this.contactForm = {name: "", phone: "", subject: "", message: ""};
            this.$refs.contactForm.reset(); // This will clear that form


            setTimeout(() => {
              this.completeModal = false;
            }, 3000);
          })
          .catch((error) => {
            let inputErrors = error.response.data.data;
            Object.keys(inputErrors).map(function (key) {
              this.error[key] = inputErrors[key][0];
            }.bind(this))
          });
    },
  },
}
</script>