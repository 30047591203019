export const actions = {

    async getServiceBookInfo({commit}, slug) {
        commit("app/setLoading", true, {root: true})

        await this._vm.$http.post(`services/${slug}/bookInfo`)
            .then((respo) => {
                if (respo.status === 200) {

                    commit("setBookInfo", respo.data.data)
                    commit("app/setLoading", false, {root: true})

                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                throw error
            })
    },


    async setBookData({commit}, data) {
        commit("setBookings", data)
    },

    async setFirstStep({commit}, data) {
        commit("setFirstStep", data)
    },

    async checkCoupon({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.post(`services/${data.id}/coupon`, data)
            .then((respo) => {
                if (respo.status === 200) {
                    commit("setCoupon", respo.data.data)
                    commit("app/setLoading", false, {root: true})
                }
                return respo.data;
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})
                return Promise.reject(error);
            })
    },

    async bookService({commit}, data) {
        commit("app/setLoading", true, {root: true})
        await this._vm.$http.post(`services/${data.id}/book`, data)
            .then((respo) => {
                if (respo.status === 200) {
                    commit("setPaymentInfo", respo.data.data)
                    commit("app/setLoading", false, {root: true})
                }
            })
            .catch((error) => {
                commit("app/setLoading", false, {root: true})

                return Promise.reject(error);
            })
    },

    async bookModal({commit}, data) {
        commit("setBookModal", data)

        commit("setPaymentInfo", {
            id: 0,
            payment_url: '',
            success_url: '',
            failed_url: 'http://hlabk.test/api/ar/payment/failed'
        })
    },
}