<template>
  <div class="container destinations mobile-container">
    <PageHeading :title='$t("destinations.title")'/>
    <h3 class="destinations-count h-show">{{ destinationsCount }} {{ $t("destinations.available-city") }}</h3>


    <Nothing class="destinations-container"
        v-if="destinations.length===0"
        :title="$t('nothing.destinations.title')"
        :desc="$t('nothing.destinations.desc')"
    />

    <template v-else>

    <div class="destinations-grid">

      <DestinationCard
          v-for="destination in destinations"
          :key="destination.id"
          :destination="destination"
      />


    </div>


    <paginate
        v-if="pageCount>1"
        v-model="page"
        class="mt-12"
        :page-count="pageCount"
        :page-range="4"
        :margin-pages="1"

        :prev-text="'<'"
        :next-text="'>'"

        :click-handler="paginationCallback"

        :container-class="'pagination'"
        :break-view-text="'...'"
        :page-class="'page-item'">
    </paginate>

    </template>


  </div>
</template>

<script>
import DestinationCard from "../../components/DestinationCard";
import paginate from "vuejs-paginate";
import PageHeading from "../../components/PageHeading";
import {mapGetters} from "vuex";
import Nothing from "../../components/nothing";

export default {
  name: "Destinations",
  components: {Nothing, PageHeading, DestinationCard, paginate},
  data() {
    return {
      page: this.$route.query.page ?? 1,
      tab: 1,
      deleteModal: false,
    }
  },

  methods: {
    paginationCallback(page) {
      this.page = page;

      this.$router.push({query: {page: this.page}})
      this.getDestinations();
    },
    getDestinations() {
      this.$store.dispatch("destinations/getDestinations", {page: this.page})
    },

  },
  computed: {
    ...mapGetters({
      destinations: "destinations/getDestinations",
      destinationsCount: "destinations/getDestinationsCount",
      pageCount: "destinations/getPageCount",
    }),
  },
  created() {
    this.getDestinations();
  },
}
</script>