<template>
  <div class="container  mobile-container">
    <RegistrationMobileNav :title="$t('reset-password.title')" :desc="$t('reset-password.desc')"/>

    <div class="forget-password">
      <div class="forget-password-form  ">


        <div class="login-nav hav-bg-height"></div>
        <h2 class="title h-show">{{ $t("reset-password.title") }}</h2>
        <small class="desc h-show">{{ $t("reset-password.desc") }}</small>

        <form class="mt-8 flex flex-col gap-4" @submit.prevent="submit()">


          <TextInput
              :label='$t("input.new-password.title")'
              :placeholder='$t("input.new-password.placeholder")'
              :type="showPassword ? 'text' : 'password'"
              :rules="['required','password']"
              :error="this.error.password"
              @inputValue="(value)=>this.password=value"
              @isReady="(value) => this.valid=value"
          >
            <template v-slot:suffix-icon>
              <eye-fontawesome @click="showPassword=!showPassword"/>
            </template>
          </TextInput>

          <TextInput
              :label='$t("input.confirm-password.title")'
              :placeholder='$t("input.confirm-password.placeholder")'
              :type="showConfirmPassword ? 'text' : 'password'"
              :rules="['required','password','confirm']"
              :confirm="this.password"
              :error="this.error.confirmPassword"
              @inputValue="(value)=>this.confirmPassword=value"
              @isReady="(value) => this.valid=value"
          >
            <template v-slot:suffix-icon>
              <eye-fontawesome @click="showConfirmPassword=!showConfirmPassword"/>
            </template>
          </TextInput>


          <button class="btn btn-primary  btn-white" :disabled="!enableSubmit || loading ">
            <div class="text-center" v-if="!loading">
              <div>{{ $t("btn.save") }}</div>
            </div>
            <Loading :loading="loading" :color="'#fff'" :size="'14px'" class="flex justify-center py-1"></Loading>
          </button>
        </form>

      </div>
    </div>
    <div class="h-full" style="height: 190px;"></div>
  </div>
</template>

<script>
import TextInput from "../../../components/inputs/text";
import {mapGetters} from "vuex";
import Loading from "vue-spinner/src/PulseLoader"
import RegistrationMobileNav from "../components/registration-mobile-nav";

export default {
  name: "ResetPassword",
  components: {RegistrationMobileNav, TextInput, Loading},
  data: () => ({
    valid: false,
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
    enableSubmit: true, errorConfirmPassword: "",
    errorPassword: "",
    error: {confirmPassword: "", password: ""},
  }),
  computed: {
    ...mapGetters({
      loading: "app/getInputLoading",
    }),
    validate: function () {
      return this.error.password.length === 0 && this.error.confirmPassword.length === 0 && this.valid;
    },
  },
  methods: {

    submit() {

      if (this.password.length === 0) {
        this.error.password = this.$t("rules.required");
      } else this.error.password = ""

      if (this.confirmPassword.length === 0) {
        this.error.confirmPassword = this.$t("rules.required");
      } else this.error.confirmPassword = ""

      if (this.validate) {
        this.$store.dispatch("auth/resetPassword", {
          password: this.password,
          "password_confirmation": this.confirmPassword,
          token: this.$route.query.token,
          email: this.$route.query.email,

        })
      }
    },
  },
  mounted() {

    this.toggleMobileBottomBar(true);
    this.toggleMobileCopyright(true);
  },

  beforeDestroy() {
    this.toggleMobileBottomBar(false)
    this.toggleMobileCopyright(false)
  }

}
</script>