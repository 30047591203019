<template>

  <div class="profile-container">
    <div class="booking-tabs">
      <div class="header">
        <div :class="'link '.concat(filter==='current'?'active':'')" @click="showCurrentBookings">
          {{ $t("user.bookings.current") }}
        </div>
        <div :class="'link '.concat(filter==='complete'?'active':'')" @click="showCompleteBookings">
          {{ $t("user.bookings.complete") }}
        </div>
      </div>
      <div class="content">

        <div class="no-content" v-if="(filter==='current' && currentBookings.length===0)||(filter==='complete' &&
      completeBookings.length===0)">
          <div class="box flex justify-center">
            <no-booking-svg/>
            <div class="text " v-if="isLoggedIn">
              <h2 class="title"> {{ $t("user.bookings.nothing.title") }}</h2>
              <p class="desc"> {{ $t("user.bookings.nothing.desc") }} </p>
            </div>

            <div class="text -mb-12" v-if="!isLoggedIn">
              <h2 class="title"> {{ $t("user.bookings.login.title") }}</h2>
              <p class="desc"> {{ $t("user.bookings.login.desc") }} </p>

              <button class="btn btn-warning  btn-white w-full mt-2 " @click="toLogin">

                <div class="text-center"> {{ $t("login.title") }}</div>

              </button>

            </div>
          </div>
        </div>

        <div class="booking-current" v-if="filter==='current'">

          <BookCard
              v-for="(book,index) in currentBookings"
              :status-code="book.status"
              :book="book"
              :key="index"
          />

          <paginate
              v-if="currentPageCount>1"
              v-model="page"
              class="mt-12"
              :page-count="currentPageCount"
              :page-range="4"
              :margin-pages="1"

              :prev-text="'<'"
              :next-text="'>'"

              :click-handler="currentPaginationCallback"

              :container-class="'pagination'"
              :break-view-text="'...'"
              :page-class="'page-item'">
          </paginate>

        </div>

        <div class="booking-current" v-if="filter==='complete'">
          <BookCard
              v-for="(book,index) in completeBookings"
              :status-code="book.status"
              :book="book"
              :key="index"
          />


          <paginate
              v-if="completePageCount>1"
              v-model="page"
              class="mt-12"
              :page-count="completePageCount"
              :page-range="4"
              :margin-pages="1"

              :prev-text="'<'"
              :next-text="'>'"

              :click-handler="completePaginationCallback"

              :container-class="'pagination'"
              :break-view-text="'...'"
              :page-class="'page-item'">
          </paginate>

        </div>

      </div>
    </div>

    <div style="height: 100px"></div>
  </div>
</template>

<script>
import paginate from "vuejs-paginate";

import BookCard from "./components/bookCard";
import {mapGetters} from "vuex";

export default {
  name: "Bookings",
  components: {BookCard, paginate},
  data() {
    return {
      page: this.$route.query.page ?? 1,
      completePage: 1,
      currentPage: 1,
      filter: "current",
    }
  },

  methods: {
    toLogin(){
      const lang = this.$route.params.lang;
      this.$router.push(`/${lang}/login`);
    },
    showCompleteBookings() {
      if (this.filter !== "complete") {
        this.filter = "complete";
        this.page = 1;//this.completePage;
        this.getBookings()
      }
    },
    showCurrentBookings() {
      if (this.filter !== "current") {
        this.filter = "current";
        this.page = 1;//this.currentPage;
        this.getBookings()
      }
    },

    async completePaginationCallback(page) {
      this.completePage = page;

      await this.$router.push({query: {page: this.completePage}})
      await this.getBookings();
    },

    async currentPaginationCallback(page) {
      this.currentPage = page;

      await this.$router.push({query: {page: this.currentPage}})
      await this.getBookings();
    },

    async getBookings() {

      if (this.isLoggedIn)
        await this.$store.dispatch("user/bookings/getBookings", {page: this.page, filter: this.filter});
    },
  },
  computed: {
    ...mapGetters({
      currentBookings: "user/bookings/getCurrentBookings",
      currentPageCount: "user/bookings/getCurrentPageCount",
      completeBookings: "user/bookings/getCompleteBookings",
      completePageCount: "user/bookings/getCompletePageCount",
      isLoggedIn: "auth/getIsLoggedIn",

    }),
  },
  created() {
    if (this.isLoggedIn)
      this.getBookings().then(() => {

        if (this.$route.query.page > this.currentPageCount) {
          this.page = 1;
          this.currentPaginationCallback(1)
        }

      });


  },
}
</script>