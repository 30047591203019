<template>
  <div class="container mobile-container">
    <PageHeading :title="detail.title" :paths="[{title:$t('blog.title'),link:'../articles'}]"/>

    <div class="articles-container pt-6">
      <div class="article-details">
        <img
            :src="detail.image" :alt="detail.title">
        <div class="time">
          <calendar-small-icon/>
          {{ detail.created_at | moment("LL") }}
        </div>
        <h2 class="title">{{ detail.title }}</h2>

        <div class="desc" v-html="detail.content"></div>

      </div>
      <div class="share">
        <div class=" details-container">
          <div class="details--heading">
            <h3>{{ $t("common.share") }}</h3>
          </div>
          <div class="details-divider"></div>
          <div id="fb-root"></div>
          <div class="px-4 flex items-center gap-3">
            <a target="_blank" :href="'https://api.whatsapp.com/send?url='.concat(link)">
              <whatsapp-box-icon/>
            </a>

            <a target="_blank" :href="'http://www.linkedin.com/shareArticle?mini=true&url='.concat(link)">
              <linkedin-box-icon/>
            </a>
            <a target="_blank" :href="'https://twitter.com/intent/tweet?url='.concat(link)">
              <twitter-box-icon/>
            </a>
            <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='.concat(link)">
              <facebook-box-icon/>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="articles-swiper-container " v-if="detail.related.length>0">

      <div class="mt-12 ">
        <div class="articles-similar-title">
          {{ $t("blog.suggested-posts") }}
        </div>
        <div class="articles-similar-container">

          <swiper class="overflow-hidden pb-8" ref="mySwiper" :options="swiperOptions">
            <SwiperSlide v-for="article in detail.related" :key="article.id">
              <ArticleCard :article="article"/>
            </SwiperSlide>

          </swiper>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import PageHeading from "../../components/PageHeading";
import ArticleCard from "../../components/ArticleCard";
import {mapGetters} from "vuex";

import {directive, Swiper, SwiperSlide} from "vue-awesome-swiper"
import "swiper/swiper.scss"

export default {
  name: "ArticleDetails",
  components: {ArticleCard, PageHeading, SwiperSlide, Swiper},
  data() {
    return {
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 30,
        loop: false,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      detail: "articles/getDetail",
    }),
    link() {
      return window.location.href;
    },

  }, directives: {
    swiper: directive,
  },

  watch: {
    $route(to, from) {
      if (from !== to) {
        this.getDetail();
      }
    },
  },
  methods: {
    getDetail() {

      this.$store.dispatch("articles/getDetail", this.$route.params.slug).catch((error) => {
        if (error.response.status === 404) {
          this.$router.push({name:'404'})
        }

      });
    },
  },
  created() {

    this.$store.commit('app/setNotification404', false)

    this.getDetail();
  },
  beforeDestroy() {

    this.$store.commit('app/setNotification404', true)

  }
}
</script>