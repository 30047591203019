<template>
  <div :class="' input '.concat(required ?'required':'') ">
    <label class="input--label" v-if="label">{{ label }}</label>

    <div class=" select-prefix " :class="error!=null &&error.length>0?'error':''">
      <span class="prefix-icon">
        <slot name="prefix-icon"/>
      </span>

      <label class="select w-full">

        <select :name="name" :required="required" @change="onSelect" v-model="selectValue">
          <option disabled selected value="placeholder">{{ placeholder }}</option>

          <slot name="options"/>

        </select>

        <arrow-down-icon/>

      </label>
    </div>

    <span class="input--error" v-if="error!=null && error.length>0">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    placeholder: String,
    label: String,
    name: String,
    rules: Array,
    keyUp: {
      type: Function,
      default: function () {
      },
    },
    onSelect: Function,
    selectValue: {
      type: String,
      default: "placeholder",
    },
    required: {
      type: Boolean,
      default: false,
    },

    value: String,
    error: String,
  },

  data() {
    return {
      inputValue: this.value,
    }
  },
}
</script>

