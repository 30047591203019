<template>

  <div class="itinerary details-container" v-if="timelines.length>0">
    <div class="details--heading">
      <h3>
        {{ $t("service.detail.tabs.timeline") }}
      </h3>
    </div>
    <div class="details-divider"></div>
    <div class="itinerary-content ">

      <itinerary-accordion
          v-for="(timeline, index) in timelines"
          :key="index"
          :no='++index'
          :title='timeline.title'
          :from='timeline.from'
          :to='timeline.to'
      >
        {{
          timeline.content
        }}
      </itinerary-accordion>


    </div>
  </div>
</template>

<script>
import ItineraryAccordion from "../components/itineraryAccordion";

export default {
  name: "itinerary-section",
  components: {ItineraryAccordion},
  props: {
    timelines: [],
  },
}
</script>