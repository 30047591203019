export const mutations = {
    setChats: (state, data) => {
        state.chats = data;
    },

    setChatMessages: (state, data) => {
        state.chatMessages = data;
    },



}