export const mutations = {

    setPolicy: (state, data) => {
        state.policy = data
    },
    setTerms: (state, data) => {
        state.terms = data
    },
    setFaqs: (state, data) => {
        state.faqs = data
    },
    setAbout: (state, data) => {
        state.about = data
    },
    setFooter: (state, data) => {
        state.footer = data
    },
}