<template>
  <div>

    <div class="container mobile-container">
      <PageHeading :title="$t('chat.detail')" class="mb-12"/>


      <div class="chat-detail-container">

        <div class="chat-form details-container mb-5 pb-0 overflow-hidden ">
          <div class="details--heading">
            <h3>{{ $t('chat.detail') }}</h3>
          </div>
          <div class="details-divider"></div>

          <div class=" ">

            <chat-window
                :current-user-id="getProfileInfo.id"
                :messages="messages"
                :rooms="rooms"
                :show-add-room="false"
                :show-search="false"
                :single-room="true"
                :rooms-loaded="true"
                :messages-loaded="true"
                :room-info-enabled="false"
                :emojis-suggestion-enabled="false"
                :show-reaction-emojis="false"
                :show-audio="false"
                :show-emojis="false"
                :message-actions="[]"
                :room-actions="[]"

                :show-footer="canSendMessage()"
                :accepted-files="'image/png, image/jpeg'"
                @send-message="sendMessage"
                @fetch-messages="fetchMessages"
                :text-messages="{
                TYPE_MESSAGE: $t('chat.type_message'),
              }"
            >

            </chat-window>
          </div>
        </div>

        <div class="provider" v-if="providerInfo.id !== 0">

          <ProviderCard :provider="providerInfo" v-if="providerInfo.id !== 0"/>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import {mapGetters} from 'vuex';

import PageHeading from "../../components/PageHeading";
import ChatWindow from "vue-advanced-chat"
import ProviderCard from "../../components/ProviderCard";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getFirestore,
  increment,
  onSnapshot,
  orderBy,
  query,
  setDoc
} from "firebase/firestore";


import moment from 'moment';

export default {
  name: "Chat",

  components: {
    ProviderCard,
    ChatWindow,
    PageHeading,
  },

  data() {
    return {
      rooms: [],
      messages: [],
      chatRef: null,
    }
  },


  computed: {
    ...mapGetters({
      getProfileInfo: 'user/getUserInfo',
      providerInfo: 'provider/getProviderBasic'
    }),

  },

  methods: {

    canSendMessage() {
      return this.providerInfo.active || this.$route.params.id == 'support';
    },
    async fetchMessages({room}) {
      this.messages = [];
      moment.locale('en');
      this.getRoom(room.roomId);
      const db = await getFirestore();
      this.messagesLoaded = true;
      const isAdmin = this.compareId(room.roomId);
      const unreadMessageMember = isAdmin
          ? 'unreadMessagesMemberA'
          : 'unreadMessagesMemberB';
      if (room.roomId === this.getProfileInfo.id.toString()) {
        await setDoc(
            doc(db, 'convrsations', room.roomId),
            {
              id: this.getProfileInfo.id.toString(),
              isAdminConvrsationWithCompany: true,
              isConvrsationWithAdmin: true,
              lastMessage: '',
              lastMessageDate: moment().format(),
              memberA: {
                id: this.getProfileInfo.id.toString(),
                name: this.getProfileInfo.name,
                photo: this.getProfileInfo.image,
              },
              members: [this.getProfileInfo.id.toString()],
              unreadMessagesMemberA: 0,
              unreadMessagesMemberB: 0,
            },
            {merge: true},
        );
        await setDoc(
            doc(db, 'unreadMessages', 'admin'),
            {
              countUnreadMessages: 0,
            },
            {merge: true},
        );
      } else {
        await setDoc(
            doc(db, 'convrsations', room.roomId),
            {
              [unreadMessageMember]: 0,
            },
            {merge: true},
        );
        await setDoc(
            doc(db, 'unreadMessages', this.getProfileInfo.id.toString()),
            {
              countUnreadMessages: 0,
            },
            {merge: true},
        );
      }
    },


    async getRoom(roomId) {


      const db = await getFirestore();
      const convrsations = doc(db, 'convrsations', roomId);

      const snapshot = await getDoc(convrsations);

      if (snapshot.exists()) {
        const messageRef = query(
            collection(db, 'convrsations', roomId, 'messages'),
            orderBy('created_at', 'asc'),
        );
        onSnapshot(messageRef, querySnapshot => {
          const messages = [];
          querySnapshot.forEach(doc => {
            const msg = doc.data();
            const message = {
              _id: doc.id,
              content: msg.body,
              senderId: parseInt(msg.sender_id),
              username: parseInt(msg.sender_id),
              timestamp: new Intl.DateTimeFormat('default', {
                hour12: true,
                hour: 'numeric',
                minute: 'numeric',
              }).format(new Date(msg.created_at)),
              date: new Date(msg.created_at).toDateString(),
            };
            messages.push(message);
          });
          if (messages.length > 0) {
            this.messages = messages;
          } else {
            this.messages;
          }
        });
      } else {
        this.messages = [];
      }
    },

    async sendMessage(message) {
      moment.locale('en');
      const db = await getFirestore();
      const isAdmin = this.compareId(message.roomId);
      const unreadMessageMember = isAdmin
          ? 'unreadMessagesMemberB'
          : 'unreadMessagesMemberA';

      const images = [];
      const formData = new FormData();
      if ('files' in message && message.files != null) {
        for (let i = 0; i < message.files.length; i++) {
          const file = new File(
              [message.files[i].blob],
              `${message.files[i].name}.${message.files[0].extension}`,
              {
                type: message.files[i].type,
                lastModified: new Date().getTime(),
              },
          );
          formData.append('file', file);
          await this.$store.dispatch('app/upload', formData).then(() => {
            images.push(this.$store.getters["app/getUploadedFileLink"]);
          });
        }
        images.forEach(image => {
          addDoc(collection(db, 'convrsations', message.roomId, 'messages'), {
            body: image,
            convrsationId: message.roomId,
            created_at: moment().format(),
            sender_id: this.getProfileInfo.id.toString(),
            type: 'image',
          });
          if (message.roomId === this.getProfileInfo.id.toString()) {
            setDoc(
                doc(db, 'convrsations', message.roomId),
                {
                  lastMessage: 'image',
                  lastMessageDate: moment().format(),
                  unreadMessagesMemberB: increment(1),
                },
                {merge: true},
            );
            setDoc(
                doc(db, 'unreadMessages', 'admin'),
                {
                  countUnreadMessages: increment(1),
                },
                {merge: true},
            );
          } else {
            setDoc(
                doc(db, 'convrsations', message.roomId),
                {
                  lastMessage: 'image',
                  lastMessageDate: moment().format(),
                  [unreadMessageMember]: increment(1),
                },
                {merge: true},
            );
            setDoc(
                doc(db, 'unreadMessages', this.otherUserId(message.roomId)),
                {
                  countUnreadMessages: increment(1),
                },
                {merge: true},
            );
          }
        });
      } else {
        await addDoc(
            collection(db, 'convrsations', message.roomId, 'messages'),
            {
              body: message.content,
              convrsationId: message.roomId,
              created_at: moment().format(),
              sender_id: this.getProfileInfo.id.toString(),
              type: 'text',
            },
        );
        if (message.roomId === this.getProfileInfo.id.toString()) {
          await setDoc(
              doc(db, 'convrsations', message.roomId),
              {
                lastMessage: message.content,
                lastMessageDate: moment().format(),
                unreadMessagesMemberB: increment(1),
              },
              {merge: true},
          );
          await setDoc(
              doc(db, 'unreadMessages', 'admin'),
              {
                countUnreadMessages: increment(1),
              },
              {merge: true},
          );
        } else {
          await setDoc(
              doc(db, 'convrsations', message.roomId),
              {
                lastMessage: message.content,
                lastMessageDate: moment().format(),
                [unreadMessageMember]: increment(1),
              },
              {merge: true},
          );
          await setDoc(
              doc(db, 'unreadMessages', this.otherUserId(message.roomId)),
              {
                countUnreadMessages: increment(1),
              },
              {merge: true},
          );
        }
      }
    },


    member(data) {
      const isAdmin = this.compareId(data.id);
      if (isAdmin) {
        return data.memberB;
      }
      return data.memberA;
    },


    compareId(id) {
      return id.split('-')[0] === this.getProfileInfo.id.toString();
    },

    otherUserId(data) {
      const array = data.split('-');
      return array.filter(id => id !== this.getProfileInfo.id.toString())[0];
    },

    compareTo(id) {
      return this.getProfileInfo.id.toString().localeCompare(id) > 0
          ? `${this.getProfileInfo.id}-${id}`
          : `${id}-${this.getProfileInfo.id}`;
    },


    //done
    getMemberInfo(roomId, member) {
      let tempMember1 = {
        id: this.providerInfo.id.toString(),
        name: this.providerInfo.name,
        photo: this.providerInfo.image,
      };

      let tempMember2 = {
        id: this.getProfileInfo.id.toString(),
        name: this.getProfileInfo.name,
        photo: this.getProfileInfo.image,
      };

      if (this.$route.params.id == 'support') {
        tempMember1 = {
          id: "0",
          name: 'الدعم الفني',
          photo: 'https://halabkstorage.s3.eu-central-1.amazonaws.com/support.svg',
        }
      }
      if (this.compareId(roomId) === true) {
        if (member === 'memberA')
          return tempMember2;
        else return tempMember1;
      } else {
        if (member === 'memberA')
          return tempMember1;
        else return tempMember2;
      }


    },

    //done
    async initChat() {

      const db = await getFirestore();
      let roomId = this.getProfileInfo.id.toString();
      let members = [];
      let memberA = {};
      let memberB = {};
      let withSupport = false;
      if (this.$route.params.id != 'support') {
        roomId = this.compareTo(this.$route.params.id.toString());
        members = [
          this.$route.params.id.toString(),
          this.getProfileInfo.id.toString(),
        ];
        memberA = this.getMemberInfo(roomId, 'memberA');
        memberB = this.getMemberInfo(roomId, 'memberB');
      } else {
        members = [
          this.getProfileInfo.id.toString(),
        ];

        withSupport = true;
        memberA = this.getMemberInfo(roomId, 'memberA');
        memberB = this.getMemberInfo(roomId, 'memberB');
      }
      await setDoc(
          doc(db, 'convrsations', roomId),
          {
            id: roomId,
            isAdminConvrsationWithCompany: false,
            isConvrsationWithAdmin: withSupport,
            // lastMessage: '',
            // lastMessageDate: moment().format(),
            memberA,
            memberB,
            members,
            // unreadMessagesMemberA: 0,
            // unreadMessagesMemberB: 0,
          },
          {merge: true},
      );
      this.rooms = [{
        roomId: roomId,
        roomName: "room",
        unreadCount: 0,
        avatar: "assets/images/bg.png",
        users: [
          {_id: memberA.id, username: memberA.name, avatar: memberA.photo},
          {_id: memberB.id, username: memberB.name, avatar: memberB.photo},
        ],
      }]

      // await this.getRoom(roomId);
    }

  },

  async created() {

    moment.locale('en');
    let id = this.$router.currentRoute.params.id;
    await this.$store.dispatch('user/getUserInfo');
    if (id !== 'support') {
      //check if the provider exits
      await this.$store.dispatch('provider/getProviderBasic', id).catch(function (error) {
        if (error.response.status === 404) {
          this.$router.push({name:'home'})
        }
      }.bind(this));
    }
    await this.initChat();
    this.toggleMobileBottomBar(true)
    this.toggleMobileFooter(true)
  },

  async mounted() {

    document.querySelectorAll(".vac-svg-button")[0].classList.add("chat-file-button")
    document.querySelectorAll(".vac-svg-button")[1].classList.add("chat-send-button")
    this.toggleMobileBottomBar(true)
    this.toggleMobileFooter(true)
  },
  beforeDestroy() {
    this.toggleMobileBottomBar(false)
    this.toggleMobileFooter(false)
  }
}
</script>
