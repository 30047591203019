export const state = {
    slider: [],
    services: [],
    latestServices: [],
    destinations: [],
    articles: [],
}






 
